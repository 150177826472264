import React, { useEffect, useState } from "react";
import { ButtonIcon } from "../../../../_layouts/components/buttons/ButtonUI";
import { GET_ENROLLMENT_PROCESS } from "../../apis/node-target-data/MarketingAPI";
import { SpinnerLoad } from "../../../../_layouts/components/loadbars/LoadingBars";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { DefaultYearId } from "./EnrollmentComp";

const RangeDataCounter = (props) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newOffset, setNewOffset] = useState(0);

  const { totalItems, ...others } = props;
  const previousFilter = sessionStorage.getItem("previousFilter")
    ? JSON.parse(sessionStorage.getItem("previousFilter"))
    : {
        yearintake: DefaultYearId(),
        offset: 1,
        limit: 10,
      };

  const { offset, limit } = previousFilter;

  const objTotal = async (polo) => {
    setLoading(true);
    console.log("polo", polo);
    const aha = await GET_ENROLLMENT_PROCESS(polo);
    setLoading(false);
    // console.log("param", RemoveEmptyValue(param));
    if (aha.message === "") {
      sessionStorage.setItem(
        "total_candidate_per_year_intake",
        JSON.stringify(aha.data.length)
      );
      setTotal(aha.data.length);
    }
  };

  useEffect(() => {
    let param = { ...previousFilter };

    delete param.offset;
    delete param.limit;

    if (param.status) {
      param["status"] = encodeURIComponent(param.status);
    }

    if (JSON.stringify(param) !== sessionStorage.getItem("last_yi")) {
      sessionStorage.setItem("last_yi", JSON.stringify(param));
      sessionStorage.removeItem("total_candidate_per_year_intake");
      objTotal(RemoveEmptyValue(param));
    }

    const total = sessionStorage.getItem("total_candidate_per_year_intake");

    if (total) {
      setTotal(total);
    } else {
      objTotal(RemoveEmptyValue(param));
    }

    setNewOffset(offset ? offset - 1 : 0);
  }, [previousFilter]);

  // let newOffset = offset ? offset - 1 : 0;

  return (
    <div className="btn-group">
      <button className="btn btn-sm bg-light fs-7" type="button">
        {loading ? (
          <SpinnerLoad />
        ) : totalItems > 0 ? (
          <span className="text-dark fw-bold">
            {newOffset * limit + 1}-{limit * newOffset + totalItems} of {total}
          </span>
        ) : (
          <span className="text-dark fw-bold">{totalItems}</span>
        )}
      </button>
      <ButtonIcon
        className="btn-sm btn-light-primary"
        title="Refresh"
        type="button"
        {...others}
      >
        <i className="bi bi-arrow-clockwise"></i>
      </ButtonIcon>
    </div>
  );
};

export default RangeDataCounter;
