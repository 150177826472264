import React, { useState } from "react";
import Select from "react-select";

const YearDropdown = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
  listYear,
}) => {
  const list = [];

  listYear.map((thn) => {
    list.push({ value: thn, label: thn.toString() });
  });

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      backgroundColor: 'transparent',
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "semibold",
      color: "gray",
    }),
  };

  return (
    <div className="form-group" style={{ minWidth: 80 }}>
      <Select
        name="degree"
        styles={customStyles}
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

export default YearDropdown;
