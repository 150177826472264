import React, { useEffect, useState } from "react";
import {
  AvaBoy,
  AvaGirl,
} from "../../../../../_layouts/components/images/Avatars";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { useOutletContext } from "react-router-dom";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

export function CardHeaderStd({ bio, acad, activeTab, setActTab }) {
  const context = useOutletContext();
  const widget_26 = context.widgets.find(
    (item) => item.widget_id === 26 && item.is_enabled === 1
  );
  const [currentSemester, setCurrentSemester] = useState();

  useEffect(() => {
    if (
      acad.semester_status &&
      Object.values(acad.semester_status).length > 0
    ) {
      const total_data = Object.values(acad.semester_status).length;
      const last_semester_status = acad.semester_status[total_data - 1];
      setCurrentSemester(last_semester_status);
    }
  });

  return (
    <div className="pt-9 px-8">
      <div className="d-flex flex-wrap flex-sm-nowrap">
        <div className="me-7 mb-4">
          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            {bio.gender === "p" ? (
              <AvaBoy className="bg-light-primary" />
            ) : (
              <AvaGirl className="bg-light-primary" />
            )}
            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
          </div>
        </div>

        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-wrap mb-2">
            <div className="">
              <div className="d-flex align-items-center mb-2">
                <span className="text-gray-900 text-hover-primary fs-1 fw-bold me-1 text-capitalize">
                  {bio.fullname}
                </span>
                <KTIcon iconName="verify" className="fs-1 text-primary" />
              </div>

              <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                  <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                  {StudentIDFormat(acad.studentid)}
                </span>
                {bio.sgumail && (
                  <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                    <KTIcon iconName="sms" className="fs-4 me-1" />
                    {bio.sgumail}
                  </span>
                )}
                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                  <KTIcon iconName="whatsapp" className="fs-4 me-1" />
                  {bio.cellular}
                </span>
              </div>
            </div>

            <div className="d-flex my-4">
              {acad.status && (
                acad.status.name === "Active" ? (
                  <ButtonTersier disabled={true} className="fw-bold me-5">
                    {acad.status.name} Student
                  </ButtonTersier>
                ) : (
                  <ButtonTersier disabled={true} className="btn-danger fw-bold me-5">
                    {acad.status.name}
                  </ButtonTersier>
                )
              )}

              {widget_26 && (
                activeTab !== 3 && (
                  <ButtonPrimary type="button" className="btn-sm fw-bold" onClick={() => setActTab(3)}>
                    <i className="ki-outline ki-user-edit fs-5 m-0"></i>
                    Edit Student
                  </ButtonPrimary>
                )
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-grow-1 pe-8">
            <div className="d-flex flex-wrap">
              <BoxDashed
                title={acad.program && acad.program.name}
                value={acad.clu && acad.clu.name}
                selector={"me-3"}
              />
              {acad.clu_spec && (
                <BoxDashed
                  title={"Specialization"}
                  value={acad.clu_spec.name || "-"}
                  selector={"me-3"}
                />
              )}
              <BoxDashed
                title={"Intake"}
                value={acad.term_intake ? (acad.term_intake.name || "-") : ""}
                selector={"me-3"}
              />
              {acad.corporate && (acad.corporate.name !== "SGU" && (
                <BoxDashed
                  title={"Corporate"}
                  value={acad.corporate.name || "-"}
                  selector={"me-3"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BoxDashed = ({ value, title, selector }) => {
  return (
    <div
      className={
        "border border-gray-300 border-dashed rounded py-3 px-3 mb-3 min-w-80px " +
        selector
      }
    >
      <div className="fs-4 fw-bolder text-gray-700 text-center">
        <span className="w-75px">{value}</span>
      </div>
      <div className="fw-bold text-muted">{title}</div>
    </div>
  );
};

export { BoxDashed }