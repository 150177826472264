export const DistincDataBaseOnEmailAndYearIntake = (rawData) => {
  const filterData = [];
  const seen = new Set();

  for (const item of rawData) {
    const key = `${item.email}_${item.year}_${item.target_department_name}`
    if (!seen.has(key)) {
      filterData.push(item);
      seen.add(key);
    }
  }

  return filterData;
};

export const SplitByYearIntake = (rawData) => {
  if (rawData) {
    const groupedByYear = rawData.reduce((acc, item) => {
      const year = item.year;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});
    return groupedByYear;
  }
};
