
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useProvider } from '../../../../_layouts/UMSLayoutProvider'
import { MenuCounsumerKey } from '../../../../_layouts/components/consumer-keys/ConsumerKeys';

const HeaderUserMenu: FC = () => {
  const { account } = useProvider();

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {account.identity.email}
            </div>
            <span className='fw-bold text-muted text-hover-primary fs-7'>
              {account.identity.empid}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to={"https://wa.me/message/NNSYT7PGEZM5E1"} target='_blank' className='menu-link px-5'>
          Change Password
        </Link>
      </div>


      <div className='menu-item px-5'>
        <Link to={"/logout"} className='menu-link px-5'>
          Log Out
        </Link>
      </div>

      {(account.identity.parent_group_id === 1) && (
        <>
          <div className='separator my-2'></div>
          <MenuCounsumerKey account={account} />
        </>
      )}
    </div>
  )
}

export { HeaderUserMenu }
