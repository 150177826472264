import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Error404 } from "../../../errors/components/Error404";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { GET_ASC_DOC, GET_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";
import { LoadingBars, LoadingProgressBar, LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { PMatriculations } from "../proposal-form/componensts/details";
import { KTIcon } from "../../../../../_metronic/helpers";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import LogActivitas from "./components/LogActivitas";
import { ButtonLong, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { HandlerDownload } from "./components/FormUI";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";

export function DetailProposalRegis() {
  const params = useParams();
  const token = params.proposal_id;

  try {
    const decode = atob(token);
    return <DetailPage decode={decode} />;
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ decode }) => {
  const proposal_id = parseInt(decode);
  const [total_credit, setTotalCredit] = useState(0);
  const [total_credit_ext, setTotalCreditExt] = useState(0);
  const [courses, setCourses] = useState([]);
  const [trans_id, setTransID] = useState("");

  const [proposal, setProposal] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [candidate, setCandidate] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setProposal({ loading: true, data: [], message: "" });
    const param = { proposal_id };
    const result = await GET_TRANSFER_PROPOSAL(param);
    if (result.data && Object.values(result.data).length > 0) {
      const find_by_id = result.data.find((item) => item.id === proposal_id);
      setTransID(find_by_id.transid);
      setProposal({ loading: false, data: find_by_id, message: "" });
      const course_dt = find_by_id.transfer_proposal_dt.map((item) => ({
        ...item,
        id: item.course_id,
        coursetitle: item.course.coursetitle,
        code: item.course.code,
        credit: item.course.credit,
      }));
      setCourses(course_dt);
      const total_credit = find_by_id.transfer_proposal_dt
        ? find_by_id.transfer_proposal_dt.reduce(
          (sum, item) => sum + (parseFloat(item.course.credit) || 0),
          0
        )
        : 0;
      setTotalCredit(total_credit);
      const total_credit_ext = find_by_id.transfer_proposal_dt
        ? find_by_id.transfer_proposal_dt.reduce(
          (sum, item) => sum + (parseFloat(item.credit_ext) || 0),
          0
        )
        : 0;
      setTotalCreditExt(total_credit_ext);

      const param_candidate = { candidate_id: find_by_id.candidate_id };
      const result_candidate = await GET_ENROLLMENT_PROCESS(param_candidate);
      if (
        result_candidate.data &&
        Object.values(result_candidate.data).length > 0
      ) {
        setCandidate({
          loading: false,
          data: result_candidate.data[0],
          message: "",
        });
      } else {
        setCandidate(result_candidate);
      }
    } else {
      setProposal(result);
    }
  };

  const [existFile, setExistFile] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [isDownload, setIsDownload] = useState(false);
  const CheckDecree = async (trans_id) => {
    setExistFile({ loading: true, data: [], message: "" });
    const param = { reff_id: trans_id };
    const result = await GET_ASC_DOC(param);
    if (result.data && Object.values(result.data).length > 0) {
      setExistFile({ loading: false, data: result.data[0], message: "" });
    } else {
      setExistFile(result);
    }
  }

  const DownloadDecree = async () => {
    const param = { file_id: existFile.data.file_id, user: existFile.data.createdby };
    const filename = `Decree for candidate ${StudentIDFormat(proposal.data.candidate_id)} ${proposal.data.fullname}`
    HandlerDownload(setIsDownload, param, filename);
  }

  useEffect(() => {
    ReloadData();
  }, [proposal_id]);

  useEffect(() => {
    if (trans_id) CheckDecree(trans_id);
  }, [trans_id])

  return proposal.loading || candidate.loading ? (
    <LoadingSkeleton />
  ) : proposal.message || proposal.loading ? (
    <AlertRetry
      messages={proposal.message || proposal.message}
      Reload={ReloadData}
    />
  ) : Object.values(proposal.data).length > 0 ? (
    <div className="row">
      <div className="col-lg-9">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bold text-gray-800">
              Transfer Credit Registration
            </span>
          </SGUCard.Header>
          <SGUCard.Toolbar>
            <div className="d-flex align-items-center">
              <KTIcon iconName="verify" className="fs-2x text-primary" />
              <span className="text-capitalize fw-bold text-primary ms-2">
                {proposal.data?.status}
              </span>
            </div>
          </SGUCard.Toolbar>
          <SGUCard.Body>
            <div className="candidate-detail">
              <div className="row mb-5 align-items-center">
                <label className="col-lg-3 fw-semibold text-muted">
                  Student:
                </label>
                <div className="col-lg-9">
                  <span className="text-dark fw-bold">
                    {StudentIDFormat(candidate.data.candidate_id) +
                      " · " +
                      (candidate.data.regstudent &&
                        (candidate.data.regstudent.fullname || ""))}
                  </span>
                </div>
              </div>
              <div className="row mb-5 align-items-center">
                <label className="col-lg-3 fw-semibold text-muted">
                  Target Department:
                </label>
                <div className="col-lg-9">
                  <span className="text-dark fw-bold">
                    {candidate.data.target_department &&
                      (candidate.data.target_department.name || "")}
                  </span>
                </div>
              </div>

              <div className="row mb-5 align-items-center">
                <label className="col-lg-3 fw-semibold text-muted">
                  Previous University:
                </label>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-auto">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control form-control-sm fw-bold"
                          readOnly
                          value={proposal.data?.university_name || "-"}
                        />
                        <label className="fw-semibold text-muted">
                          Institution Name
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control form-control-sm fw-bold"
                          readOnly
                          value={proposal.data?.university_prodi_name || "-"}
                        />
                        <label className="fw-semibold text-muted">
                          Major at prev insitution
                        </label>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control form-control-sm fw-bold"
                          readOnly
                          value={"Semester " + (candidate.data?.regstudent?.university_last_semester || "-")}
                        />
                        <label className="fw-semibold text-muted">
                          Last Semester
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="row mb-5 align-items-center">
                <label className="col-lg-3 fw-semibold text-muted">
                  Accepted at semester:
                </label>
                <div className="col-lg-9">
                  <span className="text-dark fw-bold">
                    {candidate.data?.enroll_semester}
                  </span>
                </div>
              </div>

            </div>
            <div className="my-5">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span className="col-lg-3 fw-semibold text-muted">
                  Transferable Credits:
                </span>
              </div>
              <PMatriculations
                data={courses}
                t_credit={total_credit}
                t_credit_ext={total_credit_ext}
              />
            </div>
          </SGUCard.Body>
        </SGUCard>
      </div>
      <div className="col-lg-3">
        <div className="d-grid gap-2 mb-5">
          {existFile.loading ? <LoadingBars /> :
            existFile.message ? <AlertRetry messages={existFile.message} Reload={CheckDecree(trans_id)} /> :
              Object.values(existFile.data).length > 0 && (
                <ButtonTersier type="button" className="btn-lg btn-light-success" disabled={isDownload} onClick={() => DownloadDecree()}>
                  <KTIcon iconName="cloud-download" className="fs-2" />
                  {isDownload ? "Downloading..." : "Download Decree"}
                </ButtonTersier>
              )}
          <LinkBtn className="btn btn-lg btn-primary" to={`/students/search/${candidate.data.candidate_id}`}>
            Got to student dashboard
          </LinkBtn>
        </div>
        <LogActivitas proposal={proposal.data} storage={existFile.data} />
      </div>
    </div>
  ) : (
    <Error404 />
  );
};
