import React, { useEffect, useMemo, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";
import { LoadingBars } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { DefaultYear } from "../../components/EnrollmentComp";
import YearDropdown from "../helper/YearDropdown";
import { options } from "@fullcalendar/core/preact";

const RadialBar = ({ className, data, loading, listYear }) => {
  const chartRef = useRef(null);
  const [year, setYear] = useState(DefaultYear());
  const [total, setTotal] = useState(0);

  const CookedData = useMemo(() => {
    if (Object.keys(data).length > 0) {
      let slpitEnrollStatus = {
        draft: data[year].filter((item) => item.enroll_status === "DRAFT"),
        onProcess: data[year].filter(
          (item) => item.enroll_status === "ON PROCESS"
        ),
        complete: data[year].filter(
          (item) => item.enroll_status === "FINISHED"
        ),
        canceled: data[year].filter(
          (item) => item.enroll_status === "CANCELED"
        ),
      };

      const draft = slpitEnrollStatus.draft.length;
      const onProcess = slpitEnrollStatus.onProcess.length;
      const complete = slpitEnrollStatus.complete.length;
      const canceled = slpitEnrollStatus.canceled.length;

      setTotal(draft + onProcess + complete + canceled);
      const newData = {
        draft: draft,
        onProcess: onProcess,
        complete: complete,
        canceled: canceled,
      };
      return newData;
    }
  }, [data, year]);
  console.log("cooked data -> ", CookedData);

  // console.log("year array", getYearArray());
  // console.log("bachelor array", getBachelorTotalEachYear());
  // console.log("master array", getMasterTotalEachYear());

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(CookedData, total)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, CookedData]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Registration Progress
          </span>
          <span
            className="text-muted fw-semibold fs-7"
            style={{ marginTop: -5, marginLeft: -10 }}
          >
            <YearDropdown data={year} setData={setYear} listYear={listYear} />
          </span>
        </h3>
      </div>
      {loading ? (
        <LoadingBars />
      ) : (
        <div className="card-body pt-0 position-relative">
          <div
            ref={chartRef}
            id="kt_charts_widget_3_chart"
          ></div>

          <div className="d-flex flex-column justify-content-between flex-row-fluid position-">
            <div className="d-flex fw-semibold align-items-center">
              <div
                className="bullet w-8px h-3px rounded-2 me-3"
                style={{ backgroundColor: "#9c9a9a" }}
              ></div>
              <div className="text-gray-500 flex-grow-1 me-4">Draft</div>
              <div className=" fw-bolder text-gray-700 text-xxl-end">
                {CookedData ? CookedData.draft : ""}
              </div>
            </div>
            <div className="d-flex fw-semibold align-items-center my-3">
              <div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
              <div className="text-gray-500 flex-grow-1 me-4">On Process</div>
              <div className="fw-bolder text-gray-700 text-xxl-end">
                {CookedData ? CookedData.onProcess : ""}
              </div>
            </div>
            <div className="d-flex fw-semibold align-items-center">
              <div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
              <div className="text-gray-500 flex-grow-1 me-4">Complete</div>
              <div className="fw-bolder text-gray-700 text-xxl-end">
                {CookedData ? CookedData.complete : ""}
              </div>
            </div>
            <div className="d-flex fw-semibold align-items-center my-3">
              <div className="bullet w-8px h-3px rounded-2 bg-danger me-3"></div>
              <div className="text-gray-500 flex-grow-1 me-4">Canceled</div>
              <div className="fw-bolder text-gray-700 text-xxl-end">
                {CookedData ? CookedData.canceled : ""}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { RadialBar };

function getChartOptions(data, total) {
  const draft = "#9c9a9a";
  const onProcess = getCSSVariableValue("--bs-primary");
  const complete = getCSSVariableValue("--bs-success");
  const canceled = getCSSVariableValue("--bs-danger");

  const getSeries = () => {
    if (data) {
      const series = [];
      Object.values(data).forEach((item, index) => {
        series.push(((item / total) * 100).toFixed(1));
      });
      return series;
    }
  };

  return {
    series: data ? getSeries() : [0],
    chart: {
      fontFamily: "inherit",
      type: "radialBar",
      height: 260,
      zoom: {
        enabled: false, // Disable zoom to get the default cursor
      },
      toolbar: {
        show: false,
      },
      foreColor: "#000",
    },
    colors: [draft, onProcess, complete, canceled],
    labels: ["Draft", "On Process", "Complete", "Canceled"],
    stroke: {
      lineCap: "round",
      width: -10,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "30%", // Ukuran lingkar tengah, semakin kecil nilainya semakin besar lubang
          background: "transparent", // Opsional, mengatur warna background lingkar tengah
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function(w) {
              return total;
            },
          },
        },
      },
    },
  };
}
