import { useEffect, useRef, useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import {
  Almanac,
  LetterTemplate,
  toBase64,
} from "../../components/EnrollmentComp";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { CREATE_NOTIFICATION } from "../../../apis/node-target-data/MarketingAPI";
import { ButtonPrimary } from "../../../../../_layouts/components/buttons/ButtonUI";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  GET_SYSTEM_PARAM,
  UPDATE_SYSTEM_PARAM,
} from "../../../apis/module/BackendRequest";
import { duration } from "moment";
import { SpinnerLoad } from "../../../../../_layouts/components/loadbars/LoadingBars";

const AcceptanceLetterModal = ({ data, triggerNotification }) => {
  const editorRef = useRef(null);
  const [template, setTemplate] = useState("");
  const [oldTemplate, setOldTemplate] = useState([]);
  const [emailTarget, setEmailTarget] = useState("");
  const [subject, setSubject] = useState("");
  const [cc, setCc] = useState("");
  const [loadingSendLetter, setLoadingSendLetter] = useState(false);
  const [loadingUpdateTemplate, setLoadingUpdateTemplate] = useState(false);
  const [loadingSetDefaultTemplate, setLoadingSetDefaultTemplate] = useState(
    false
  );
  const [loadingGetTemplate, setLoadingGetTemplate] = useState({
    loading: true,
    message: "",
    data: [],
  });

  const [pos, setPos] = useState(0);

  const handleEditorChange = (e) => {
    setTemplate(e);
  };

  const handleSendLetter = async () => {
    setLoadingSendLetter(true);
    try {
      const param = {
        asonemail: 1,
        body: toBase64(template),
        schd_type: "direct",
        channel: "notif.channel.type.email",
        subject: toBase64(subject),
        target_user: [
          {
            email: emailTarget,
            is_group: 0,
          },
        ],
      };

      if (cc !== "") {
        param.ext1 = cc;
      }

      console.log("param - ", param);
      const send = await CREATE_NOTIFICATION(param);
      console.log("send - ", send);
      setLoadingSendLetter(false);

      if (send.message === "") {
        triggerNotification({
          title: "Success",
          type: "success",
          message: `Success send letter`,
          duration: 3000,
        });
      } else {
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: send.message,
          duration: 3000,
        });
      }
    } catch (error) {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: error.message,
        duration: 3000,
      });
      setLoadingSendLetter(false);
      console.log(error);
    }
  };

  const getTemplate = async () => {
    setLoadingGetTemplate({ loading: true, message: "", data: [] });
    try {
      const xx = await GET_SYSTEM_PARAM();
      // console.log("xx >> ", xx);
      setLoadingGetTemplate(xx);
      if (xx.message === "") {
        setOldTemplate(JSON.parse(atob(xx.data[0].value)));
      } else {
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: xx.message,
        });
      }
    } catch (error) {
      setLoadingGetTemplate({
        loading: false,
        message: error.message,
        data: [],
      });
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: error.message,
      });
    }
  };

  const updateTemplate = async () => {
    setLoadingUpdateTemplate(true);

    try {
      const newTemplate = oldTemplate.map((value, index) =>
        index === parseInt(pos.replace("template_", "")) - 1 ? template : value
      );

      const param = {
        id: 1,
        value: btoa(JSON.stringify(newTemplate)),
      };
      const update = await UPDATE_SYSTEM_PARAM(param);
      // console.log("update -> ", update);
      if (update.message === "") {
        triggerNotification({
          type: "success",
          message: "Success update template",
        });
        getTemplate();
      } else {
        triggerNotification({
          type: "danger",
          message: update.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "danger",
        message: error.message,
      });
    } finally {
      setLoadingUpdateTemplate(false);
    }
  };

  const updateDefaultTemplate = async () => {
    setLoadingSetDefaultTemplate(true);

    try {
      const newTemplate = oldTemplate.map((value, index) =>
        index === parseInt(pos.replace("template_", "")) - 1 ? template : value
      );

      const param = {
        id: 1,
        tipe: pos,
        value: btoa(JSON.stringify(newTemplate)),
      };
      const update = await UPDATE_SYSTEM_PARAM(param);
      // console.log("update -> ", update);
      if (update.message === "") {
        triggerNotification({
          type: "success",
          message: `Success set template ${pos.replace(
            "template_",
            ""
          )} as default`,
        });
        getTemplate();
      } else {
        triggerNotification({
          type: "danger",
          message: update.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "danger",
        message: error.message,
      });
    } finally {
      setLoadingSetDefaultTemplate(false);
    }
  };

  useEffect(() => {
    getTemplate();
  }, []);

  window.addEventListener("error", (event) => {
    if (event.message.includes("ResizeObserver loop limit exceeded")) {
      console.warn("ResizeObserver error ignored");
      event.preventDefault();
    }
  });

  return (
    <>
      <div className="w-full d-flex justify-content-between position-relative">
        <div className="d-flex align-items-center flex-column flex-lg-row">
          <div className="form-group position-relative me-md-5">
            <label className={"fw-bold"}>Send to</label>
            <input
              className="form-control form-control-sm pe-10"
              placeholder="Email target"
              value={emailTarget}
              onChange={(e) => setEmailTarget(e.target.value)}
            />
            {emailTarget && (
              <span
                className="position-absolute"
                style={{
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setEmailTarget("")}
              >
                <i className="ki-outline ki-cross fs-1"></i>
              </span>
            )}
          </div>
          <div className="form-group position-relative me-md-5">
            <label className={"fw-bold"}>Subject</label>
            <input
              className="form-control form-control-sm pe-10"
              placeholder="Subject fot the letter"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            {subject && (
              <span
                className="position-absolute"
                style={{
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setSubject("")}
              >
                <i className="ki-outline ki-cross fs-1"></i>
              </span>
            )}
          </div>
          <div className="form-group position-relative">
            <label className={"fw-bold"}>CC</label>
            <input
              className="form-control form-control-sm pe-10"
              placeholder="CC for the letter"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
            />
            {cc && (
              <span
                className="position-absolute"
                style={{
                  top: "70%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setCc("")}
              >
                <i className="ki-outline ki-cross fs-1"></i>
              </span>
            )}
          </div>
        </div>
        <div
          className="form-group mb-5 position-absolute d-flex align-items-center"
          style={{
            right: 0,
            top: 15,
            zIndex: 999,
            gap: 20,
          }}
        >
          {template && <Almanac />}
          <div style={{ width: 200 }}>
            {loadingGetTemplate.loading ? (
              <SpinnerLoad />
            ) : (
              <LetterTemplate
                data={template}
                setData={setTemplate}
                setPos={setPos}
                listTemplate={loadingGetTemplate.data[0]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Editor
          apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
          // onInit={() => handleEditorInit()}
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          onEditorChange={handleEditorChange}
          value={template}
          init={{
            height: 450,
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
          }}
        />
      </div>
      <div className="mt-7 d-flex justify-content-between">
        <div>
          {pos !== -1 && (
            <div>
              <Button
                className="btn-outline btn-outline-primary me-3"
                onClick={() => updateTemplate()}
              >
                {loadingUpdateTemplate ? "Updating..." : "Update template"}
              </Button>
              {pos !== loadingGetTemplate?.data[0]?.tipe && (
                <Button
                  className="btn-outline btn-outline-primary"
                  onClick={() => updateDefaultTemplate()}
                >
                  {loadingSetDefaultTemplate ? "Setting..." : "Set as default"}
                </Button>
              )}
            </div>
          )}
        </div>
        <div>
          <Button
            variant="secondary"
            className="me-3"
            onClick={() => openModal({ open: false })}
          >
            Cancel
          </Button>
          <ButtonPrimary
            title={
              subject === "" || emailTarget === ""
                ? "Make sure email target and subject filled"
                : "Send letter"
            }
            // disabled={subject === "" || emailTarget === ""}
            onClick={() =>
              subject === "" || emailTarget === "" ? null : handleSendLetter()
            }
          >
            {loadingSendLetter ? "Sending..." : "Send"}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};

export { AcceptanceLetterModal };
