import React, { useState } from 'react'
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI'
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { RequestData } from '../../apis/ApiBackend';

export default function FormUpdate({ data, ReloadData }) {
    const objparam = {
        group_id: (data ? data.group_id : 0),
        name: (data ? data.name : ""),
        description: (data ? data.description : ""),
        is_active: (data ? data.is_active : 0)
    };
    const [post, setPost] = useState(objparam);
    const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });
    const SubmitHendler = async (e) => {
        e.preventDefault()
        if (post.name && post.is_active) {
            setSubmit({ loading: true, message: "", data: [] });
            const param = {
                method: "post",
                pathuri: "api/group/update",
                payload: post
            };
            let parampost = (post.group_id) ? param : RemoveEmptyValue(param);
            const result = await RequestData(parampost);

            if (result.data && Object.values(result.data).length > 0) {
                setSubmit({ loading: false, message: "Successfully updated", data: result.data });
                ReloadData();
            } else {
                setSubmit({ loading: false, message: "Failed to update. Please try again later", data: [] });
            }
        } else {
            setSubmit({ loading: false, message: "Please fill up the required field", data: [] })
        }
    }
    return (
        <div>
            <form className='' autoComplete='off' onSubmit={(e) => SubmitHendler(e)}>
                <div className="form-group mb-5">
                    <label className="required fw-bolder">Group Name</label>
                    <input type="text" required className="form-control" defaultValue={post.name} onChange={(e) => setPost({ ...post, name: e.target.value })} />
                </div>
                <div className="form-group mb-5">
                    <label className="fw-bolder">Description</label>
                    <input type="text" className="form-control" defaultValue={post.description} onChange={(e) => setPost({ ...post, description: e.target.value })} />
                </div>
                <div className="form-group mb-5">
                    <label className="required fw-bolder">Status</label>
                    <select name="status" required className="form-control" defaultValue={post.is_active} onChange={(e) => setPost({ ...post, is_active: parseInt(e.target.value) })}>
                        <option value="0">Choose One</option>
                        <option value="1">Active</option>
                        <option value="2">Not Active</option>
                    </select>
                </div>
                {submit.message && (
                    <div className="my-5">
                        <AlertNotif messages={submit.message} color={(Object.values(submit.data).length > 0) ? "info":"danger"} />
                    </div>
                )}
                <div className="text-end">
                    <ButtonPrimary type="submit" disabled={submit.loading} >
                        {submit.loading ? "Processing..." : "Save changes"}
                    </ButtonPrimary>
                </div>
            </form>
        </div>
    )
}
