import React, { useEffect, useState } from 'react'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { CandidateSelect } from '../../components/TCUI'
import { GET_ENROLLMENT_PROCESS } from '../../../../apis/node-target-data/MarketingAPI';
import { LoadingBars } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { AllUnivMajorTransSelect, AllUnivSelect } from '../../../../components/forms/SelectBox';
import { useLocation } from 'react-router-dom';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { GET_TRANSFER_PROPOSAL } from '../../../../apis/node-target-data/AcademicApi';
import { MessageError, openModal } from '../../../../../../_layouts/components/modals/Modals';
import { ButtonIcon } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { StudentIDFormat } from '../../../../../../_layouts/_helpers/Global';
import { handleKeyDown } from '../../../../components/forms/InputText';

export default function CandidateData({ cadidates, setDcandidate, setCandidates, univ, setUniv, univProdi, setUnivProdi, setTargetDept, doc_date, setDoc_date, setIsExist, isAvail, univLastSemes, setUnivLastSemes }) {
    const location = useLocation();
    const unividstorage = location.state ? location.state.data.university_id : 0
    const univprodistorage = location.state ? location.state.data.university_prodi : 0

    const [candidate, setCandidate] = useState({ loading: false, message: "", data: [] });
    const [localUniv, setLocalUniv] = useState(univ);
    const [localUnivProdi, setLocalUnivProdi] = useState(univProdi);
    const [localUnivLastSemes, setLocalUnivLastSemes] = useState(univLastSemes);

    const MessageAvailPrev = "Please update the data of " + (StudentIDFormat(cadidates)) + " first through admissions regarding the previous institute (university, major, and last semester)";
    const IntoEditable = "The previous institution can be updated by admissions based on the master institution data";

    const CheckCandidateProposal = async () => {
        const param = { candidate_id: cadidates };
        const result = await GET_TRANSFER_PROPOSAL(param);
        if (result.data && Object.values(result.data).length > 0) {
            openModal({ message: <MessageError message={"This candidate " + cadidates + " has already been created. Please select another candidate."} /> });
            setIsExist(true);
        } else {
            GetDetailCandidate();
            setIsExist(false);
        }
    }

    const GetDetailCandidate = async () => {
        setCandidate({ loading: true, message: "", data: [] });
        const param = { candidate_id: cadidates };
        const result = await GET_ENROLLMENT_PROCESS(param);
        if (result.data && Object.values(result.data).length > 0) {
            setDcandidate(result.data[0]);
            setCandidate({ loading: false, message: "", data: result.data[0] });

            if (result.data[0].target_department) {
                setTargetDept(result.data[0].target_department.name);
            }
            if (result.data[0].regstudent && result.data[0].regstudent.university_id) {
                setLocalUniv(result.data[0].regstudent.university_id);
                setLocalUnivProdi(result.data[0].regstudent.university_prodi_id);
                setLocalUnivLastSemes((result.data[0]?.regstudent?.university_last_semester) || "");

                setUniv(result.data[0].regstudent.university_id)
                setUnivProdi(result.data[0].regstudent.university_prodi_id);
                setUnivLastSemes((result.data[0]?.regstudent?.university_last_semester) || "");
            } else {
                setLocalUniv(unividstorage);
                setUniv(unividstorage);
                setLocalUnivProdi(univprodistorage);
                setUnivProdi(univprodistorage);
                setLocalUnivLastSemes(0);
                setUnivLastSemes(0);
            }
        } else {
            setDcandidate("");
            setCandidate(result);
        }
    }

    useEffect(() => {
        if (cadidates) {
            //CheckCandidateProposal();
            GetDetailCandidate();
        }
    }, [cadidates]);

    useEffect(() => {
        setLocalUniv(univ);
        setLocalUnivProdi(univProdi);
        setLocalUnivLastSemes(univLastSemes);
    }, [univ, univProdi]);

    const handleUnivChange = (value) => {
        setLocalUniv(value);
        setUniv(value);
    };

    const handleUnivProdiChange = (value) => {
        setLocalUnivProdi(value);
        setUnivProdi(value);
    };

    const changeLastSemes = (value) => {
        const semester = parseInt(value);
        setLocalUnivLastSemes(semester);
        setUnivLastSemes(semester);
    }

    return (
        <SGUCard>
            <SGUCard.Body className={"pb-0 pt-8"}>
                <div className="row">
                    <div className="col-lg-4">
                        <CandidateSelect data={cadidates} setData={setCandidates} required />
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label className="fw-bold">Target Department</label>
                            <div className="form-control form-control-sm">{(candidate.data?.target_department?.name) || "-"}</div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <label className="fw-bold required">Date of Matriculation</label>
                            <div className="position-relative">
                                <DatePicker className="form-control form-control-sm" dateFormat={"yyyy-MM-dd"}
                                    minDate={doc_date}
                                    placeholderText='Enter date' selected={doc_date} onChange={(date) => setDoc_date(date)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} />
                                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                    <KTIcon iconName='calendar' className='fs-1 text-dark' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(cadidates && cadidates.length > 0) && (
                    <div className="row">
                        <div className="col-12">
                            <h4 className='mb-0 d-flex align-items-center'>
                                <span>Previous Institutions</span>
                                <ButtonIcon className={"btn-sm p-0"} type="button" title={IntoEditable}>
                                    <KTIcon iconName='information-3' className='fs-1x text-info' />
                                </ButtonIcon>
                            </h4>
                        </div>
                        {candidate.loading ? <div className="col-auto"><LoadingBars /></div> :
                            candidate.message ? <div className="col-auto"><AlertNotif messages={candidate.message} /></div> : (
                                <>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label className="required fw-bold">Prev University</label>
                                            <div className="form-control form-control-sm">{(candidate.data?.regstudent?.university_obj?.name) || "-"}</div>
                                        </div>
                                    </div>
                                    {localUniv !== 0 && (
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label className="required fw-bold">Major at prev university</label>
                                                <div className="form-control form-control-sm">{(candidate.data?.regstudent?.university_prodi_obj?.name) || "-"}</div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-auto">
                                        <div className="form-group">
                                            <label className="fw-bold required">Semester Passed</label>
                                            <div className="form-control form-control-sm">{(candidate.data?.regstudent?.university_last_semester) || "-"}</div>
                                        </div>
                                    </div>
                                </>
                            )}
                        {!isAvail && (
                            <div className="col-12 mt-3">
                                <AlertNotif messages={MessageAvailPrev} />
                            </div>
                        )}
                    </div>
                )}
            </SGUCard.Body>
        </SGUCard>
    )
}
