import moment from "moment";
import { toPng } from 'html-to-image';
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";
import { ButtonDanger, ButtonIcon, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import "./EventBox.css"
import { MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useEffect, useState } from "react";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";
import { DELETE_SCHD } from "../../apis/node-target-data/AcademicApi";
import CreateTimetable from "../timetables/components/CreateTimetable";
import { AuthDecodeToken } from "../../../reducer/AuthRedux";
import { GET_WIDGET_BY_GROUP } from "../../apis/module/BackendRequest";
import { ExtractWidgetIds } from "../../../../_layouts/_helpers/Global";
import FormCreateEvent from "../event-management/FormCreateEvent";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";

const Token = AuthDecodeToken();


const EventTitle = (event_type, label, title, coursetitle) => {
    let title_event = '';
    try {
        const event_type_arr = event_type.id.split(".");
        const event_type_name = event_type_arr[event_type_arr.length - 1];

        if (event_type_name.includes('event')) {
            title_event = (label ? label : title);
        } else {
            if (label) {
                title_event = coursetitle + ": " + label;
            } else {
                title_event = title;
            }
        }
    } catch (error) {
        title_event = coursetitle;
    }

    return title_event;
}
const EventBoxCall = (data) => {
    let item = data.event;
    let objItem = JSON.parse(item.groupId);

    let title_event = EventTitle(objItem.event_type, objItem.label, item.title, objItem.course?.coursetitle);

    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: moment(item.start).format("hh:mm a") + "-" + moment(item.end).format("hh:mm a") + ", " + item.title })}>
            <div id="sgu-event-calendar" className={"event-item cursor-pointer p-1 "} onClick={() => openModal({ message: <DetailEvent data={objItem} /> })} style={{ height: "inherit" }} >
                <div className="mb-0 text-dark">
                    {(parseInt(moment(item.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(item.start, 'YYYY-MM-DD HH:mm:ss'), 'hours'))) === 0 ? (
                        <>
                            <p className={"mb-0 fw-bolder fs-9 " + (objItem.conflict ? "text-danger" : "")}>{title_event}</p>
                            <p className={"mb-0 fw-bold fs-9 " + (objItem.conflict ? "text-danger" : "")}>{moment(item.start).format("hh:mm a")}-{moment(item.end).format("hh:mm a")}</p>
                        </>
                    ) : (
                        <>
                            {(parseInt(moment(item.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(item.start, 'YYYY-MM-DD HH:mm:ss'), 'hours'))) === 1 ? (
                                <>
                                    {item.title.length > 30 ? (
                                        <>
                                            <p className={"mb-0 fw-bold fs-9 " + (objItem.conflict ? "text-danger" : "")}>{moment(item.start).format("hh:mm a")}-{moment(item.end).format("hh:mm a")}</p>
                                            <p className={"mb-0 fw-bolder fs-9 " + (objItem.conflict ? "text-danger" : "")}>{title_event}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className={"mb-0 fw-bold fs-9 " + (objItem.conflict ? "text-danger" : "")}>{moment(item.start).format("hh:mm a")}-{moment(item.end).format("hh:mm a")}</p>
                                            <p className={"mb-0 fw-bolder fs-7 " + (objItem.conflict ? "text-danger" : "")}>{title_event}</p>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <p className={"mb-0 fw-bold fs-9 " + (objItem.conflict ? "text-danger" : "")}>{moment(item.start).format("hh:mm a")}-{moment(item.end).format("hh:mm a")}</p>
                                    <p className={"mb-0 fw-bolder fs-6 " + (objItem.conflict ? "text-danger" : "")}>{title_event}</p>
                                </>
                            )}

                            {Object.values(objItem.event_room).length > 0 ? (
                                <p className='mb-0 fw-bold'>
                                    <span className={"fs-9 " + (objItem.conflict ? "text-danger" : "")}>Room: </span>
                                    {objItem.event_room.map((v, index) => (
                                        <span className={"fs-9 " + (objItem.conflict ? "text-danger" : "")} key={index}>
                                            {(v.room) ? v.room.g_resourceName + "(" + v.room.g_capacity + ")" : ""}
                                            {((Object.values(objItem.event_room).length - 1) > index) ? ", " : ""}
                                        </span>
                                    ))}
                                </p>
                            ) : ""}
                        </>
                    )}
                    {objItem.is_cancel === 1 && (
                        <span className="badge badge-danger fs-9">Cancel</span>
                    )}
                </div>
            </div>
        </OverlayTrigger>
    )
}

const DetailEvent = ({ data }) => {
    console.log(data);
    let title_event = EventTitle(data.event_type, data.label, (data.label ? data.label : data.course?.coursetitle), data.course?.coursetitle);
    const [widgets, setWidgets] = useState({ loading: false, data: [], message: "" });
    const widget_50 = (Object.values(widgets.data).length > 0) && widgets.data.find(item => item.widget_id === 50 && item.is_enabled === 1)

    useEffect(() => {
        FetchWidgets({ group_id: Token.identity.group_id, menu_id: 16 }, setWidgets);
    }, []);

    let type_event = "";
    try {
        const event_type_arr = data.event_type.split(".");
        const event_type_name = event_type_arr[event_type_arr.length - 1];
        type_event = event_type_name;
    } catch (error) {
        type_event = "";
    }
    
    const [isRemove, setIsRemove] = useState(false);
    const HandlerRemove = async (id) => {
        const param = { schd_id: id }
        const result = await DELETE_SCHD(param);
        if (!result.loading) {
            openModal({ message: <MessageSuccess message={"Successfully removed. Please reload this page"} /> })
        }
    }

    return (
        <div>
            <div className="titles mb-8">
                <h1>{title_event}</h1>
                <p className="fs-5 text-muted">
                    <span>
                        {moment(data.start_dt).format("dddd")},
                        <span className="mx-1">{moment(data.start_dt).format("MMM Do YYYY")} ⋅</span>
                        {moment(data.start_dt).format("hh:mm A")} - {moment(data.end_dt).format("hh:mm A")}
                    </span>
                </p>
                {data.is_cancel === 1 && (
                    <AlertNotif messages={"Class is cancel"} />
                )}
            </div>

            <div className="d-flex">
                {data.section_id && (
                    <div className="mb-5 me-8">
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-30px me-3">
                                <span className="symbol-label">
                                    <KTIcon iconName="fasten" className="fs-1" />
                                </span>
                            </div>
                            <div className="m-0">
                                <span className="fw-semibold text-gray-500 d-block fs-8">Section ID</span>
                                <span className="fw-bold text-gray-800 fs-7">{data.section_id}</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mb-5">
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px me-3">
                            <span className="symbol-label">
                                <KTIcon iconName="setting" className="fs-1" />
                            </span>
                        </div>
                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 fs-8 d-block">Type </span>
                            <span className="fw-bold text-gray-800 fs-7 text-capitalize">
                                {type_event}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {(data.event_room && Object.values(data.event_room).length > 0) && (
                <div className="mb-5">
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px me-3">
                            <span className="symbol-label">
                                <KTIcon iconName="delivery-door" className="fs-1" />
                            </span>
                        </div>
                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 fs-8 d-block">Room </span>
                            {data.event_room.map((v, index) => (
                                <span className="fw-bold text-gray-800 fs-7 me-2" key={index}>
                                    {v.room && (v.room.g_resourceName || "")}
                                    ({v.room && (v.room.g_capacity || "0")}) ⋅
                                </span>
                            ))}

                        </div>
                    </div>
                </div>
            )}

            {data.event_member && Object.values(data.event_member).length > 0 && (
                <div className="mb-5">
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px me-3">
                            <span className="symbol-label">
                                <KTIcon iconName="people" className="fs-1" />
                            </span>
                        </div>

                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 fs-8 d-block">Participants </span>
                            {data.event_member.map((v, index) => (
                                <span className="badge badge-light-primary fw-bold text-gray-800 fs-7 me-2 mb-1" key={index}>
                                    {v.sgumail || ""}
                                </span>
                            ))}

                        </div>
                    </div>
                </div>
            )}

            {(data.emp && Object.values(data.emp).length > 0) && (
                <div className="mb-5">
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-30px me-3">
                            <span className="symbol-label">
                                <KTIcon iconName="user" className="fs-1" />
                            </span>
                        </div>
                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 fs-8 d-block">Lecturer </span>
                            {data.emp.map((v, index) => (
                                <span className="fw-bold text-gray-800 fs-7 d-block" key={index}>
                                    {v.fullname || ""}
                                </span>
                            ))}

                        </div>
                    </div>
                </div>
            )}

            <div className="d-flex justify-content-between align-items-center mt-8">
                {widget_50 && (
                    isRemove ? (
                        <div className="confirm-remove alert alert-danger text-center">
                            <p className="mb-3">Are you sure want to remove ?</p>
                            <ButtonTersier type="button" className="btn-sm px-8 me-2" onClick={() => setIsRemove(false)}>
                                No
                            </ButtonTersier>
                            <ButtonDanger type="button" className="btn-sm px-8" onClick={() => HandlerRemove(data.id)}>
                                Yes
                            </ButtonDanger>
                        </div>
                    ) : (
                        <ButtonIcon type="button" className="btn-sm" title="Remove schedule" onClick={() => setIsRemove(true)}>
                            <KTIcon iconName="trash" className="fs-1 text-danger" />
                        </ButtonIcon>
                    )
                )}

                {data.section_id && (
                    <LinkBtn to={"/course-managements/section/" + data.section_id + "/timetable"} target='_blank' className="btn btn-sm btn-light d-flex align-items-center">
                        <KTIcon iconName="calendar" />
                        <span>View timetable</span>
                    </LinkBtn>
                )}
            </div>
        </div>
    )
}

const ConsentEvent = (props) => {
    const { selectedDate, ReloadData, roomSelected, room_id, iscustom } = props;

    const [selectedForm, setSelectedForm] = useState("");

    if (selectedForm === "event-management") {
        openModal({ header: "Create a Event Schedule for " + moment(selectedDate).format("DD MMM YYYY hh:mm a"), size: "lg", message: <FormCreateEvent iscustom={iscustom} ReloadData={ReloadData} date={selectedDate} room_id={room_id} /> });
    } else if (selectedForm === "schd-class") {
        openModal({ header: "Create a Class Schedule for " + moment(selectedDate).format("DD MMM YYYY hh:mm a"), size: "xl", message: <CreateTimetable iscustom={iscustom} ReloadData={ReloadData} date={selectedDate} room_id={room_id} /> });
    }

    return (
        <div className="text-center mt-5">
            <p className="fs-4">Please select the type of schedule you want to create:</p>
            <div className="d-flex align-items-center justify-content-between px-8">
                <ButtonTersier type="button" className="btn-sm fw-bold" onClick={() => setSelectedForm("schd-class")} >
                    <KTIcon iconName="teacher" className="fs-3x text-primary" />
                    <span className="d-block mt-3 fs-5">
                        Class Schedule
                    </span>
                </ButtonTersier>
                <ButtonTersier type="button" className="btn-sm fw-bold" onClick={() => setSelectedForm("event-management")} >
                    <KTIcon iconName="geolocation" className="fs-3x text-info" />
                    <span className="d-block mt-3 fs-5">
                        Event Schedule
                    </span>
                </ButtonTersier>
            </div>
        </div>
    )
}

const htmlToImageConvert = (elementRef, title, setLoad) => {
    setLoad(true);
    toPng(elementRef.current, { cacheBust: false, quality: 4 })
        .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "Calendar " + title + ".png";
            link.href = dataUrl;
            link.click();
            setLoad(false);
        })
        .catch((err) => {
            console.log(err);
            openModal({ message: <MessageError message={"Failed download calendar."} /> })
            setLoad(false);
        });
};

const FetchWidgets = async (param, setWidgets) => {
    setWidgets({ loading: true, data: [], message: "" });
    const result = await GET_WIDGET_BY_GROUP(param);

    if (result.data && Object.values(result.data).length > 0) {
        const isenabled = result.data.find((item) => item.is_enabled === 1);
        const data = ExtractWidgetIds(isenabled);
        setWidgets({ loading: false, message: "", data: data });
    } else {
        setWidgets(result);
    }
}

const CalendarPrevNext = ({ start_dt, setPostParam, schd = false }) => {

    const HandlerPrevNextWeek = (type) => {
        let startOfWeeks = moment(start_dt).format("YYYY-MM-DD");
        let endOfWeeks = moment(start_dt).format("YYYY-MM-DD");

        if (type === "prev") {
            startOfWeeks = moment(start_dt)
                .subtract(1, "weeks")
                .startOf("weeks")
                .format("YYYY-MM-DD");
            endOfWeeks = moment(start_dt)
                .subtract(1, "weeks")
                .endOf("weeks")
                .format("YYYY-MM-DD");
        } else if (type === "next") {
            startOfWeeks = moment(start_dt)
                .add(1, "weeks")
                .startOf("weeks")
                .format("YYYY-MM-DD");
            endOfWeeks = moment(start_dt)
                .add(1, "weeks")
                .endOf("weeks")
                .format("YYYY-MM-DD");
        }

        if (schd) {
            setPostParam((prev) => {
                const data = { ...prev };
                data.start_dt = startOfWeeks;
                data.end_dt = endOfWeeks;
                return data;
            })
        } else {
            setPostParam((prev) => {
                const data = { ...prev };
                data.tgl = startOfWeeks;
                data.tglend = endOfWeeks;
                return data;
            })
        }
    }

    const CustomDatePicker = forwardRef(
        ({ onClick }, ref) => (
            <ButtonIcon className="btn-sm btn-light-primary" title="Custome date" type="button" ref={ref} onClick={onClick}>
                <KTIcon iconName="calendar" className="fs-1" />
            </ButtonIcon>
        ),
    );

    const HandlerSelectedDate = (date) => {
        const startOfWeeks = moment(date).startOf("weeks").format("YYYY-MM-DD");
        const endOfWeeks = moment(date).endOf("weeks").format("YYYY-MM-DD");
        if (schd) {
            setPostParam((prev) => {
                const data = { ...prev };
                data.start_dt = startOfWeeks;
                data.end_dt = endOfWeeks;
                return data;
            })
        } else {
            setPostParam((prev) => {
                const data = { ...prev };
                data.tgl = startOfWeeks;
                data.tglend = endOfWeeks;
                return data;
            })
        }

    }

    return (
        <div className="d-flex align-items-center">
            <DatePicker
                selected={moment(start_dt).toDate()}
                onChange={(date) => HandlerSelectedDate(date)}
                customInput={<CustomDatePicker />}
                withPortal
            />

            <div className="btn-group ms-3">
                <ButtonIcon className="btn-sm btn-light-primary" title="Previous Week" type="button" onClick={() => HandlerPrevNextWeek("prev")}>
                    <KTIcon iconName="left" className="fs-1" />
                </ButtonIcon>
                <ButtonIcon className="btn-sm btn-light-primary" title="Next Week" type="button" onClick={() => HandlerPrevNextWeek("next")}>
                    <KTIcon iconName="right" className="fs-1" />
                </ButtonIcon>
            </div>
        </div>
    )
}

const CalendarNotes = () => {
    return (
        <div className="fs-7">
            <p className="mb-0 fw-bolder">Notes:</p>
            <div className="d-flex align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                    <div className="p-2 bg-danger border border-danger me-2"></div>
                    <div className="text-dark">
                        : Room Conflict
                    </div>
                </div>
                <div className="d-flex justify-content-start align-items-center ms-3">
                    <div className="p-2 border border-secondary bg-white me-2"></div>
                    <div className="text-dark">
                        : Click in empty cell to create a new event
                    </div>
                </div>
            </div>
        </div>
    )
}
export { EventBoxCall, ConsentEvent, CalendarPrevNext, htmlToImageConvert, CalendarNotes }