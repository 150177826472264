import React, { useEffect, useState } from "react";
import { Line } from "./charts/Line";
import { Donat } from "./charts/Donat";
import { FeeData } from "./charts/FeeData";
import { MajorChart } from "./charts/MajorChart";
import { Bar } from "./charts/Bar";
import { GET_ENROLLDATA } from "../../apis/node-target-data/MarketingAPI";
import {
  DistincDataBaseOnEmailAndYearIntake,
  SplitByYearIntake,
} from "./helper/dataSpliter";
import { LoadingBars } from "../../../../_layouts/components/loadbars/LoadingBars";
import { RadialBar } from "./charts/RadialBar";
import { useNavigate } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";

export function ADMDashboard() {
  const navigation = useNavigate();
  const [listYear, setListYear] = useState([]);
  const [superRawData, setSuperRawData] = useState([]);
  const [rawData, setRawData] = useState({
    loading: false,
    message: "",
    data: {},
  });

  const getData = async () => {
    setRawData({
      loading: true,
      message: "",
      data: {},
    });

    try {
      // Fetch data from API
      const get = await GET_ENROLLDATA();
      console.log(get);

      if (get.message === "") {
        setSuperRawData(get.data);
        const distinc = DistincDataBaseOnEmailAndYearIntake(get.data);
        const split = SplitByYearIntake(distinc);
        // console.log(split);

        setListYear(Object.keys(split));

        setRawData({
          loading: get.loading,
          message: get.message,
          data: split,
        });
      } else {
        setRawData({
          loading: false,
          message: get.message,
          data: {},
        });
      }
    } catch (error) {
      console.log(error.message);
      setRawData({
        loading: false,
        message: error.message,
        data: {},
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return rawData.loading ? (
    <LoadingBars />
  ) : rawData.message !== "" ? (
    <AlertNotif messages={rawData.message} />
  ) : (
    <div>
      <div className="row g-5 g-xl-8">
        <div className="col-lg-6">
          <Line
            className="card-xl-stretch mb-xl-8"
            data={rawData.data}
            loading={rawData.loading}
          />
        </div>
        <div className="col-lg-3">
          <RadialBar
            className="card-xl-stretch mb-xl-8"
            data={rawData.data}
            listYear={listYear}
            loading={rawData.loading}
          />
        </div>
        {/* <div className="col-lg-3">
          <Donat
            className="card-xl-stretch mb-xl-8"
            data={rawData.data}
            listYear={listYear}
            loading={rawData.loading}
          />
        </div> */}
        <div className="col-lg-3">
          <div className="row g-0">
            <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Registration Fee"
              data={superRawData}
              listYear={listYear}
            />
            {/* <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Tuition Fee"
              data={superRawData}
              listYear={listYear}
            /> */}
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-lg-4">
          <MajorChart
            className="card-xl-stretch mb-xl-8"
            data={rawData.data}
            listYear={listYear}
          />
        </div>
        <div className="col-lg-8">
          <Bar
            className="card-xl-stretch mb-xl-8"
            data={rawData.data}
            listYear={listYear}
          />
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-12"></div>
      </div>
    </div>
  );
}
