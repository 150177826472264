import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import {
  GET_STUDENT_ACTIVITY,
  GET_STUDENT_ACTIVITY_DETAIL,
  GET_TRANSFER_PROPOSAL,
} from "../../../../apis/node-target-data/AcademicApi";
import { LoadingBars } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { ButtonIcon } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../../_layouts/components/modals/Modals";
import { ActionPCF, ActionTransfer, DetailOtherActivity } from "./ActivityComponent";

export default function ActivityList({ stdAcad, name }) {
  const [actHist, setActHist] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setActHist({ loading: true, message: "", data: [] });
    const param = { studentid: stdAcad.studentid };
    const result = await GET_STUDENT_ACTIVITY(param);
    if (result.data && Object.values(result.data).length > 0) {
      const sort_data = result.data.sort(
        (a, b) => a.atp_id_term - b.atp_id_term
      );
      setActHist({ loading: false, message: "", data: sort_data });
    } else {
      setActHist(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder fs-3 mb-1">
          {name}'s Activity
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          List of Activities Completed During Studies
        </span>
      </SGUCard.Header>
      <SGUCard.Body>
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
            <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th>No</th>
                <th>Activity</th>
                <th>Semester</th>
                <th>Status</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {actHist.loading ? (
                <tr>
                  <td colSpan={5}>
                    <LoadingBars />
                  </td>
                </tr>
              ) : actHist.message ? (
                <tr>
                  <td colSpan={5}>
                    <AlertRetry
                      messages={actHist.message}
                      Reload={ReloadData}
                    />
                  </td>
                </tr>
              ) : Object.values(actHist.data).length > 0 ? (
                actHist.data.map((v, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {v.student_activity ? v.student_activity.name : "-"}
                    </td>
                    <td>Semester {v.semester}</td>
                    <td>
                      <p
                        className={
                          "mb-0 text-" +
                          (v.status_activity === "APPROVED"
                            ? "info"
                            : "danger") +
                          " p-2 fw-bolder"
                        }
                      >
                        {v.status_activity}
                      </p>
                    </td>
                    <td>
                      <BtnActionActivity data={v} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}

const BtnActionActivity = ({ data }) => {
  const activity_name = data?.student_activity?.name || "";
  if (activity_name.includes("PCF")) {
    return <ActionPCF data={data} />;
  } else if (activity_name.includes("Transfer Credit")) {
    return <ActionTransfer data={data} />
  } else if(activity_name.includes("Leave")){
    return (
      <ButtonIcon
        className="btn-sm btn-light-primary fw-bold"
        onClick={() =>
          openModal({
            header: "Detail Activity",
            message: <DetailOtherActivity eventid={data.id} />,
            size: "sm",
          })
        }
      >
        <KTIcon iconName="arrow-right" className="fs-5" />
      </ButtonIcon>
    );
  }else{
    return "";
  }
};
