/* eslint-disable no-undef */
import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";
import { LoadingBars } from "../../../../../_layouts/components/loadbars/LoadingBars";
import YearDropdown from "../helper/YearDropdown";
import { DefaultYear } from "../../components/EnrollmentComp";

const Donat = ({
  className,
  chartSize = 150,
  chartLine = 20,
  chartRotate = 145,
  data,
  listYear,
}) => {
  const chartRef = useRef(null);
  const [year, setYear] = useState(DefaultYear());

  const CookedData = useMemo(() => {
    if (Object.keys(data).length > 0) {
      let slpitEnrollStatus = {
        draft: data[year].filter((item) => item.enroll_status === "DRAFT"),
        onProcess: data[year].filter(
          (item) => item.enroll_status === "ON PROCESS"
        ),
        complete: data[year].filter(
          (item) => item.enroll_status === "FINISHED"
        ),
        canceled: data[year].filter(
          (item) => item.enroll_status === "CANCELED"
        ),
      };

      const draft = slpitEnrollStatus.draft.length;
      const onProcess = slpitEnrollStatus.onProcess.length;
      const complete = slpitEnrollStatus.complete.length;
      const canceled = slpitEnrollStatus.canceled.length;

      const newData = {
        totalRegistration: draft + onProcess + complete + canceled,
        draft: draft,
        onProcess: onProcess,
        complete: complete,
        canceled: canceled,
      };
      return newData;
    }
  }, [data, year]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, CookedData);
    }, 10);
  };

  useEffect(() => {
    if (CookedData) {
      refreshChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CookedData]);

  return !CookedData ? (
    <LoadingBars />
  ) : (
    <div className={`card card-flush ${className}`}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Registration Progress
          </span>
          <span className="text-muted fw-semibold fs-7" style={{marginTop: -5, marginLeft: -10}}>
            <YearDropdown data={year} setData={setYear} listYear={listYear} />
          </span>
        </h3>
      </div>

      <div className="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
        <div className="d-flex flex-center me-5 pt-2 position-relative">
          <div
            id="kt_card_widget_17_chart"
            ref={chartRef}
            style={{ minWidth: chartSize + "px", minHeight: chartSize + "px" }}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
          <div className="position-absolute d-flex flex-column align-items-center">
            <span className="text-muted fw-bold">Total</span>
            <span className="fs-1 fw-bold">
              {CookedData ? CookedData.totalRegistration : ""}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column content-justify-center flex-row-fluid">
          <div className="d-flex fw-semibold align-items-center">
            <div
              className="bullet w-8px h-3px rounded-2 me-3"
              style={{ backgroundColor: "#dbdbdb" }}
            ></div>
            <div className="text-gray-500 flex-grow-1 me-4">Draft</div>
            <div className=" fw-bolder text-gray-700 text-xxl-end">
              {CookedData ? CookedData.draft : ""}
            </div>
          </div>
          <div className="d-flex fw-semibold align-items-center my-3">
            <div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
            <div className="text-gray-500 flex-grow-1 me-4">On Process</div>
            <div className="fw-bolder text-gray-700 text-xxl-end">
              {CookedData ? CookedData.onProcess : ""}
            </div>
          </div>
          <div className="d-flex fw-semibold align-items-center">
            <div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
            <div className="text-gray-500 flex-grow-1 me-4">Complete</div>
            <div className="fw-bolder text-gray-700 text-xxl-end">
              {CookedData ? CookedData.complete : ""}
            </div>
          </div>
          <div className="d-flex fw-semibold align-items-center my-3">
            <div className="bullet w-8px h-3px rounded-2 bg-danger me-3"></div>
            <div className="text-gray-500 flex-grow-1 me-4">Canceled</div>
            <div className="fw-bolder text-gray-700 text-xxl-end">
              {CookedData ? CookedData.canceled : ""}
            </div>
          </div>
        </div>

        <div className="alert alert-dismissible bg-light-primary border border-primary border-dashed p-5 mb-5 mt-10">
          <strong>The current registration</strong> shows good progress with{" "}
          <span className="text-success fw-bolder">
            {(
              (CookedData.complete / CookedData.totalRegistration) *
              100
            ).toFixed(2)}
            %
          </span>{" "}
          of registrations <strong>completed</strong>. A total of{" "}
          <span className="text-gray-700 fw-bolder">
            {((CookedData.draft / CookedData.totalRegistration) * 100).toFixed(
              2
            )}
            %
          </span>{" "}
          are still in the draft <strong>stage</strong>,{" "}
          <span className="text-danger fw-bolder">
            {(
              (CookedData.canceled / CookedData.totalRegistration) *
              100
            ).toFixed(2)}
            %
          </span>{" "}
          have been <strong>canceled</strong>, and the remaining{" "}
          <span className="text-primary fw-bolder">
            {(
              (CookedData.onProcess / CookedData.totalRegistration) *
              100
            ).toFixed(2)}
            %
          </span>{" "}
          are still in <strong>progress</strong>.
        </div>
        
      </div>
    </div>
  );
};

const initChart = (chartSize = 70, chartLine = 11, chartRotate = 145, data) => {
  const el = document.getElementById("kt_card_widget_17_chart");
  if (!el) {
    return;
  }
  el.innerHTML = "";

  const options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
  };

  const canvas = document.createElement("canvas");
  const span = document.createElement("span");

  if (typeof G_vmlCanvasManager !== "undefined") {
    G_vmlCanvasManager.initElement(canvas);
  }

  const ctx = canvas.getContext("2d");
  canvas.width = canvas.height = options.size;

  el.appendChild(span);
  el.appendChild(canvas);

  ctx?.translate(options.size / 2, options.size / 2);
  ctx?.rotate((-1 / 2 + options.rotate / 180) * Math.PI);

  const radius = (options.size - options.lineWidth) / 2;

  const drawCircle = (color, lineWidth, percent) => {
    if (percent > 0) {
      percent = Math.min(Math.max(0, percent || 1), 1);
      if (!ctx) {
        return;
      }
    }

    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);
    ctx.strokeStyle = color;
    ctx.lineCap = "round";
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  };

  // Convert the object to an array of key-value pairs
  const sortedData = Object.entries(data).sort((a, b) => b[1] - a[1]); // Sort by values (a[1] and b[1])

  // Draw circles based on the sorted data
  sortedData.forEach(([key, value]) => {
    let color;
    switch (key) {
      case "draft":
        color = "#dbdbdb";
        break;
      case "onProcess":
        color = getCSSVariableValue("--bs-primary");
        break;
      case "complete":
        color = getCSSVariableValue("--bs-success");
        break;
      case "canceled":
        color = getCSSVariableValue("--bs-danger");
        break;
      case "totalRegistration":
        color = "#e9f3ff";
        break;
      default:
        color = "#000"; // Fallback color
    }

    drawCircle(color, options.lineWidth, value / data.totalRegistration);
  });
};

export { Donat };
