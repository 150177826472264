import React, { useEffect, useMemo, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";
import { DefaultYear } from "../../components/EnrollmentComp";
import YearDropdown from "../helper/YearDropdown";

const MajorChart = ({ className, data, listYear }) => {
  const chartRef = useRef(null);
  const [degree, setDegree] = useState(1);
  const [loading, setLoading] = useState(false);

  const [year, setYear] = useState(DefaultYear());

  const bachelor_program_name_arr = [
    "Mechatronics Engineering",
    "IT Technopreneurs",
    "AI and Data Science",
    "IE: Smart Industry",
    "IE: Eco-Industry",
    "IT Cyber Security",
    "Hybrid and Electric Vehicles",
    "Hotel and Tourism Management",
    "Business and Management",
    "Business Accounting",
    "Global Strategic Communications",
    "International Culinary Business",
    "Pharmaceutical Chemical Engineering",
    "Food Technology",
    "Biomedical Engineering",
    "Sustainable Energy & Environment",
  ];

  const master_program_name_arr = [
    "Master Program Data Science Cyber Security",
    "Master Program Data Science Business Informatics",
    "Master Program Digital Innovation",
    "Master Program Engineering Management",
    "Master Program Mechatronics",
    "Master of Business Administration",
  ];

  const CookedData = useMemo(() => {
    if (Object.keys(data).length > 0) {
      let slpitEnrollStatus = {
        bachelor: data[year].filter((item) => item.program === "BACHELOR"),
        master: data[year].filter((item) => item.program === "MASTER"),
      };

      const bachelor = slpitEnrollStatus.bachelor;
      const master = slpitEnrollStatus.master;

      let bachelor_program = {};
      bachelor_program_name_arr.forEach((element) => {
        bachelor_program[element] = bachelor.filter(
          (item) => item.target_department_name === element
        );
      });

      let master_program = {};
      master_program_name_arr.forEach((element) => {
        master_program[element] = master.filter(
          (item) => item.target_department_name === element
        );
      });

      const newData = {
        bachelor: bachelor_program,
        master: master_program,
      };
      setLoading(false);
      return newData;
    }
  }, [data, year]);

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(degree, CookedData)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    if (!loading) {
      const chart = refreshMode();

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [chartRef, degree, CookedData, loading]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Major Most Choosen
          </span>
          <span
            className="text-muted fw-semibold fs-7"
            style={{ marginTop: -5, marginLeft: -10 }}
          >
            <YearDropdown data={year} setData={setYear} listYear={listYear} />
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1 ${degree ===
              1 && "active"}`}
            onClick={() => setDegree(1)}
          >
            Bachelor
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${degree ===
              2 && "active"}`}
            onClick={() => setDegree(2)}
          >
            Master
          </a>
        </div>
      </div>
      <div className="card-body">
        <div
          ref={chartRef}
          id="kt_charts_widget_3_chart"
          style={{ height: "350px" }}
        ></div>
      </div>
    </div>
  );
};

export { MajorChart };

function getChartOptions(degree, CookedData) {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const baseColor = getCSSVariableValue("--bs-primary");

  const cek = CookedData ? true : false;

  const master = cek ? Object.keys(CookedData.master) : [0];
  const bachelor = cek ? Object.keys(CookedData.bachelor) : [0];

  const dataBachelor = cek
    ? Object.values(CookedData.bachelor).map((x) => x.length)
    : [0];
  const dataMaster = cek
    ? Object.values(CookedData.master).map((x) => x.length)
    : [0];


  return {
    series: [
      {
        name: "Total",
        data: cek ? (degree === 1 ? dataBachelor : dataMaster) : [0],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar, including the download menu
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: cek ? (degree === 1 ? bachelor : master) : [0],
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
        formatter: function(val) {
          return parseInt(val);
        },
      },
      forceNiceScale: true,
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    grid: {
      borderColor: borderColor,
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    colors: [baseColor],
  };
}
