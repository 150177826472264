import { useEffect, useState } from "react";
import {
  GET_STUDENT_ACTIVITY_DETAIL,
  GET_TRANSFER_PROPOSAL,
} from "../../../../apis/node-target-data/AcademicApi";
import { Link } from "react-router-dom";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { LoadingBars } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";

const ActionTransfer = ({ data }) => {
  const [transfer_id, setTransferID] = useState(0);
  const Get_Transfer_data = async (candidate_id) => {
    const param = { candidate_id };
    const result = await GET_TRANSFER_PROPOSAL(param);
    if (result.data && Object.values(result.data).length > 0) {
      const find_by_id = result.data.find(
        (item) =>
          item.status === "APPROVED" && item.candidate_id === candidate_id
      );
      setTransferID(find_by_id.id);
    }
  };
  useEffect(() => {
    Get_Transfer_data(data.studentid);
  }, []);

  return (
    <Link
      to={
        "/course-managements/transfer-credit-registration/detail/" +
        btoa(transfer_id)
      }
      target="_blank"
      className="btn btn-sm btn-light-primary btn-icon"
    >
      <KTIcon iconName="arrow-right" className="fs-5" />
    </Link>
  );
};

const ActionPCF = ({ data }) => {
  return (
    <Link
      to={
        "/students/academic-activity/" +
        btoa(JSON.stringify({ eventid: data.id, studentid: data.studentid }))
      }
      target="_blank"
      className="btn btn-sm btn-light-primary btn-icon"
    >
      <KTIcon iconName="arrow-right" className="fs-5" />
    </Link>
  );
};

const DetailOtherActivity = ({ eventid }) => {
  const [data, setData] = useState({ loading: false, data: [], message: "" });
  const ReloadData = async () => {
    setData({ loading: true, data: [], message: "" });
    const param = { eventid: eventid };
    const result = await GET_STUDENT_ACTIVITY_DETAIL(param);

    setData(result);
  };
  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <div className="">
      {data.loading ? (
        <LoadingBars />
      ) : data.message ? (
        <AlertRetry messages={data.message} Reload={ReloadData} />
      ) : (
        Object.values(data.data).length > 0 && (
          <div className="detail">
            <div className="form-group mb-5">
              <label className="fw-bold text-muted">Activity Type:</label>
              <p className="mb-0">
                {data.data.student_activity &&
                  (data.data.student_activity.name || "")}
              </p>
            </div>
            <div className="form-group mb-5">
              <label className="fw-bold text-muted">
                {data.data.student_activity &&
                  (data.data.student_activity.name || "")}{" "}
                in term:
              </label>
              <p className="mb-0">
                {data.data.atp_term && (data.data.atp_term.name || "")}
              </p>
              <p className="mb-0">Semester {data.data.semester || ""}</p>
            </div>
            <div className="form-group mb-5">
              <label className="fw-bold text-muted">Note:</label>
              <p className="mb-0">{data.data.note || "-"}</p>
            </div>
            <div className="form-group mb-5">
              <label className="fw-bold text-muted">Counselor:</label>
              {data.data.counselor &&
                data.data.counselor.map((v, index) => (
                  <p className="mb-0" key={index}>
                    {v.emp && (v.emp.fullname || "-")}
                  </p>
                ))}
            </div>
            <div className="form-group mb-5">
              <label className="fw-bold text-muted">Status:</label>
              <p className="mb-0">
                <span className="d-block">{data.data.status_activity}</span>
                {data.data.approve_date_formatted && (
                    <span>{moment(data.data.approve_date_formatted).format("DD MMM YYYY hh:mm A")}</span>
                )}
              </p>
            </div>
            {data.data.submit_date_formatted && (
              <div className="form-group mb-5">
                <label className="fw-bold text-muted">Submited at:</label>
                <p className="mb-0">
                  {moment(data.data.submit_date_formatted).format(
                    "DD MMM YYYY hh:mm A"
                  )}
                </p>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export { ActionTransfer, ActionPCF, DetailOtherActivity };
