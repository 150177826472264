// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sgu-calendar-week .fc-timegrid-slot,
#sgu-calendar-week .fc-timegrid-axis,
#sgu-calendar-week .fc-col-header-cell {
  border: 1px solid #656564 !important;
}

#sgu-calendar-week .fc-timegrid-slot-lane {
  border-bottom: 1px solid #929292 !important;
}

#sgu-calendar-week .fc-timegrid-col {
  border-left: 1px solid #0af234 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/calendar/components/FullCalendar.css"],"names":[],"mappings":"AAAA;;;EAGE,oCAAoC;AACtC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["#sgu-calendar-week .fc-timegrid-slot,\n#sgu-calendar-week .fc-timegrid-axis,\n#sgu-calendar-week .fc-col-header-cell {\n  border: 1px solid #656564 !important;\n}\n\n#sgu-calendar-week .fc-timegrid-slot-lane {\n  border-bottom: 1px solid #929292 !important;\n}\n\n#sgu-calendar-week .fc-timegrid-col {\n  border-left: 1px solid #0af234 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
