import React from 'react'

export function CurrentGradeCumulative({data}) {
  return (
    <div className='card card-flush'>
      <div className="card-header rounded bg-success bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-150px">
        <h3 className="card-title align-items-start flex-column text-white pt-5">
            <span className="fw-bold fs-2x mb-3">Academic Summary</span>
            <span className="fs-4 text-white opacity-75">Credit and GPA Summary</span>
        </h3>
      </div>

      <div className="card-body mt-n15">
        <div className="mt-n15 position-relative">
            <div className="row g-3 g-lg-6">
                <div className="col-6"><CardInfo name={"CGPA"} value={data?.cgpa || 0} /></div>
                <div className="col-6"><CardInfo name={"Grade"} value={data?.avg_grade || 0} /></div>
                <div className="col-6"><CardInfo name={"Cumulative Credit Summary"} value={(data?.ccredit_gpa || 0)+" SKS"} /></div>
                <div className="col-6"><CardInfo name={"Cumulative Earn Credit"} value={(data?.ccredit_pass || 0)+" SKS"} /></div>
                <div className="col-12"><CardInfo name={"Current Semester"} value={data?.semester || 0} /></div>
            </div>
        </div>
      </div>
    </div>
  )
}

const CardInfo = ({name, value}) =>{
    return (
        <div className="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
            <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">{value}</span>
            <span className="text-gray-500 fw-semibold fs-6">{name}</span>
        </div>
    )
}
