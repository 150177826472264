import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  ButtonDownload,
  ButtonTersier,
} from "../../../../_layouts/components/buttons/ButtonUI";
import DownloadActivity from "./DownloadActivity";
import { StudentIDFormat } from "../../../../_layouts/_helpers/Global";
import {
  ActionPCF,
  ActionTransfer,
} from "../find-student/components/acad-hist/ActivityComponent";

export default function TableData({ data, ReloadData }) {
  const [totalSubmited, setTotalSubmited] = useState(0);
  const [totalApproved, setTotalApproved] = useState(0);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "Student", field: "student_id", sortable: true },
    { name: "Department", field: "student_id", sortable: false },
    { name: "Counselor", field: "counselor", sortable: false },
    { name: "Activity", field: "semester", sortable: true },
    { name: "Submited Date", field: "submit_date_fmt", sortable: true },
    { name: "Approved Date", field: "approve_date_fmt", sortable: true },
    { name: "Detail", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];

    if (data) {
      computedData = data.map((c) => {
        var obj = {};
        obj.atp_id = c.atp_id_term ? c.atp_id_term : "";
        obj.atp_name = c.atp_term ? c.atp_term.name : "";
        obj.event_status = c.status_activity ? c.status_activity : "";
        obj.eventid = c.id ? c.id : 0;
        obj.eventid_enc = btoa(obj.eventid);
        obj.clu_name = c.clu ? c.clu.name : "";
        obj.clu_spec_name = c.clu_spec ? c.clu_spec.name : "";
        obj.clu_spec_code = c.clu_spec ? c.clu_spec : "";
        obj.student_activity = c.student_activity && (c.student_activity || "");
        obj.student_id = c.studentid;
        obj.student_name = c.studentdata ? c.studentdata.fullname || "" : "";
        obj.submit_date = c.submit_date_formatted
          ? c.submit_date_formatted
          : "";
        obj.approve_date = c.approve_date_formatted
          ? c.approve_date_formatted
          : "";
        obj.submit_date_fmt = c.submit_date_formatted
          ? moment(c.submit_date_formatted).format("YYYY-MM-DD hh:mm a")
          : "";
        obj.approve_date_fmt = c.approve_date_formatted
          ? moment(c.approve_date_formatted).format("YYYY-MM-DD hh:mm a")
          : "";
        obj.counselor = c.counselor ? c.counselor : "";
        obj.semester = c.semester ? c.semester.toString() : 0;
        obj.is_status_semester = c.is_status_semester
          ? c.is_status_semester
          : 0;
        return obj;
      });

      computedData.sort((a, b) =>
        a.submit_date_fmt < b.submit_date_fmt ? 1 : -1
      );
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }

    setTotalItems(Object.values(computedData).length);
    let submited = computedData.filter(
      (item) => item.event_status === "SUBMITTED"
    );
    setTotalSubmited(Object.values(submited).length);
    let approved = computedData.filter(
      (item) => item.event_status === "APPROVED"
    );
    setTotalApproved(Object.values(approved).length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, currentPage, search, sorting]);

  return (
    <div id="table-data">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <div className="btn-group border-end pe-2 rounded-0">
            <ButtonTersier className="btn-sm" disabled={true}>
              Total Submited {totalSubmited}
            </ButtonTersier>
            <ButtonTersier className="btn-sm" disabled={true}>
              Total Approved {totalApproved}
            </ButtonTersier>
          </div>
          <div className="mx-2">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
          {totalItems > 0 && (
            <ButtonDownload
              className="btn-sm"
              type="button"
              onClick={() =>
                DownloadActivity(
                  data,
                  data[0].atp_term ? data[0].atp_term.name : "-"
                )
              }
            >
              Export to excel
            </ButtonDownload>
          )}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td width={"20%"}>
                    <span className="mb-1 fs-7 text-dark fw-bold d-block">
                      {v.student_name}
                    </span>
                    <span className="fs-7 text-muted ">
                      {StudentIDFormat(v.student_id)}
                    </span>
                  </td>
                  <td width={"20%"}>
                    <span className="mb-1 fs-7 text-dark fw-bold d-block">
                      {v.clu_name}
                    </span>
                    {v.clu_spec_name && (
                      <span className="mb-1 fs-7 text-muted">
                        {v.clu_spec_name}
                      </span>
                    )}
                  </td>
                  <td width={"20%"}>
                    {Object.values(v.counselor).length > 0
                      ? v.counselor.map((c, index) => (
                          <span
                            key={index}
                            className="mb-1 fs-7 text-dark fw-bolder d-block"
                          >
                            {c.emp ? c.emp.fullname : "-"}
                          </span>
                        ))
                      : "-"}
                  </td>
                  <td width={"15%"}>
                    <div className={"fs-7"}>
                      <div>
                        <span className="fw-bolder d-block">
                          <span className="text-primary">
                            {v.student_activity ? v.student_activity.name : "-"}
                          </span>{" "}
                        </span>
                        <span className="d-block fw-bolder">
                          Semester {v.semester}
                        </span>
                        <span
                          className={
                            "w-100 badge badge-light-" +
                            (v.event_status === "APPROVED"
                              ? "primary"
                              : "danger")
                          }
                        >
                          <i
                            className={
                              "bi " +
                              (v.event_status === "APPROVED"
                                ? "bi-check-circle-fill text-primary"
                                : "bi-exclamation-circle-fill text-danger")
                            }
                          ></i>
                          <span className="ms-2">{v.event_status}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td width={"10%"}>
                    {v.submit_date && (
                      <>
                        <span className="fs-7 d-block fw-bolder">
                          {moment(v.submit_date).format("DD MMM YYYY")}
                        </span>
                        <span className="fs-7 text-muted">
                          {moment(v.submit_date).format("hh:mm A")}
                        </span>
                      </>
                    )}
                  </td>
                  <td width={"10%"}>
                    <span className="fs-7 d-block fw-bolder">
                      {v.approve_date &&
                        moment(v.approve_date).format("DD MMM YYYY")}
                    </span>
                    <span className="fs-7 text-muted">
                      {v.approve_date &&
                        moment(v.approve_date).format("hh:mm A")}
                    </span>
                  </td>
                  <td><BtnActionActivity data={v} /></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>

        {totalItems > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const BtnActionActivity = ({ data }) => {
  const activity_name = data?.student_activity?.name || "";
  if (activity_name.includes("PCF")) {
    return (
      <Link
        to={btoa(
          JSON.stringify({
            eventid: data.eventid,
            studentid: data.student_id,
          })
        )}
        className="btn btn-icon btn-sm btn-light-primary"
      >
        <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
      </Link>
    );
  } else if (activity_name.includes("Transfer Credit")) {
    let data_param = data;
    data_param.studentid = data.student_id;
    return <ActionTransfer data={data_param} />;
  } else if (activity_name.includes("Leave")) {
    return (
      <Link
        to={btoa(
          JSON.stringify({
            eventid: data.eventid,
            studentid: data.student_id,
          })
        )}
        className="btn btn-icon btn-sm btn-light-primary"
      >
        <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
      </Link>
    );
  } else {
    return "";
  }
};
