import React, { useEffect, useMemo, useRef } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";
import { LoadingBars } from "../../../../../_layouts/components/loadbars/LoadingBars";

const Line = ({ className, data, loading }) => {
  const chartRef = useRef(null);

  const CookedData = useMemo(() => {
    if (Object.keys(data).length > 0) {
      let slpitDegree = {};
      Object.keys(data).forEach((key) => {
        slpitDegree[key] = {
          BACHELOR: data[key].filter((item) => item.program === "BACHELOR"),
          MASTER: data[key].filter((item) => item.program === "MASTER"),
        };
      });
      return slpitDegree;
    }
  }, [data]);
  // console.log("cooked data -> ", CookedData);

  const getYearArray = () => {
    if (CookedData) {
      return Object.keys(CookedData);
    }
    return [];
  };

  const getBachelorTotalEachYear = () => {
    if (CookedData) {
      let newBachelorArray = [];
      return getYearArray().reduce((acc, year) => {
        newBachelorArray.push(CookedData[year].BACHELOR.length);
        return newBachelorArray;
      }, {});
    }
    return {};
  };

  const getMasterTotalEachYear = () => {
    if (CookedData) {
      let newMasterArray = [];
      return getYearArray().reduce((acc, year) => {
        newMasterArray.push(CookedData[year].MASTER.length);
        return newMasterArray;
      }, {});
    }
    return {};
  };

  // console.log("year array", getYearArray());
  // console.log("bachelor array", getBachelorTotalEachYear());
  // console.log("master array", getMasterTotalEachYear());

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        getYearArray(),
        getBachelorTotalEachYear(),
        getMasterTotalEachYear()
      )
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, CookedData]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Annual Appliant</span>
          <span className="text-muted fw-semibold fs-7">
            Last update: Today at 00:00 AM
          </span>
        </h3>
      </div>
      {loading ? (
        <LoadingBars />
      ) : (
        <div className="card-body">
          <div
            ref={chartRef}
            id="kt_charts_widget_3_chart"
            style={{ height: "350px" }}
          ></div>
        </div>
      )}
    </div>
  );
};

export { Line };

function getChartOptions(y, b, m) {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const bachelor = getCSSVariableValue("--bs-primary");
  const master = getCSSVariableValue("--bs-info");

  return {
    series: [
      {
        name: "Bachelor",
        data: b && b.length ? b : [0],
      },
      {
        name: "Master",
        data: m && m.length ? m : [0],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "line",
      height: 350,
      zoom: {
        enabled: false, // Disable zoom to get the default cursor
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: "top", // Position the legend at the top
      horizontalAlign: "right", // Align it to the right
      floating: false, // Set to true if you want it to float
      offsetY: 0, // Optional: adjust vertical offset
      offsetX: 0,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [bachelor, master],
    },
    xaxis: {
      categories: y && y.length ? y : [0],
      // labels: {
      //   style: {
      //     colors: labelColor,
      //     fontSize: "12px",
      //   },
      // },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    colors: [bachelor, master],
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };
}
