import React from 'react'
import { CardActiveCalendar } from './components/CardsUI'
import TimetableStd from './TimetableStd'
import { CurrentGradeCumulative } from './CurrentGradeCumulative'
import CardCounselor from './CardCounselor'
import ClassList from './ClassList'
import { PCFAmmount } from './CardActivity'

export function MyDashboard({ stdAcad, stdBio }) {
    return (
        <div id="dashboard-student">
            <div className='row gx-5 gx-xl-10'>
                <div className="col-xl-8">
                    <CardActiveCalendar data={stdAcad} />
                    <TimetableStd data={stdAcad} />
                </div>
                <div className="col-xl-4">
                    <div className="">
                        <PCFAmmount studentid={stdAcad.studentid} />
                    </div>
                    <div className="my-5">
                        <CurrentGradeCumulative data={stdAcad} />
                    </div>
                    <CardCounselor studentid={stdAcad.studentid} />
                </div>
            </div>
            <div className='row gx-5 gx-xl-10'>
                <div className="col-12">
                    <ClassList stdAcad={stdAcad} stdBio={stdBio} />
                </div>
            </div>
        </div>
    )
}
