import { useEffect, useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  OverlayTrigger,
  Popover,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import {
  DELETE_MKT_COUPON,
  POST_ENROLLMENT_REGISTER,
} from "../../../apis/node-target-data/MarketingAPI";
import { FeeStatus, formatRibuan, StatusCoupon } from "../../components/Helper";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { BadgeItem } from "../../components/EnrollmentComp";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";

export default function TableDataCoupon({
  results,
  ReloadData,
  setCookedData,
  triggerNotification,
  widget_create_coupon,
}) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [copyStatus, setCopyStatus] = useState("Copy coupon");
  const [indexDelete, setIndexDelete] = useState(-1);

  const headers = [
    // { name: "Coupon Type", field: "coupon_type", sortable: false },
    { name: "Code", field: "code", sortable: false },
    { name: "Discount Type", field: "discount_type", sortable: false },
    { name: "Discount", field: "discount", sortable: false },
    { name: "Created/Expired", field: "created_expired_date", sortable: false },
    { name: "Created By", field: "created_by", sortable: false },
    { name: "Used By", field: "used_by", sortable: false },
    { name: "Trans ID", field: "trans_id", sortable: false },
    { name: "Enroll ID", field: "enroll_id", sortable: false },
    { name: "Status", field: "status", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results) {
      computedData = results.data.map((c) => {
        let obj = {};
        obj.coupon_type = c.coupon_type?.includes("regfee")
          ? "Reg Fee"
          : "Tuition Fee";
        obj.coupon_no = c.coupon_no;
        obj.discount_type = c.discount_type?.includes("nominal")
          ? "Nominal"
          : "Percentage";
        obj.coupon_discount = formatRibuan(c.coupon_discount);
        obj.coupon_created = moment(c.coupon_created).format(
          "DD MMM YYYY HH:mm"
        );
        obj.expiration_date = moment(c.expiration_date).format("DD MMM YYYY");
        obj.coupon_createdby = c.coupon_createdby;
        obj.used_by = c.candidate_id;
        obj.trans_id = c.trans_id;
        obj.enroll_id = c.enroll_id;
        obj.fullname = c.fullname;
        obj.status = StatusCoupon(c.trans_id, c.expiration_date);

        obj.desc = c.coupon_created;
        obj.real = c;
        return obj;
      });

      computedData.sort((a, b) => (b.desc > a.desc ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) => {
          const value = listData[key];
          return (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          );
        });
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // console.log("computedData ", computedData);
    return computedData;
  }, [results, search, sorting]);

  const CreteNew = async () => {
    const aha = {
      address: "Jl.%20Hore%20RT.45%2FRW.55",
      birthcountry: "Indonesia",
      birthdate: "2024-10-26",
      birthplace: "Pati",
      candidate_id: "SGU-241004124636H6",
      citizenship: 103,
      city: "tangsel",
      company_address: "Jl.%20Hore%20RT.45%2FRW.55",
      company_city: "Ura",
      company_email: "com@gmail.com",
      company_name: "SGU",
      company_position: "IT Dev",
      country_id: 103,
      email: "nurammarnaufal@gmail.com",
      enrollment_type: "mkt.enrollment.type.reguler",
      firstname: "Ist Test",
      gender: "p",
      idtype_ktp: "3434343",
      idtype_passport: "3434343",
      lastname: "Naufal",
      middlename: "Ammar",
      mkt_info_id: 50,
      mkt_info_oth: "Smaug News",
      mobile: "222222222222",
      postalcode: "111111111",
      religion: 1,
      target_department: 33,
      university: "Universitas Teknologi Yogyakarta",
      university_gpa: "3.65",
      university_id: 123,
      university_prodi: "TE",
      university_year_graduate: 2019,
      whatsapp: "2222222222",
      yearintake: 30,
      "g-recaptcha-response": "",
    };
    const result = await POST_ENROLLMENT_REGISTER(aha);
    console.log(result);
  };

  useEffect(() => {
    if (ResultData) setCookedData(ResultData);
  }, [ResultData]);

  const handleCopy = async (cpn) => {
    try {
      await navigator.clipboard.writeText(cpn);
      setCopyStatus("Copied");
      setTimeout(() => {
        setCopyStatus("Copy coupon");
      }, 3000);
    } catch (err) {
      setCopyStatus("Failed to copy text.");
    }
  };

  const CopyCoupon = (name) => {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="bottom"
        overlay={renderTooltip({ title: copyStatus })}
      >
        <div>
          <span className="fs-bold" onClick={() => handleCopy(name)}>
            {name}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  const DeleteCoupon = async (coupon_no, index) => {
    setIndexDelete(index);
    console.log(coupon_no);
    const del = await DELETE_MKT_COUPON({ coupon_no: coupon_no });
    setIndexDelete(-1);

    triggerNotification({
      title: del.message ? "Failed!" : "Success",
      type: del.message ? "danger" : "success",
      message: del.message
        ? "This coupon has already been used"
        : "Success delete a coupon",
      duration: 3000,
    });

    if (del.message === "") {
      ReloadData();
    }
  };

  return (
    <div className="position-relative">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed gy-5 fs-6 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td className="fs-7">
                    <span className="d-block fw-bolder cursor-pointer">
                      {CopyCoupon(v.coupon_no)}
                    </span>
                    <BadgeItem
                      icon="some-files"
                      name={v.coupon_type}
                      title="Coupon Type"
                    />
                  </td>
                  <td className="fs-7 fw-bold">{v.discount_type}</td>
                  <td className="fs-7 fw-bold">{v.coupon_discount}</td>
                  <td className="fs-5 fw-bolder text-nowrap">
                    <span className="d-block badge badge-light-primary">
                      {v.coupon_created}
                    </span>
                    <span className="d-block badge badge-light"></span>
                    <span className="d-block badge badge-light-danger">
                      {v.expiration_date}
                    </span>
                  </td>
                  <td className="fs-7 fw-bold">{v.coupon_createdby}</td>
                  <td className="fs-7">
                    <span className="d-block fw-bolder">{v.fullname}</span>
                    {v.used_by && (
                      <BadgeItem
                        icon="profile-circle"
                        name={v.used_by}
                        title="Candidate ID"
                      />
                    )}
                  </td>
                  <td className="fs-7 fw-bold">{v.trans_id}</td>
                  <td className="fs-7 fw-bold">{v.enroll_id}</td>
                  <td className="fs-7">
                    <BadgeItem icon="" name={v.status} title="Status Coupon" />
                  </td>
                  <td className="fs-7 fw-bold text-center">
                    {widget_create_coupon ? (
                      <OverlayTrigger
                        delay={{ show: 250, hide: 400 }}
                        placement="bottom"
                        overlay={renderTooltip({ title: "Delete Coupon" })}
                      >
                        <div
                          className={`align-items-center`}
                          onClick={() => DeleteCoupon(v.coupon_no, index)}
                        >
                          {index === indexDelete ? (
                            <SpinnerLoad />
                          ) : (
                            <KTIcon
                              iconName="trash"
                              className="fs-2 cursor-pointer text-hover-danger"
                            />
                          )}
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <KTIcon iconName="trash" className="fs-2 opacity-50" />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <div onClick={() => CancelCoupon()}>
        <ButtonPrimary>
          <KTIcon iconName="send" className="fs-3 me-2" />
          <span>Cancel Coupon</span>
        </ButtonPrimary>
      </div>
      <div onClick={() => CreteNew()}>
        <ButtonPrimary>
          <KTIcon iconName="send" className="fs-3 me-2" />
          <span>Create</span>
        </ButtonPrimary>
      </div> */}
    </div>
  );
}
