import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { AvaBlank, AvaFirstName } from '../../../../_layouts/components/images/Avatars'
import Profile from '../../../../_layouts/components/widgets/Profile'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { LoadingBars } from '../../../../_layouts/components/loadbars/LoadingBars'
import { useProvider } from '../../../../_layouts/UMSLayoutProvider'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout();
  const { account } = useProvider();
  const [clockState, setClockState] = useState<string>("");
  useEffect(() => {
    const intervalId = setInterval(() => {
      const current_time = moment().format("DD MMM YYYY h:mm A");
      setClockState(current_time);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [])
  return (
    <div className='app-navbar flex-shrink-0 align-items-center'>
      <div className="app-navbar-item ms-1 ms-md-4 d-none d-md-block">
        <div className="bg-light rounded d-flex align-items-center justify-content-center p-3">
          <span className="text-gray-600 fw-bold">{(clockState) ? clockState : <LoadingBars />}</span>
          <KTIcon iconName='calendar' className='fs-2 text-gray-500 lh-0 fs-2 ms-2 me-0' />
        </div>
      </div>

      <div className="app-navbar-item ms-1 ms-md-4 d-none d-md-block">
        <div className="bg-light-primary rounded d-flex align-items-center justify-content-center p-3">
          <KTIcon iconName='security-user' className='fs-1 text-primary lh-0 me-2 ms-0' />
          <span className="text-gray-600 fw-bold">{account.identity.parent_group_name ? account.identity.parent_group_name : account.identity.group_name}</span>
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <Profile />
        </div>
        <HeaderUserMenu />
      </div>



      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }
