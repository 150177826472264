import React, { useState } from 'react'
import { ButtonLong } from '../../../_layouts/components/buttons/ButtonUI';
import { ConsumerKey, InputPassword, InputUsername } from './components/From';
import { MessageError, MessageSuccess, openModal } from '../../../_layouts/components/modals/Modals';
import CryptoJSAesJson from '../../../_layouts/_helpers/CryptoJSAesJson';
import { authStoreData } from '../../reducer/AuthRedux';
import { LoginEstes } from './API';
import { GenerateAppID, GET_ENVIRONMENT } from '../../../_layouts/_core/Core';

export function AuthLogin() {
  const TYPE_ENV = GET_ENVIRONMENT();
  const baseUri = window.location.href;
  var datetime = new Date();
  datetime.setHours(datetime.getHours() + 6);
  const [postData, setPostData] = useState({
    username: "",
    email: "@sgu.ac.id",
    password: "",
    typeCK: "production"
  });
  const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });

  var datetime = new Date();
  datetime.setHours(datetime.getHours() + 6);

  const submitLogin = async (e) => {
    e.preventDefault();
    if (postData.username && postData.email && postData.password) {
      setSubmit({ loading: true, data: [], message: "" })
      var encrypted_password = JSON.parse(
        CryptoJSAesJson.encrypt(
          postData.password,
          TYPE_ENV.privatekey
        )
      );
      var mailUser = postData.username + "" + postData.email;
      mailUser = mailUser.replaceAll(/\s/g, "");
      const hApp = {
        "App-Env":TYPE_ENV.appenv,
        "App-Id":GenerateAppID(36),
      }
      const headers = hApp;
      headers['Content-Type'] = 'application/json';

      const payload = {
        username: mailUser,
        password: encrypted_password,
        typeCK: postData.typeCK
      }

      const results = await LoginEstes({headers, payload});
      setSubmit(results);
      console.log(results);
      if (Object.values(results.data).length > 0 && results.message) {
        const authStorage = {
          headers : hApp,
          identity: results.data.identity,
          secretkey: results.data.secretkey,
          expiredSession : datetime.getTime(),
          typeCK: payload.typeCK
        }
        console.log(authStorage);
        authStoreData(authStorage);
        openModal({ message: <MessageSuccess message={results.message} /> });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        openModal({ message: <MessageError title={"Error"} message={results.message} /> });
      }

    } else {
      openModal({ message: <MessageError title={"Error"} message={"All required fields must be filled out"} /> });
    }
  }

  return (
    <div id="login-page">
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        autoComplete="off"
        onSubmit={(e) => submitLogin(e)}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">University Management System</h1>
          <div className="text-gray-400 fs-4">
            Please login using the same username and password as your <span className="text-primary fw-bolder ms-2">SGU Google account</span>
          </div>
        </div>
        {(baseUri.includes("localhost") || baseUri.includes("staging")) && (
          <ConsumerKey postData={postData} setPostData={setPostData} />
        )}
        <InputUsername postData={postData} setPostData={setPostData} />
        <InputPassword postData={postData} setPostData={setPostData} />
        <ButtonLong className={"btn-lg btn-primary mt-8"} type="submit" disabled={submit.loading} >
          {submit.loading ? "Processing..." : "Continue"}
        </ButtonLong>
      </form>
    </div>
  )
}
