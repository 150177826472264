import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../_layouts/components/cards/CardUI'
import { ButtonPrimary } from '../../../_layouts/components/buttons/ButtonUI'
import { KTIcon } from '../../../_metronic/helpers'
import FormUpdate from './components/FormUpdate'
import { openModal } from '../../../_layouts/components/modals/Modals'
import { GET_GROUPS } from '../apis/module/BackendRequest'
import { LoadingSkeleton, NoRecordFound } from '../../../_layouts/components/loadbars/LoadingBars'
import { AlertRetry } from '../../../_layouts/components/alerts/AlertsUI'
import TableData from './components/TableData'
import ListWidgets from './components/ListWidgets'

export function MAccessGroups() {
    const [groups, setGroups] = useState({ loading: false, data: [], message: "" });
    const [selectedGroup, setSelectedGroup] = useState("");
    const ReloadData = async () => {
        setSelectedGroup("");
        setGroups({ loading: true, message: "", data: [] });
        const result = await GET_GROUPS();
        if (result.data && Object.values(result.data).length > 0) {
            const data_group = buildGroupHierarchy(result.data);
            setGroups({ loading: false, message: "", data: data_group });
        } else {
            setGroups(result);
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        <div id='management-access' className='row gx-5 gx-xl-10'>
            <div className={`col-lg-${selectedGroup ? "6":"12"}`}>
                <SGUCard>
                    <SGUCard.Header>
                        <span className="card-label fw-bold text-gray-900">List of groups</span>
                    </SGUCard.Header>
                    <SGUCard.Toolbar>
                        <ButtonPrimary className="btn-sm" onClick={() => openModal({ header: "Form Group", message: <FormUpdate ReloadData={ReloadData} /> })}>
                            <KTIcon iconName='plus' className='fs-3' />
                            Create New
                        </ButtonPrimary>
                    </SGUCard.Toolbar>
                    <SGUCard.Body>
                        {groups.loading ? <LoadingSkeleton /> :
                            groups.message ? <AlertRetry messages={groups.message} Reload={ReloadData} /> :
                                Object.values(groups.data).length > 0 ? <TableData ReloadData={ReloadData} groups={groups} setSelectedGroup={setSelectedGroup} /> : <NoRecordFound />}
                    </SGUCard.Body>
                </SGUCard>
            </div>
            <div className={`col-lg-6 ${!selectedGroup ? "d-none":""}`}>
                <ListWidgets selected={selectedGroup} />
            </div>
        </div>
    )
}

const buildGroupHierarchy = (groupItems) => {
    const groupMap = {};

    groupItems.forEach(item => {
        if (!item.children) {
            item.children = [];
        }
        groupMap[item.group_id] = item;
    });

    const hierarchy = [];
    groupItems.forEach(item => {
        if (item.parent_id === 0) {
            hierarchy.push(item);
        } else if (groupMap[item.parent_id]) {
            groupMap[item.parent_id].children.push(item);
        }
    });

    return hierarchy;
}

export const ToogleCard = () => {
    const managementAccess = document.getElementById('management-access');
    if (managementAccess) {
        const colLg = managementAccess.querySelector('.list-group');
        const colLg4 = managementAccess.querySelector('.select-widget');

        if (colLg && colLg4) {
            colLg.classList.toggle('col-lg-6');
            colLg.classList.toggle('col-lg-12');
            colLg4.classList.toggle('d-none');
        }
    }
}