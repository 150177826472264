import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { GET_MKT_COUPON } from "../../apis/node-target-data/MarketingAPI";
import FilterCoupon from "./components/FilterCoupon";
import TableDataCoupon from "./components/TableDataCoupon";
import { ButtonCetakCoupon, ButtonCoupon } from "../components/EnrollmentComp";
import { CetakCoupon } from "./components/CetakCoupon";
import useToaster from "../components/use-toaster";
import { ModalNewCoupon } from "./components/ModalNewCoupon";
import { openModal } from "../../../../_layouts/components/modals/Modals";

export function Coupons() {
  const context = useOutletContext();
  const widget_81 = context.widgets.find(
    (item) => item.widget_id === 81 && item.is_enabled === 1
  );

  const widget_create_coupon = context.widgets.find(
    (item) => item.widget_id === 83 && item.is_enabled === 1
  );

  const { ToasterComponent, triggerNotification } = useToaster();
  const [previousFilter, setPreviousFilter] = useState("");

  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const [isCetak, setIsCetak] = useState(false);
  const [cookedData, setCookedData] = useState([]);

  const ReloadData = async (param) => {
    console.log("param reload", param);
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_MKT_COUPON(param);
    console.log(result);

    setResults(result);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (param) => {
    setPreviousFilter(param);
    ReloadData(param);
  };

  const Reload = () => {
    ReloadData(previousFilter);
  };

  useEffect(() => {
    const param = {
      status: "",
    };
    setPreviousFilter(param);
    ReloadData(param);
  }, []);

  // console.log("widget_create_coupon", widget_create_coupon);

  return widget_81 ? (
    <div className="section-browse row">
      {ToasterComponent}
      <div className="col-lg-3 mb-xl-10">
        <FilterCoupon
          SubmitFilter={SubmitFilter}
          results={results}
          setResults={setResults}
          title="Coupon"
        />
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">Coupon Data</span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of the coupons data by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Toolbar>
            <>
              {widget_create_coupon && (
                <ButtonCoupon
                  type="button"
                  iconName="plus"
                  className="btn-sm ms-3"
                  onClick={() =>
                    openModal({
                      message: <ModalNewCoupon handleReload={Reload} />,
                      header: <h4 className="modal-title">Add new coupon</h4>,
                    })
                  }
                >
                  Coupon
                </ButtonCoupon>
              )}
              {results.data.length > 0 && (
                <ButtonCetakCoupon
                  className="btn-sm ms-3"
                  onClick={() => CetakCoupon(cookedData, setIsCetak)}
                  disabled={isCetak}
                >
                  {isCetak ? "Downloading..." : "Download"}
                </ButtonCetakCoupon>
              )}
            </>
          </SGUCard.Toolbar>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <div className="w-100">
                <TableDataCoupon
                  results={results}
                  ReloadData={Reload}
                  setCookedData={setCookedData}
                  widget_create_coupon={widget_create_coupon}
                  triggerNotification={triggerNotification}
                />
              </div>
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
