import ExcelJs from "exceljs";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { YearIntake } from "../../Helper";
import { BlobImg } from "../../../../../_layouts/components/images/Logos";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import moment from "moment";

const DownloadAcceptance = async (param, setStatus) => {
  let paramExcel = { ...param };
  delete paramExcel.limit;
  delete paramExcel.offset;
  try {
    setStatus(true);
    const result = await GET_ENROLLMENT_PROCESS(param);

    console.log("result", result);

    if (result.data && Object.values(result.data).length > 0) {
      const filterAccepted = result.data.filter((row) => row.term_id !== null);

      const sort_desc = filterAccepted.sort((a, b) =>
        moment(a.registrationdate).toDate() <
        moment(b.registrationdate).toDate()
          ? 1
          : -1
      );

      console.log("filterAccepted", filterAccepted);
      console.log("ready to excel", sort_desc);

      EnrollmentExcel(
        sort_desc,
        `Intake ${YearIntake(param.yearintake)}`,
        paramExcel
      );
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }

    setStatus(false);
  } catch (error) {
    console.log(error);
    openModal({
      message: (
        <MessageError message={"Failed to download. Please try again later."} />
      ),
    });
    setStatus(false);
  }
};

const EnrollmentExcel = (data, intake_label, paramExcel) => {
  const wb = new ExcelJs.Workbook();
  wb.creator = "UMS-SGU";
  wb.lastModifiedBy = "UMS-SGU-System";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();

  //body here
  SheetEnrollment(wb, data, intake_label, paramExcel);
  //end body here

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "text/csv;charset=utf-8",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  wb.csv.writeBuffer().then((buffer) => {
    writeFile("Report Admission " + intake_label + ".csv", buffer);
  });
};

const SheetEnrollment = (wb, data, intake_label) => {
  const ws = wb.addWorksheet(intake_label);

  let rowNumber = 0;

  rowNumber = rowNumber + 1;

  const headers = [
    "studentID",
    "givenName",
    "sn",
    "cn",
    "password",
    "userPrincipalName",
    "mail",
    "department",
  ];
  const rowHeader = ws.getRow(rowNumber);
  headers.forEach((element, index) => {
    RowHeader(rowHeader, index + 1, element);
  });

  rowNumber = rowNumber + 1;

  data.map((v) => {
    const row = ws.getRow(rowNumber);
    row.getCell(1).value = v.candidate_id || "";
    row.getCell(2).value = v.regstudent && (v.regstudent.firstname || "");
    row.getCell(3).value =
      v.regstudent &&
      (`${v.regstudent?.middlename} ${v.regstudent?.lastname}` || "");
    row.getCell(4).value = v.regstudent && (v.regstudent.fullname || "");

    row.getCell(5).value = v.regstudent.birthdate
      ? `Sgu${v.candidate_id}@${v.regstudent.birthdate.split("-")[2]}${
          v.regstudent.birthdate.split("-")[1]
        }${v.regstudent.birthdate.split("-")[0]}`
      : "";

    row.getCell(6).value =
      v.regstudent.firstname &&
      (`${v.regstudent.firstname
        .toLowerCase()
        .split(" ")
        .join("")}.${v.regstudent.lastname.toLowerCase().replace(/\s/g, "")}` ||
        "");
    row.getCell(7).value =
      v.regstudent.firstname &&
      (`${v.regstudent.firstname
        .toLowerCase()
        .split(" ")
        .join("")}.${v.regstudent.lastname
        .toLowerCase()
        .replace(/\s/g, "")}@student.sgu.ac.id` ||
        "");
    row.getCell(8).value =
      v.target_department && (v.target_department.name || "");

    rowNumber++;
  });

  ws.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      let cellLength = 0;

      if (cell.value) {
        if (typeof cell.value === "string") {
          cellLength = cell.value.length;
        } else if (typeof cell.value === "number") {
          cellLength = cell.value.toString().length;
        } else if (cell.value instanceof Date) {
          cellLength = cell.text.length;
        } else {
          cellLength = cell.value.toString().length;
        }
      }

      if (cellLength > maxLength) {
        maxLength = cellLength;
      }
    });

    // Tambahkan batasan minimum dan maksimum
    const minWidth = 8; // Lebar minimum kolom
    const maxWidth = 50; // Lebar maksimum kolom
    const padding = 2; // Padding untuk readability

    // Terapkan batasan
    column.width = Math.max(minWidth, Math.min(maxLength + padding, maxWidth));
  });
};

const RowHeader = (rowHeader, i, value) => {
  rowHeader.getCell(i).value = value;
};

export { DownloadAcceptance };
