import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { ButtonIcon } from '../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../_metronic/helpers';

export default function TableData({ groups, setSelectedGroup, ReloadData }) {
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const [expandedRows, setExpandedRows] = useState({});

    const headers = [
        { name: "No", field: "group_id", sortable: false },
        { name: "Name", field: "name", sortable: true },
        { name: "Description", field: "description", sortable: true },
        { name: "Action", field: "group_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        const filterByName = (data, searchTerm) => {
            return data.filter(item => {
                const nameMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

                const childrenMatch = item.children && item.children.length > 0
                    ? filterByName(item.children, searchTerm).length > 0
                    : false;

                return nameMatch || childrenMatch;
            }).map(item => ({
                ...item,
                children: item.children ? filterByName(item.children, searchTerm) : []
            }));
        };

        let computedData = [];
        if (Object.values(groups.data).length > 0) {
            computedData = groups.data.map(item => ({
                ...item,
                description: item.description || ""
            }));
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key]
                        .toString()
                        .toLowerCase()
                        .includes(search)
                );
            });
        }

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        setTotalItems(computedData.length);

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [groups.data, search, sorting, currentPage])

    const toggleRowExpansion = (groupId) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [groupId]: !prevState[groupId]
        }));
    };

    return (
        <div className='table-data'>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex">
                    <div className="mx-2">
                        <ButtonItemRefresh
                            totalItems={totalItems}
                            onClick={() => ReloadData()}
                        />
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                        {ResultData.map((item, index) => (
                            <React.Fragment key={item.group_id}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        {(item.children && Object.values(item.children).length > 0) && (
                                            <ButtonIcon
                                                className={`btn-sm ${expandedRows[item.group_id] ? 'btn-success' : 'btn-light-success'}`}
                                                type="button"
                                                title={expandedRows[item.group_id] ? "Click to collapse" : "Click to expand Role"}
                                                onClick={() => toggleRowExpansion(item.group_id)}
                                            >
                                                {Object.values(item.children).length}
                                            </ButtonIcon>
                                        )}
                                        <span className="ms-2 fw-bold">
                                            {item.name}
                                        </span>
                                    </td>
                                    <td>{item.description}</td>
                                    <td>
                                        {(item.children && Object.values(item.children).length === 0) && (
                                            <ButtonAccess data={item} setSelectedGroup={setSelectedGroup} />
                                        )}
                                    </td>
                                </tr>
                                {expandedRows[item.group_id] && item.children && item.children.map((child, childIndex) => (
                                    <tr key={`${item.group_id}-${child.group_id}`} className="bg-light">
                                        <td className='text-end'>{(childIndex + 1)}</td>
                                        <td className='fw-bold'>{child.name}</td>
                                        <td>{child.description}</td>
                                        <td>
                                            <ButtonAccess data={child} parent={{ group_id: item.group_id, name: item.name }} setSelectedGroup={setSelectedGroup} />
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                {totalItems > 0 && (
                    <div className="footer">
                        <PaginationComponent
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const ButtonAccess = ({ data, parent = null, setSelectedGroup }) => {
    const OpenAccessWidget = () => {
        if (parent) {
            let item = data;
            item.parent = parent;
            setSelectedGroup(item);
        } else {
            setSelectedGroup(data);
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    return (
        <ButtonIcon
            className="btn-sm"
            type="button"
            title="Give an access"
            onClick={OpenAccessWidget}
        >
            <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
        </ButtonIcon>
    )
}
