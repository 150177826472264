import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

const RowStudent = ({ data }) => {
  return (
    <div>
      <span className="fw-bold d-block text-capitalize">{data.student_name}</span>
      <span className="text-muted fs-7">{StudentIDFormat(data.student_id)}</span>
      <span className="mx-2">·</span>
      <span className="text-muted fs-7">Semester {data.student_semester}</span>
    </div>
  );
};
const RowCourse = ({ data }) => {
  return (
    <div>
      <span className="fw-bold d-block">{data.course_name}</span>
      <span className="text-muted fs-7">{data.course_code}</span>
      <span className="mx-2">·</span>
      <span className="text-muted fs-7">Credit {data.course_credit} SKS</span>
    </div>
  );
};
const RowLecturer = ({ data }) => {
  const CardLect = ({ data, ismulti }) => {
    return (
      <div className={(ismulti && "border rounded py-1 px-2 me-1")+" fs-7"}>
        <span className="fw-bold d-block">
          {data.emp && (data.emp.fullname || "")}
        </span>
        <span className="text-muted">{data.section_id}</span>
      </div>
    );
  };

  return (
    data.length > 0 &&
    (data.length > 1 ? (
      <div className="d-flex">
        {data.map((v, index) => (
          <CardLect data={v} key={index} ismulti={true} />
        ))}
      </div>
    ) : (
      <div className="d-flex">
        {data.map((v, index) => (
          <CardLect data={v} key={index} />
        ))}
      </div>
    ))
  );
};
const RowCumulative = ({ data }) => {
  return (
    <div>
      {data.atd && <span className="fw-bold d-block fs-7">Absent {data.atd}</span>}
      <span className="text-muted d-block fs-8">Total Absent {data.t_absent}</span>
      <span className="text-muted fs-8">Session {data.t_session}</span>
    </div>
  );
};

export { RowStudent, RowCourse, RowLecturer, RowCumulative };
