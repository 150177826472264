import { NavLink } from "react-router-dom";
import Select from "react-select";
import { SGUCardFlush } from "../../../../../_layouts/components/cards/CardUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { BoxDashed } from "../../../students/find-student/components/CardHeaderStd";
import { useEffect, useMemo, useState } from "react";

const HeaderThesis = ({ data }) => {
  return (
    <SGUCardFlush>
      <SGUCardFlush.Body className={"pb-0 px-0"}>
        <div className="pt-9 px-8 d-flex justify-content-between align-items-center">
          <div className="profile">
            <div className="d-flex align-items-center mb-2">
              <span className="text-gray-900 text-hover-primary fs-1 fw-bold me-1">
                {data.studentdata && (data.studentdata.fullname || "")}
              </span>
              {data.status === "APPROVED" ? (
                <KTIcon iconName="verify" className="fs-1 text-primary" />
              ) : (
                <KTIcon iconName="shield-cross" className="fs-1 text-danger" />
              )}
            </div>

            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
              <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                {data.studentid || ""}
              </span>
              <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                <KTIcon iconName="sms" className="fs-4 me-1" />
                {data.studentdata && (data.studentdata.sgumail || "")}
              </span>
              <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                <KTIcon iconName="information" className="fs-4 me-1" />
                {data.status || ""}
              </span>
            </div>
          </div>
          <div className="dept d-flex">
            {data.studentdata && (
              <>
                <BoxDashed
                  title={"Department"}
                  value={data.studentdata.clu && (data.studentdata.clu.name || "")}
                  selector={"me-3"}
                />
                {data.studentdata.clu_spec && (
                  <BoxDashed
                    title={"Specialization"}
                    value={data.studentdata.clu_spec.name || ""}
                    selector={"me-3"}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="separator"></div>
        <div className="px-8">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <li className="nav-item">
              <NavLink
                to={`schedules`}
                className={({ isActive }) =>
                  (isActive && "active text-active-primary") +
                  " nav-link py-5 me-6"
                }
              >
                <span className="nav-label">Information & Schedule</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`examiners`}
                className={({ isActive }) =>
                  (isActive && "active text-active-primary") +
                  " nav-link py-5 me-6"
                }
              >
                Examiners
              </NavLink>
            </li>
          </ul>
        </div>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
};

const SectionCompSelect = ({ data, setData, components }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(data && (data || []));

  useMemo(() => {
    if (Object.values(components).length > 0) {
      const option_result = components.map((v) => ({
        value: v.wg_com.id,
        label: `${v.name} ${v.weight * 100}%`,
      }));
      setOptions(option_result);
    }
  }, [components]);

  const handleChange = (selectedValue) => {
    if (selectedValue) {
      setData(selectedValue);
      setSelectedOption(selectedValue);
    } else {
      setData([]);
      setSelectedOption([]);
    }
  };


  return (
    <div className="mb-5">
      <Select
        name="component"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder="Choose One"
        isMulti
      />
    </div>
  );

}

export { HeaderThesis, SectionCompSelect };
