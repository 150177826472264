import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import { AvaBlank } from "../../../../../_layouts/components/images/Avatars";
import { KTIcon } from "../../../../../_metronic/helpers";

const RowStudent = ({ data }) => {
  return (
    <div>
      <span className="fw-bold d-block text-capitalize">{data.student_name}</span>
      <span className="text-muted fs-7">
        {StudentIDFormat(data.student_id)}
      </span>
      <span className="mx-2">·</span>
      <span className="text-muted fs-7">Semester {data.student_semester}</span>
    </div>
  );
};
const RowCourse = ({ data }) => {
  return (
    <>
      {data.student_status !== "ACTIVE" ? (
        <div className="notice d-flex align-items-center bg-light-danger rounded border-danger border border-dashed py-3">
          <KTIcon iconName="cross-circle" className="fs-2tx text-danger me-2" />
          <div className="">
            <span className="fw-bold">{data.course_name}</span>
            <div className="">
              <span className="text-muted fs-7">{data.course_code}</span>
              <span className="mx-2">·</span>
              <span className="text-muted fs-7">
                Credit {data.course_credit} SKS
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span className="fw-bold d-block">{data.course_name}</span>
          <span className="text-muted fs-7">{data.course_code}</span>
          <span className="mx-2">·</span>
          <span className="text-muted fs-7">
            Credit {data.course_credit} SKS
          </span>
        </div>
      )}
    </>
  );
};

const RowLecturer = ({ data }) => {
  const lecturers = data.section_lec;
  const total = Object.values(lecturers).length;
  const RenderLecturer = ({ name, value }) => {
    return (
      <LinkBtn
        to={"/course-managements/section/" + value + "/detail"}
        className="text-dark text-hover-primary"
        target="_blank"
        title={"View Section " + value}
      >
        <span className="fw-bold d-block fs-7">{name}</span>
        <span className="text-muted fs-8">{value}</span>
      </LinkBtn>
    );
  };
  if (total > 0) {
    if (total === 1) {
      return (
        <div>
          {lecturers.map((v, index) => (
            <RenderLecturer
              key={index}
              name={v.emp && (v.emp.fullname || "")}
              value={v.section_id}
            />
          ))}
        </div>
      );
    } else {
      return (
        <ol className="m-0 p-0">
          {lecturers.map((v, index) => (
            <li key={index} className="fs-7 m-0 p-0">
              <RenderLecturer
                name={v.emp && (v.emp.fullname || "")}
                value={v.section_id}
              />
            </li>
          ))}
        </ol>
      );
    }
  }
};

const RowCumulative = ({ data }) => {
  return (
    <div>
      <div className="mb-1">
        <div
          className={
            "rounded-pill d-flex align-items-center position-relative h-25px w-100 overflow-hidden bg-light-" +
            ColorStatus(data.course_status)
          }
        >
          <div className="d-flex align-items-center position-relative z-index-2">
            <div className="symbol-group symbol-hover me-3">
              <i
                className={
                  "bi " +
                  (data.course_status === "INCOMPLETE"
                    ? "bi-x-circle-fill"
                    : "bi-check-circle-fill") +
                  (" text-" + ColorStatus(data.course_status))
                }
              ></i>
            </div>
            <span className={"fw-bold text-" + ColorStatus(data.course_status)}>
              {data.course_status}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="border border-gray-300 border-dashed rounded  p-1">
          <div className="text-center fs-7">{data.atd}</div>
          <div className="fw-bold fs-8 text-dark-50">Absent(%)</div>
        </div>
        <div className="border border-gray-300 border-dashed rounded  p-1">
          <div className="text-center fs-7">{data.score}</div>
          <div className="fw-bold fs-8 text-dark-50">Score</div>
        </div>
        <div className="border border-gray-300 border-dashed rounded  p-1">
          <div className="text-center fs-7">{data.grade_fin}</div>
          <div className="fw-bold fs-8 text-dark-50">Grade</div>
        </div>
      </div>
    </div>
  );
};

const ColorStatus = (color) => {
  switch (color) {
    case "INCOMPLETE":
      return "warning";
    case "PASS":
      return "success";
    default:
      return "danger";
  }
};

const StudentInfo = ({ data }) => {
  return (
    <SGUCard>
      <SGUCard.Body className={"pt-5 px-0"}>
        <div className="p-8 pt-2">
          <div className="d-flex flex-column text-center mb-9 px-9">
            <div className="symbol symbol-80px symbol-lg-150px mb-4">
              <AvaBlank className="bg-light-primary rounded" />
            </div>
            <div className="text-center">
              <span className="text-gray-800 fw-bold fs-4 text-capitalize">
                {data.studentdata && (data.studentdata.fullname || "")}
              </span>
              <div>
                <span className="text-muted fw-semibold">{data.studentid}</span>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <CardInfo name={"Absent"} value={data.atd + "%"} />
            <CardInfo name={"Semester"} value={data.semester || ""} />
            <CardInfo name={"Status"} value={data.status} />
          </div>

          <div className="separator separator-dashed my-4"></div>
          <div className="mb-0">
            <h3>Department:</h3>
            <p className="mb-0">
              {(data.clu && data.clu.name) || ""}
              {data.clu_spec && (
                <span className="mx-1">/ {data.clu_spec.name}</span>
              )}
            </p>
          </div>

          {data.section_lec && data.section_lec.length > 0 && (
            <div className="mt-5">
              <h3>Lecturer:</h3>
              <ol>
                {data.section_lec.map((v, index) => (
                  <li className="mb-3" key={index}>
                    <span className="d-block fw-bold fs-5">
                      {v.emp && (v.emp.fullname || "")}
                    </span>
                    <span className="text-muted">{v.section_id}</span>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
};

const CardInfo = ({ name, value }) => {
  return (
    <div className="col-md-4 text-center">
      <div className="text-gray-800 fw-bold fs-3">
        <span className="m-0 counted">{value}</span>
      </div>
      <span className="text-gray-500 fs-8 d-block fw-bold">{name}</span>
    </div>
  );
};

export { RowStudent, RowCourse, RowCumulative, RowLecturer, StudentInfo };
