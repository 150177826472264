import React, { useEffect, useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { LoadingBars, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { RowScore, RowStudent, RowThesisInfo } from "./ThesisUI";
import moment from "moment";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { ButtonDownload } from "../../../../_layouts/components/buttons/ButtonUI";
import { GET_ACAD_TERM } from "../../apis/node-target-data/AcademicApi";
import { AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { DownloadThesisScore } from "./DownloadThesisScore";

export default function TableData({ results, ReloadData, postParam }) {
  const [termSelected, setTermSelected] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadTerm = async () => {
    const param = { atpid: postParam.term_id };
    const result = await GET_ACAD_TERM(param);
    setTermSelected(result);
  };

  useEffect(() => {
    ReloadTerm();
  }, [postParam]);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "ths_id", sortable: false },
    { name: "Student", field: "student_name", sortable: true },
    { name: "Thesis Info", field: "final_title", sortable: true },
    { name: "Score", field: "score_status", sortable: true },
    { name: "Detail", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(results.data).length > 0) {
      computedData = Object.values(results.data).map((item) => {
        let obj = {};
        obj.student_name = item.studentdata
          ? item.studentdata?.fullname || ""
          : "";
        obj.studentid = item.studentid || "";
        obj.clu = item.studentdata
          ? (item.studentdata.clu && item.studentdata.clu.name) || ""
          : "";
        obj.clu_code = item.studentdata
          ? (item.studentdata.clu && item.studentdata.clu.code) || ""
          : "";
        obj.clu_spec = item.studentdata
          ? (item.studentdata.clu_spec && item.studentdata.clu_spec.name) || ""
          : "";
        obj.clu_spec_code = item.studentdata
          ? (item.studentdata.clu_spec && item.studentdata.clu_spec.code) || ""
          : "";
        obj.final_title = item.final_title || "";
        obj.atp_id_term = item.atp_id_term || "";
        obj.ths_id = item.id || "";
        let defense_time = "";
        if (item.defensedate && item.defensetime) {
          const joindate = item.defensedate + " " + item.defensetime;
          const convdate = moment(joindate).format("DD MMM YYYY, hh:mm A");
          defense_time = convdate;
        }
        obj.defense_time = defense_time;
        obj.defensedate = item.defensedate || "";
        obj.defensetime = item.defensetime || "";
        obj.advisor = item?.advisor?.fullname || "";
        obj.advisor_id = item.advisor_id || "";
        obj.coadvisor = item?.coadvisor?.fullname || "";
        obj.coadvisor_id = item.coadvisor_id || "";
        obj.score = item?.student_course?.score || "";
        obj.score_status = item?.student_course?.course_status || "";
        obj.score_grade = item?.student_course?.grade_fin || "";
        obj.section_id = item?.student_course?.section_id || "";
        return obj;
      });

      computedData.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);

  const [isDownload, setDownload] = useState(false);
  const HandlerDownload = () =>{
    const param = RemoveEmptyValue(postParam);
    const filename = encodeURIComponent(termSelected.data.name);
    DownloadThesisScore(param, filename, setDownload);
  }

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
          {totalItems > 0 && (
            termSelected.loading ? <LoadingBars /> : 
            termSelected.message ? <AlertRetry messages={termSelected.message} Reload={ReloadTerm} /> : 
            Object.values(termSelected.data).length > 0 && (
                <ButtonDownload className="btn-sm ms-3" type="button" disabled={isDownload} onClick={()=>HandlerDownload()}>
                    {isDownload ? "Processing..." :"Export to excel"}
                </ButtonDownload>
            )
          )}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-middle">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={5}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={5}>
                  <AlertNotif messages={results.message} />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <RowStudent data={v} />
                  </td>
                  <td>{v.final_title && <RowThesisInfo data={v} />}</td>
                  <td>
                    <RowScore data={v} />
                  </td>
                  <td>
                    {(v.ths_id) && (
                      <LinkBtn
                        to={
                          "detail/" +
                          btoa(
                            JSON.stringify({
                              term_id: v.atp_id_term,
                              ths_main_id: v.ths_id,
                            })
                          )
                        }
                        className="btn btn-icon btn-sm btn-light"
                        title="View Score"
                      >
                        <KTIcon iconName="arrow-right" className="fs-5" />
                      </LinkBtn>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {totalItems > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
