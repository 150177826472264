import { useState } from "react";
import { ButtonIcon } from "../../../../../../_layouts/components/buttons/ButtonUI";
import {
  ConfirmRemove,
  MessageError,
  openModal,
} from "../../../../../../_layouts/components/modals/Modals";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { RSAencryptData } from "../../../../../../_layouts/_helpers/RSAEnc";
import {
  DOWNLOAD_REQUEST_DOC,
} from "../../../../apis/module/JupiterRequest";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../../_layouts/components/popovers/PopOvers";

const RenderFileDoc = ({ data, file_id, filename, HandlerDeleteFile }) => {
  const [isdownload, setIsDownload] = useState();

  return (
    <div className="d-flex align-items-center border rounded p-2 bg-light">
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={renderTooltip({ title: "Click to download" })}
      >
        <div className="d-flex align-items-center me-10 cursor-pointer" onClick={() => HandlerDownload(setIsDownload, { file_id: file_id, user: data.createdby }, filename)}>
          <i className="bi bi-filetype-pdf fs-2x text-danger"></i>
          <span className="mx-2 fw-bold">
            {isdownload ? "Downloading..." : filename}
          </span>
        </div>
      </OverlayTrigger>

      <ButtonIcon
        type="button"
        className="btn-sm"
        title="Remove File"
        onClick={() =>
          openModal({
            message: (
              <ConfirmRemove
                handler={() => HandlerDeleteFile(file_id)}
                message={"Are you sure want to remove?"}
              />
            ),
          })
        }
      >
        <KTIcon iconName="cross-circle" className="fs-2 text-danger" />
      </ButtonIcon>
    </div>
  );
};

const HandlerDownload = async (setIsDownload, param, filename) => {
  setIsDownload(true);
  const objData = JSON.stringify(param);
  const rsaEnc = RSAencryptData(objData);
  const formData = new FormData();
  formData.append("data", rsaEnc);

  try {
    const response = await DOWNLOAD_REQUEST_DOC(formData);

    if (response.status === 200 && response.data) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);
      setIsDownload(false);
    } else {
      setIsDownload(false);
      throw new Error("Download failed");
    }
  } catch (error) {
    setIsDownload(false);
    console.error("Download error:", error);
    openModal({
      message: <MessageError message={error.message || "Download failed"} />,
    });
  }
};

export { RenderFileDoc, HandlerDownload };
