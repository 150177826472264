import { KTIcon } from "../../../../../../../_metronic/helpers"

const CardActiveCalendar = ({data}) => {
    return (
        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-8">
            <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
                <KTIcon iconName="calendar" className="text-primary fs-3x" />
                <div className="ms-3">
                    <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                        {data?.atp_term?.name || ""}
                    </h1>
                    <span className="text-muted">
                        Active Calendar Academic
                    </span>
                </div>
            </div>
        </div>
    )
}

export {CardActiveCalendar}