import React, { useEffect, useState } from "react";
import Filter from "./components/Filter";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import TableData from "./components/TableData";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import useToaster from "../components/use-toaster";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import { GET_ENROLLMENT_PROCESS } from "../../apis/node-target-data/MarketingAPI";
import { DefaultYear, DefaultYearId } from "../components/EnrollmentComp";

export function Documents() {
  const { ToasterComponent, triggerNotification } = useToaster();
  const context = useOutletContext();
  const widget_63 = context.widgets.find(
    (item) => item.widget_id === 63 && item.is_enabled === 1
  );
  const update_doc = context.widgets.find(
    (item) => item.widget_id === 64 && item.is_enabled === 1
  );

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [previousFilter, setPreviousFilter] = useState();

  const [results, setResults] = useState({
    loading: true,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_ENROLLMENT_PROCESS(param);
    // console.log("result -> ", result)
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (filterparam) => {
    setLimit(filterparam.limit);
    sessionStorage.setItem("previousFilter", JSON.stringify(filterparam));
    const param = RemoveEmptyValue(filterparam);
    if (param.candidatename) {
      param["candidatename"] = encodeURIComponent(param.candidatename);
    }
    if (param.status) {
      param["status"] = encodeURIComponent(param.status);
    }
    setPreviousFilter(param);
    if (param) {
      if (param.offset === undefined) setOffset(1);
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else if (type == 2) {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = JSON.parse(sessionStorage.getItem("previousFilter")) ?? {
      ...previousFilter,
    };
    param.offset = current_offset;
    param.limit = limit;
    SubmitFilter(RemoveEmptyValue(param));
  };

  useEffect(() => {
    // get data 1st ope
    sessionStorage.removeItem("lastVisit");
    SubmitFilter({
      yearintake: DefaultYearId(),
      offset: offset,
      limit: limit,
    });
  }, []);

  return widget_63 ? (
    <div className="section-browse row">
      {ToasterComponent}
      <div className="col-lg-3 mb-xl-10">
        <Filter
          SubmitFilter={SubmitFilter}
          results={results}
          setResults={setResults}
        />
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Documets Data
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of the documets data by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading &&
            sessionStorage.getItem("previousFilter") === null ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="w-100">
                  <TableData
                    results={results}
                    ReloadData={() => SubmitFilter(previousFilter)}
                    HandlerPage={HandlerPage}
                    triggerNotification={triggerNotification}
                    update_doc={update_doc}
                  />
                </div>
                <div className="w-100">
                  <div className="footer">
                    <ul className="pagination">
                      <li
                        className={
                          "page-item " +
                          (offset !== 1 ? "cursor-pointer" : "disabled")
                        }
                      >
                        <span
                          className="page-link"
                          onClick={() => HandlerPage(1)}
                        >
                          <span>‹ Prev</span>
                        </span>
                      </li>
                      <li className="page-item active">
                        <span className="page-link text-white">
                          Page {offset}
                        </span>
                      </li>
                      <li
                        className={
                          "page-item " +
                          (Object.values(results.data)?.length === limit
                            ? "cursor-pointer"
                            : "disabled")
                        }
                      >
                        <span
                          className="page-link"
                          onClick={() => HandlerPage(2)}
                        >
                          <span>Next ›</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
