import React from 'react'
import { SGUCardFlush } from '../../../../_layouts/components/cards/CardUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { ListWidget } from '../../menu-widgets/components/ListWidget';
import UserList from '../../group-users/components/users/UserList';

export default function ListWidgets({ selected }) {
    let title = selected.name;
    if (selected.parent) {
        title = `${selected.parent.name} - ${selected.name}`;
    }
    return (
        <>
            <SGUCardFlush>
                <SGUCardFlush.Body className={"px-0 pt-5 pb-0"}>
                    <div className="px-5 mb-5">
                        <h3 className='d-flex align-items-center'>
                            <KTIcon iconName='setting-2' className='fs-1 text-primary me-2' />
                            Selected Group: <span className="text-primary ms-2">{title}</span>
                        </h3>
                    </div>
                    <div className="w-100">
                        <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-0">
                            <li className="nav-item col-4 mx-0 px-0">
                                <a
                                    className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100 active"
                                    data-bs-toggle="tab"
                                    href="#card-widget"
                                >
                                    <span className="nav-text fw-bold fs-4 mb-3">
                                        Access Widget
                                    </span>
                                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                                </a>
                            </li>
                            <li className="nav-item col-4 mx-0 p-0">
                                <a
                                    className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100"
                                    data-bs-toggle="tab"
                                    href="#card-users"
                                >
                                    <span className="nav-text fw-bold fs-4 mb-3">Membership</span>
                                    <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                                </a>
                            </li>
                            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded"></span>
                        </ul>
                    </div>
                </SGUCardFlush.Body>
            </SGUCardFlush>
            <div className="tab-content">
                <div className="tab-pane blockui fade show active" id="card-widget">
                    <ListWidget activeGroup={selected} />
                </div>
                <div className="tab-pane blockui fade" id="card-users">
                    <UserList activeGroup={selected} />
                </div>
            </div>
        </>
    )
}
