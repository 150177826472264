import ExcelJs from "exceljs";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { YearIntake } from "../../Helper";
import { BlobImg } from "../../../../../_layouts/components/images/Logos";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import moment from "moment";

const CetakCoupon = async (data, setStatus) => {
  console.log(data);

  try {
    setStatus(true);

    if (data.length > 0) {
      CouponExcel(data, `Coupon`);
    }

    setStatus(false);
  } catch (error) {
    console.log(error);
    openModal({
      message: (
        <MessageError message={"Failed to download. Please try again later."} />
      ),
    });
    setStatus(false);
  }
};

const CouponExcel = (data, intake_label) => {
  console.log(intake_label, data);
  const wb = new ExcelJs.Workbook();
  wb.creator = "UMS-SGU";
  wb.lastModifiedBy = "UMS-SGU-System";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();

  //body here
  SheetEnrollment(wb, data, intake_label);
  //end body here

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  wb.xlsx.writeBuffer().then((buffer) => {
    writeFile("Report Admission " + intake_label, buffer);
  });
};

const SheetEnrollment = (wb, data, intake_label) => {
  // console.log("dlam ", data)
  const ws = wb.addWorksheet(intake_label);
  const HeaderXLS = BlobImg();
  const imageId1 = wb.addImage({
    fileName: "logo-1",
    extension: "png",
    base64: HeaderXLS.imglogo,
  });
  const imageId2 = wb.addImage({
    fileName: "logo-2",
    extension: "png",
    base64: HeaderXLS.imglogoName,
  });

  ws.addImage(imageId1, "A1:B2");
  ws.addImage(imageId2, "G2:I2");

  let rowNumber = 4;

  ws.getRow(rowNumber).getCell("A").value = "Admission " + intake_label;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  ws.getRow(rowNumber).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };
  ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
  ws.mergeCells("A" + rowNumber + ":I" + rowNumber);
  rowNumber = rowNumber + 2;

  const headers = [
    "No",
    "Coupon Type",
    "Coupon No",
    "Created Date",
    "Expired Date",
    "Discount Type",
    "Discount",
    "Status",
    "Used By",
    "Trans ID",
    "Enroll ID",
    "Created By",
  ];
  const rowHeader = ws.getRow(rowNumber);
  headers.forEach((element, index) => {
    RowHeader(rowHeader, index + 1, element);
  });

  rowNumber = rowNumber + 1;
  var num = 1;
  data.map((v) => {
    const discount_type = (dsn) => {
      if (dsn !== null) {
        if (dsn?.includes("percentage")) {
          return "Percentage";
        } else {
          return "Nominal";
        }
      }else{
        return ""
      }
    };
    const row = ws.getRow(rowNumber);
    row.getCell(1).value = num++;
    row.getCell(2).value = v.coupon_type || "";
    row.getCell(3).value = v.coupon_no || "";
    row.getCell(4).value = v.coupon_created || "";
    row.getCell(5).value = v.expiration_date || "";
    row.getCell(6).value = discount_type(v.discount_type);
    row.getCell(7).value = v.coupon_discount || "";
    row.getCell(8).value = v.status || "";
    if (v.status === "Used") {
      row.getCell(8).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }  // Kuning
      };
    } else if (v.status === "Available") {
      row.getCell(8).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '92D050' }  // Hijau
      };
    } else {
      row.getCell(8).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' }  // Merah
      };
    }
    row.getCell(9).value = v.fullname || "";
    row.getCell(10).value = v.trans_id || "";
    row.getCell(11).value = v.enroll_id || "";
    row.getCell(12).value = v.coupon_createdby || "";
    rowNumber++;
  });

  ws.columns.forEach((column, index) => {
    if (index === 0) {
      column.width = 5;
    } else if (index === 1) {
      column.width = 15;
    } else {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxColumnLength) {
          maxColumnLength = cellLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
    }
  });
};

const RowHeader = (rowHeader, i, value) => {
  rowHeader.getCell(i).value = value;
  rowHeader.getCell(i).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  rowHeader.getCell(i).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "191970" },
  };
  rowHeader.getCell(i).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  rowHeader.getCell(i).font = {
    bold: true,
    size: 11,
    color: { argb: "FFFFFF" },
  };
};

export { CetakCoupon };
