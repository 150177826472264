import Select from "react-select";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  OverlayTrigger,
  Popover,
  Toast,
  ToastContainer,
  Tooltip,
} from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";

const ButtonSelf = forwardRef(({ children, className, ...props }, ref) => {
  return (
    <button ref={ref} className={"btn fw-semibold " + className} {...props}>
      {children}
    </button>
  );
});

const BadgeItem = ({ icon, name, title, className }) => {
  let color = "";

  if (title === "Reg Date") {
    color = "";
  } else if (title === "Program Degree") {
    color = name === "Bachelor" ? "-primary" : name === "Master" ? "-info" : "";
  } else if (title === "Enrollment Type") {
    color = "-info";
  } else if (title === "Year Intake") {
    color = "-dark";
  } else if (title === "Status Coupon") {
    color =
      name === "Available"
        ? "-success"
        : name === "Expired"
        ? "-danger"
        : name === "Used"
        ? "-warning"
        : "";
  } else if (title === "Coupon Type") {
    color =
      name === "Reg Fee" ? "-info" : name === "Tuition Fee" ? "-primary" : "";
  }

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="bottom"
      overlay={renderTooltip({ title: title })}
    >
      <div
        className={`align-items-center mt-1 badge badge-light${color} ${className}`}
        style={{ flex: "0 0 auto" }}
      >
        <KTIcon iconName={icon} className={`text${color}`} />
        <span className="fs-8 ms-1">{name}</span>
      </div>
    </OverlayTrigger>
  );
};

const BadgeAcceptance = ({ icon, value, title, className }) => {
  let color = "";

  if (title === "Status") {
    color = value === 0 ? "-warning" : "-success";
  } else if (title === "Sent Date") {
    color = "-primary";
  } else if (title === "Sent By") {
    color = "-info";
  } else if (title === "Sent Acceptance") {
    color = "";
  }

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <div
        className={`align-items-center mt-1 badge badge-light${color} ${className}`}
        style={{ flex: "0 0 auto" }}
      >
        <KTIcon iconName={icon} className={`text${color}`} />
        <span className="fs-8 ms-1">{value}</span>
      </div>
    </OverlayTrigger>
  );
};

const CandidateName = ({
  data,
  setData,
  required = false,
  disabled = false,
  label = "Search",
  placeholder = "Type to search",
}) => {
  const [selected, setSelected] = useState(data || "");

  const HandlerChange = (value) => {
    setSelected(value);
    // setData(value.replace(/-/g, ""));
    setData(value);
  };

  const clearInput = () => {
    setSelected("");
    setData("");
  };

  return (
    <div className="form-group mb-5 position-relative">
      <label className={"fw-bold " + (required && "required")}>{label}</label>
      <input
        type="text"
        className="form-control form-control-sm"
        placeholder={placeholder}
        disabled={disabled}
        value={selected}
        onChange={(e) => HandlerChange(e.target.value)}
      />
      {selected && (
        <span
          className="position-absolute"
          style={{
            top: "70%",
            right: "10px",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => clearInput()}
        >
          <i className="ki-outline ki-cross fs-1"></i>
        </span>
      )}
    </div>
  );
};

const RegistrationStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: "DRAFT", label: "Draft" },
    { value: "ON PROCESS", label: "On Process" },
    { value: "FINISHED", label: "Complete" },
    { value: "CANCELLED", label: "Cancelled" },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Registration Status"}
      </label>
      <Select
        name="regis_status"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        isClearable
        options={list}
        placeholder="All"
      />
    </div>
  );
};

const Degree = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: 1, label: "Bachelor" },
    { value: 2, label: "Master" },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Degree"}
      </label>
      <Select
        name="degree"
        classNamePrefix="react-select"
        value={selected}
        placeholder="All"
        isClearable
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

const DocumentStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const list = [
    { value: "All", label: "All" },
    { value: "Complete", label: "Complete" },
    { value: "Incomplete", label: "Incomplete" },
  ];

  const HandlerChange = (value) => {
    console.log(value);
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Document Status"}
      </label>
      <Select
        name="doc_status"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
        placeholder="Select status"
      />
    </div>
  );
};

const BillingStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const list = [
    { value: "All", label: "All" },
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
  ];

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Billing Status"}
      </label>
      <Select
        name="billing_status"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
        placeholder="Select status"
      />
    </div>
  );
};

const LimitFetch = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    {
      value: 10,
      label: "10 Data",
    },
    {
      value: 20,
      label: "20 Data",
    },
    {
      value: 30,
      label: "30 Data",
    },
    {
      value: 50,
      label: "50 Data",
    },
    {
      value: 100,
      label: "100 Data",
    },
  ];
  const [selected, setSelected] = useState(
    list.find((v) => v.value === data) || ""
  );

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Limit Show Data"}
      </label>
      <Select
        name="limit"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

const DateRangeFilter = ({
  data,
  setData,
  required = false,
  disabled = false,
  label = "Date Range",
  placeholder = "Set Date Range",
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    console.log(dates);
    setData({
      start_dt: moment(start).format("YYYY-MM-DD"),
      end_dt: moment(end).format("YYYY-MM-DD"),
    });
    setStartDate(start);
    setEndDate(end);
  };

  const clearInput = () => {
    setStartDate("");
    setEndDate("");
    setData({
      start_dt: "",
      end_dt: "",
    });
  };

  return (
    <div className="form-group mb-5 d-flex flex-column position-relative">
      <label className={"fw-bold " + (required && "required")}>{label}</label>
      <DatePicker
        className="form-control form-control-sm pe-10"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        dateFormat={"dd MMM yyyy"}
        selectsRange
        placeholderText={placeholder}
        selectsDisabledDaysInRange
      />
      {startDate && (
        <span
          className="position-absolute"
          style={{
            top: "70%",
            right: "10px",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => clearInput()}
        >
          <i className="ki-outline ki-cross fs-1"></i>
        </span>
      )}
      {/* <p>
        {JSON.stringify(moment(startDate).format("DD-MM-YYYY"))}{" "}
        {JSON.stringify(moment(endDate).format("DD-MM-YYYY"))}
      </p> */}
    </div>
  );
};

const RegistrationFeeStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: "UNPAID", label: "Unpaid" },
    { value: "PAID", label: "Paid" },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Reg Fee Status"}
      </label>
      <Select
        name="degree"
        classNamePrefix="react-select"
        value={selected}
        placeholder="All"
        isClearable
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

const TuitionFeeStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: "UNPAID", label: "Unpaid" },
    { value: "PAID", label: "Paid" },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Tuition Fee Status"}
      </label>
      <Select
        name="degree"
        classNamePrefix="react-select"
        value={selected}
        placeholder="All"
        isClearable
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

const ButtonCoupon = (props) => {
  const { iconName, children, className, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      {/* <i className="ki-outline ki-discount fs-3"></i> */}
      <KTIcon iconName={iconName} className="fs-3" />
      {children}
    </ButtonSelf>
  );
};

const ButtonCetakCoupon = (props) => {
  const { children, className, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      <i className="ki-outline ki-exit-down fs-3"></i>
      {children}
    </ButtonSelf>
  );
};

const ButtonSetEdit = (props) => {
  const { title, children, className, ...others } = props;
  const class_name = `btn btn-${
    title.includes("Edit") ? "secondary" : "primary"
  } ${className}`;
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  );
};

const ButtonAccept = (props) => {
  const { title, children, className, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  );
};

const ButtonMini = (props) => {
  const { iconName, children, className, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      <KTIcon iconName={iconName} className="fs-3" />
      {children}
    </ButtonSelf>
  );
};

const CouponStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: "unused", label: "Available" },
    { value: "expired", label: "Expired" },
    { value: "used", label: "Used" },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Coupon Status"}
      </label>
      <Select
        name="regis_status"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        isClearable
        options={list}
        placeholder="All"
      />
    </div>
  );
};

const EnrollmentType = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const list = [
    { value: "mkt.enrollment.type.cooperation", label: "Cooperation" },
    { value: "mkt.enrollment.type.exchange", label: "Exchange" },
    {
      value: "mkt.enrollment.type.internationalschool",
      label: "International School",
    },
    { value: "mkt.enrollment.type.learingprogram	", label: "Learning Program" },
    { value: "mkt.enrollment.type.pmdk", label: "PMDK" },
    { value: "mkt.enrollment.type.reguler", label: "Reguler" },
    { value: "mkt.enrollment.type.scholarship", label: "Scholarship" },
    {
      value: "mkt.enrollment.type.transfer.in.external",
      label: "Transfer In External",
    },
  ];

  const [selected, setSelected] = useState(
    data === undefined ? list[0] : list.find((v) => v.value === data)
  );

  const HandlerChange = (value) => {
    if (value === null) {
      setSelected(null);
      setData("");
    } else {
      setSelected(value);
      setData(value.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Enrollment Type"}
      </label>
      <Select
        name="degree"
        classNamePrefix="react-select"
        value={selected}
        placeholder="All"
        isClearable
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
      />
    </div>
  );
};

const formatNumberWithRegex = (number) => {
  return number.toString().replace(/(\d+)(\d{3})(\d{3})$/, "$1-$2-$3");
};

const ButtonAcceptanceLetter = (props) => {
  const { children, className, ...others } = props;
  const class_name = `btn btn-outline btn-outline-info ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const LetterTemplate = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
  setPos,
  listTemplate,
}) => {
  // console.log("listTemplate", listTemplate);
  let listExtractTemplate = JSON.parse(atob(listTemplate.value));

  // console.log("listExtractTemplate", listExtractTemplate);

  const list = [
    { value: listExtractTemplate[0], label: "Template 1", pos: "template_1" },
    { value: listExtractTemplate[1], label: "Template 2", pos: "template_2" },
    { value: listExtractTemplate[2], label: "Template 3", pos: "template_3" },
  ];

  const activeTemplate = (tipe) => {
    if (!tipe) return;
    console.log("tipe", tipe);
    if (tipe.includes("1")) {
      return list[0];
    } else if (tipe.includes("2")) {
      return list[1];
    } else if (tipe.includes("3")) {
      return list[2];
    }
  };

  const [selected, setSelected] = useState(null);

  const HandlerChange = (value) => {
    console.log(value);
    if (value === null) {
      setSelected(null);
      setData("");
      setPos(-1);
    } else {
      setPos(value.pos);
      setSelected(value);
      setData(value.value);
    }
  };

  useEffect(() => {
    if (listTemplate) {
      const choosen = activeTemplate(listTemplate.tipe);
      setSelected(choosen);
      setData(choosen.value);
      setPos(choosen.pos);
    }
  }, [listTemplate]);

  const CustomOption = ({ innerProps, data, selectProps }) => (
    <div
      {...innerProps}
      className={`d-flex justify-content-between px-3 py-2 ${
        selectProps?.value?.value === data?.value
          ? "bg-primary"
          : "bg-hover-light-primary"
      }`}
    >
      <span
        className={`${selectProps?.value?.value === data?.value &&
          "text-white"}`}
      >
        {data ? data.label : ""}
      </span>
      {data.pos === listTemplate.tipe && (
        <span
          className={`${
            selectProps?.value?.value === data?.value
              ? "text-white"
              : "text-black"
          }`}
        >
          <p className="m-0">Default</p>
        </span>
      )}
    </div>
  );

  return (
    <div>
      <Select
        name="regis_status"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        isClearable
        options={list}
        placeholder="Choose template"
        components={{ Option: CustomOption }}
      />
    </div>
  );
};

function toBase64(str) {
  // Encode string to UTF-8
  const utf8Bytes = new TextEncoder().encode(str);

  // Convert bytes to binary string
  const binaryString = Array.from(utf8Bytes)
    .map((byte) => String.fromCharCode(byte))
    .join("");

  // Encode binary string to Base64
  return btoa(binaryString);
}

const Almanac = () => {
  const style = { "--bs-tooltip-max-width": "none" };
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={
        <Tooltip id="button-tooltip" style={style}>
          <div className="d-flex flex-column align-items-start">
            <h5 className="my-3">Available for enrollment only</h5>

            <p className="text-nowrap m-0">{"Today Date: {{date_now}}"}</p>
            <p className="text-nowrap m-0">
              {"Month Rome Format: {{bulan_romawi}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Year 2 Digit: {{year_2_digit}}"}
            </p>
            <p className="text-nowrap m-0">{"Full Name: {{name}}"}</p>
            <p className="text-nowrap m-0">{"First Name: {{firstname}}"}</p>
            <p className="text-nowrap m-0">{"Middle Name: {{middlename}}"}</p>
            <p className="text-nowrap m-0">{"Last Name: {{lastname}}"}</p>

            <div
              className="separator border-2 my-5"
              style={{ width: "100%" }}
            ></div>

            <p className="text-nowrap m-0">
              {"Candidate ID: {{candidate_id}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Registration Date: {{registrationdate}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Month Intake: {{month_intake_name}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Year Intake: {{yearintake_name}}"}
            </p>
            <p className="text-nowrap m-0">{"Major: {{major}}"}</p>
            <p className="text-nowrap m-0">{"Degree: {{degree_name}}"}</p>

            <div
              className="separator border-2 my-5"
              style={{ width: "100%" }}
            ></div>

            <p className="text-nowrap m-0">{"Coupon: {{coupon}}"}</p>
            <p className="text-nowrap m-0">{"Registration Fee: {{reg_fee}}"}</p>
            <p className="text-nowrap m-0">
              {"Registration Fee Status: {{reg_fee_status}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Tuition Fee Status: {{tuition_status}}"}
            </p>
            <p className="text-nowrap m-0">
              {"Registration Status: {{reg_status}}"}
            </p>
          </div>
        </Tooltip>
      }
    >
      <div>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          rootClose
          placement="top"
          overlay={renderTooltip({
            title: "Parameters", // Almanac
          })}
        >
          <div>
            <KTIcon iconName="book-open" className="fs-1 cursor-pointer" />
          </div>
        </OverlayTrigger>
      </div>
    </OverlayTrigger>
  );
};

const DefaultYear = () => {
  const currentMonthNumber = moment().month() + 1;
  const currentYear = moment().year();

  return currentMonthNumber > 9 ? currentYear + 1 + "" : currentYear + "";
};

const DefaultYearId = () => {
  let defYear = 2025;
  let defId = 27;
  let idNow = 0;

  const currentMonthNumber = moment().month() + 1;
  const currentYear = moment().year();

  idNow = defId + (currentYear - defYear);

  return currentMonthNumber > 9 ? idNow + 1 : idNow;
};

const ConvertBulanKeRomawi = (bulan) => {
  const romawi = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];
  return romawi[bulan - 1];
};

export {
  BadgeItem,
  BadgeAcceptance,
  CandidateName,
  RegistrationStatus,
  EnrollmentType,
  Degree,
  DocumentStatus,
  BillingStatus,
  LimitFetch,
  DateRangeFilter,
  ButtonCoupon,
  ButtonCetakCoupon,
  ButtonMini,
  ButtonSetEdit,
  ButtonAccept,
  CouponStatus,
  formatNumberWithRegex,
  RegistrationFeeStatus,
  TuitionFeeStatus,
  ButtonAcceptanceLetter,
  LetterTemplate,
  toBase64,
  Almanac,
  DefaultYear,
  DefaultYearId,
  ConvertBulanKeRomawi,
};
