import React, { useState } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { useOutletContext } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { EmployeeSelect, RoomSingle } from '../../../../components/forms/SelectBox';
import { ButtonPrimary, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { ConfirmLeave, MessageError, MessageSuccess, MessageSuccessConfirmLeave, openModal } from '../../../../../../_layouts/components/modals/Modals';
import { RemoveEmptyValue } from '../../../../../../_layouts/_helpers/Global';
import { RequiredMsg } from '../../../../../../_layouts/_core/ErrorMessage';
import { UPDATE_THS } from '../../../../apis/node-target-data/AcademicApi';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { useProvider } from '../../../../../../_layouts/UMSLayoutProvider';


export function FormSchedule() {
  const { account } = useProvider();
  const typeck = account.typeCK;
  const context_data = useOutletContext();
  const context = context_data.data;
  const widgets = context_data.widgets;
  let isComplete = context_data.isComplete;
  const title = "Thesis Information & Defense Schedule";
  const now = moment().toDate();
  const [paramPost, setParamPost] = useState({
    "defensedate": context && (context.defensedate || ""),
    "defensetime": context && (context.defensetime || ""),
    "duedate": context && (context.duedate || ""),
    "id": context && (context.id || 0),
    "studentid": context && (context.studentid || "")
  });

  const final_title = context && (context.final_title || "");
  const advisor_name = context && (context.advisor ? (context.advisor.fullname) : "");
  const coadvisor_name = context && (context.coadvisor ? (context.coadvisor.fullname) : "");
  const [room_id, setRoom_id] = useState(context && (context.room_id || ""));
  const [advisor_id, setAdvisor] = useState(context && (context.advisor ? ({ label: context.advisor.fullname, value: context.advisor_id }) : 0));
  const [coadvisor_id, setCoadvisor] = useState(context && (context.coadvisor ? ({ label: context.coadvisor.fullname, value: context.coadvisor_id }) : 0));

  const [results, setResults] = useState({ loading: false, data: [], message: "" });
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (final_title && paramPost.defensedate && paramPost.defensetime && paramPost.duedate && advisor_id.value && room_id) {
      setResults({ loading: true, data: [], message: "" });
      let paramThs = { ...paramPost };
      paramThs.room_id = room_id;
      paramThs.advisor_id = advisor_id.value ? (advisor_id.value || advisor_id) : advisor_id;
      paramThs.coadvisor_id = coadvisor_id.value ? (coadvisor_id.value || coadvisor_id) : coadvisor_id;
      const param = { thesis: [RemoveEmptyValue(paramThs)] };
      const result = await UPDATE_THS(param);
      if (result.data && Object.values(result.data).length > 0) {
        openModal({ message: <MessageSuccess message={"Successfully Saved"} /> })
        setResults({ loading: false, data: result.data, message: "Successfully Saved" });
      } else {
        setResults(result);
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          {title}
        </span>
      </SGUCard.Header>
      <SGUCard.Body>
        <form autoComplete='off' method="post" onSubmit={(e) => SubmitHandler(e)}>
          <div className="row mb-7">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Thesis Title</label>
            <div className="col-lg-6">
              <div className="form-control form-control-lg">{final_title}</div>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Defense Date</label>
            <div className="col-auto">
              <div className="position-relative">
                <DatePicker className="form-control form-control-lg" dateFormat={"yyyy-MM-dd"}
                  minDate={now} timeIntervals={10}
                  placeholderText='Enter date' selected={paramPost.defensedate && moment(paramPost.defensedate).toDate()} onChange={(date) => setParamPost({ ...paramPost, defensedate: moment(date).format("YYYY-MM-DD"), duedate: moment(date).add(14, 'days').format("YYYY-MM-DD") })}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                  <KTIcon iconName='calendar' className='fs-1 text-primary' />
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="position-relative">
                <DatePicker className="form-control form-control-lg"
                  showTimeSelect showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Time"
                  dateFormat="hh:mm a"
                  placeholderText='Enter time' selected={paramPost.defensetime && (moment(paramPost.defensedate + " " + paramPost.defensetime) || now).toDate()} onChange={(date) => setParamPost({ ...paramPost, defensetime: moment(date).format("HH:mm:ss") })}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                  <KTIcon iconName='time' className='fs-1 text-primary' />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-7">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Revision Due Date</label>
            <div className="col-auto">
              <div className="position-relative">
                <DatePicker className="form-control form-control-lg" dateFormat={"yyyy-MM-dd"}
                  minDate={moment(paramPost.defensedate).toDate()} timeIntervals={10}
                  placeholderText='Enter date' selected={paramPost.duedate && moment(paramPost.duedate).toDate()} onChange={(date) => setParamPost({ ...paramPost, duedate: moment(date).format("YYYY-MM-DD") })}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                  <KTIcon iconName='calendar' className='fs-1 text-primary' />
                </div>
              </div>
            </div>

          </div>

          <div className="row mb-5">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Room</label>
            <div className="col-lg-3">
              <RoomSingle data={room_id} setData={setRoom_id} showtitle={false} required placeholder="Find Room" />
            </div>
          </div>

          <div className="row mb-1">
            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Advisor</label>
            <div className="col-lg-3">
            <div className="form-control form-control-lg">{advisor_name}</div>
            </div>
          </div>

          <div className="row mb-5">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">Co-Advisor</label>
            <div className="col-lg-3">
            <div className="form-control form-control-lg">{coadvisor_name}</div>
            </div>
          </div>
  
          <StatusLais isComplete={isComplete} />
        
          {results.message && (
            <AlertNotif messages={results.message} color={Object.values(results.data).length > 0 ? "success" : "danger"} />
          )}

          {widgets.widget_77 && (
            <div className="text-end mt-8">
              <ButtonTersier className="btn-lg fw-bold" type="button" onClick={() => HandlerClose()}>
                Cancel
              </ButtonTersier>
              <ButtonPrimary className="btn-lg fw-bold ms-3" type="submit" disabled={results.loading}>
                {results.loading ? "Processing..." : "Save Changes"}
              </ButtonPrimary>
            </div>
          )}
        </form>
      </SGUCard.Body>
    </SGUCard>
  )
}

const HandlerClose = () => {
  openModal({ message: <ConfirmLeave message={"Are you sure want to leave?"} url={"/calendar/thesis-defense"} /> })
}

const StatusLais = ({ isComplete }) => {
  if (isComplete.lais === 1 || isComplete.ths_activity === 1) {
    return (
      <div className="row mb-5">
        <div className="col-auto">
          <div className="position-relative">
            <div className="alert alert-dismissible bg-light-primary border border-primary border-dashed ">
              <strong>LAIS status information :
                <span className="text-danger fw-bolder">
                  {isComplete.lais === 1 ? "Incomplete LAIS &" : " "}
                  {isComplete.ths_activity === 1 ? " Incomplete Thesis Activity" : " "}
                </span>
              </strong>.
              <p>Schedule cannot be created because student hasn't completed the number of required advisory meetings.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
