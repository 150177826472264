import { useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";
import { StudentIDFormat } from "../../../../_layouts/_helpers/Global";

const RowStudent = ({ data }) => {
  return (
    <div className="d-flex">
      <div className="symbol symbol-40px me-3">
        <div className="symbol-label fs-5 fw-semibold bg-light-primary text-primary">
          {data.clu_code}
        </div>
      </div>
      <div className="data">
        <span className="fw-bold d-block fs-7">{data.student_name}</span>
        <span className="text-muted fs-8">{StudentIDFormat(data.studentid)}</span>
      </div>
    </div>
  );
};

const RowThesisInfo = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const textToDisplay = isExpanded
    ? data.final_title
    : data.final_title.substring(0, 100);

  return (
    <div>
      <div className="fs-8">
        <span className="me-1 fw-bold text-primary d-none">Title:</span>
        <span>
        {data.final_title}  
        </span>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <div className="fs-8">
            <span className="fw-bold text-primary">Advisor:</span>
            <span className="ms-1 fw-bold">{data.advisor}</span>
          </div>
          {data.coadvisor && (
            <div className="fs-8">
              <span className="fw-bold text-primary">Co-Advisor:</span>
              <span className="ms-1 fw-bold">{data.coadvisor}</span>
            </div>
          )}
        </div>
      </div>
      {data.defense_time && (
        <div className="text-end w-auto">
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="left"
            overlay={renderTooltip({ title: "Defense Date" })}
          >
            <div className="fs-8 d-flex align-items-center">
              <KTIcon iconName="calendar" className="me-1" />
              <span className="text-muted">{data.defense_time}</span>
            </div>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
};

const RowScore = ({ data }) => {
  return (
    <div>
      <div className="mb-1">
        <div
          className={
            "rounded-pill d-flex align-items-center position-relative h-25px w-100 overflow-hidden bg-light-" +
            ColorStatus(data.score_status)
          }
        >
          <div className="d-flex align-items-center position-relative z-index-2">
            <div className="symbol-group symbol-hover me-3">
              <i
                className={
                  "bi " +
                  (data.score_status === "INCOMPLETE"
                    ? "bi-x-circle-fill"
                    : "bi-check-circle-fill") +
                  (" text-" + ColorStatus(data.score_status))
                }
              ></i>
            </div>
            <span
              className={"fw-bold me-3 text-" + ColorStatus(data.score_status)}
            >
              {data.score_status}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="border border-gray-300 border-dashed rounded py-2 px-3">
          <div className="text-center fs-7">{data.score || "-"}</div>
          <div className="fw-bold fs-8 text-dark-50">Score</div>
        </div>
        <div className="border border-gray-300 border-dashed rounded py-2 px-3">
          <div className="text-center fs-7">{data.score_grade || "-"}</div>
          <div className="fw-bold fs-8 text-dark-50">Grade</div>
        </div>
      </div>
    </div>
  );
};

const ColorStatus = (color) => {
  switch (color) {
    case "INCOMPLETE":
      return "warning";
    case "PASS":
      return "success";
    case "APPROVED":
      return "primary";
    default:
      return "danger";
  }
};


export { RowStudent, RowThesisInfo, RowScore, ColorStatus };
