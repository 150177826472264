import React from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { AvaBlank } from "../../../../../_layouts/components/images/Avatars";
import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

export default function StdInfo({ data }) {
  return (
    <SGUCard>
      <SGUCard.Body className={"p-0"}>
        <ButtonIcon
          type="button"
          className="btn-sm btn-clear"
          onClick={() => window.close()}
          title="Close window"
        >
          <i className="bi bi-x-square fs-1"></i>
        </ButtonIcon>
        <div className="p-8">
          <div className="d-flex flex-column text-center mb-9 px-9">
            <div className="symbol symbol-80px symbol-lg-150px mb-4">
              <AvaBlank className="bg-light-primary rounded" />
            </div>
            <div className="text-center">
              <span className="text-gray-800 fw-bold fs-4 text-capitalize">
                {data.student_name}
              </span>
              <div>
                <span className="text-muted fw-semibold">
                  {StudentIDFormat(data.student_id)}
                </span>
                <span className="mx-1">·</span>
                <span className="text-muted fw-semibold">
                  Semester {data.student_semester}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <CardInfo name={"Absent"} value={data.atd || "-"} />
            <CardInfo name={"Total Absent"} value={data.t_absent} />
            <CardInfo name={"Session"} value={data.t_session} />
          </div>

          <div className="separator separator-dashed my-4"></div>

          <h3>Lecturer:</h3>
          {data.section_lec.length > 0 && (
            <ol>
              {data.section_lec.map((v, index) => (
                <li className="mb-3" key={index}>
                  <span className="d-block fw-bold fs-5">
                    {v.emp && (v.emp.fullname || "")}
                  </span>
                  <span className="text-muted">{v.section_id}</span>
                </li>
              ))}
            </ol>
          )}
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}

const CardInfo = ({ name, value }) => {
  return (
    <div className="col-md-4 text-center">
      <div className="text-gray-800 fw-bold fs-3">
        <span className="m-0 counted">{value}</span>
      </div>
      <span className="text-gray-500 fs-8 d-block fw-bold">{name}</span>
    </div>
  );
};
