import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { ButtonIcon, ButtonPrimary, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { GET_COUNSELOR, UPDATE_COUNSELOR } from "../../../../apis/node-target-data/AcademicApi";
import { LoadingBars, NoRecordFound } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import { GET_EMPLOYEE_LOOK_UP } from "../../../../apis/node-target-data/HRAPI";
import { MessageError, MessageSuccess, openModal } from "../../../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage";
import { SGUCardFlush } from "../../../../../../_layouts/components/cards/CardUI";
import { EmployeeSelect } from "../../../../components/forms/SelectBox";

export default function CardCounselor({ studentid }) {
  const context = useOutletContext();
  const widget_74 = context.widgets.find(
    (item) => item.widget_id === 74 && item.is_enabled === 1
  );
  const [openform, setOpenForm] = useState(false);
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const postParam = {
    studentid: studentid,
  };

  const ReloadData = async () => {
    setOpenForm(false);
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_COUNSELOR(postParam);

    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);
  return (
    <SGUCardFlush>
      <SGUCardFlush.Body className={"p-0"}>
        <div className="d-flex align-items-center justify-content-between px-5 pt-3">
          <h3>
            <span className="fw-bold text-gray-900">Counselor</span>
          </h3>
          <div>
            {(widget_74 && Object.values(results.data).length > 0 ) && (
              <ButtonIcon
                className="btn-sm btn-clear"
                type="button"
                title="Update Counselor"
                onClick={() => setOpenForm(!openform)}
              >
                <KTIcon
                  iconName={!openform ? "user-edit" : "cross-square"}
                  className="text-gray-600 fw-bold fs-1"
                />
              </ButtonIcon>
            )}
          </div>
        </div>
        {results.loading ? (
          <LoadingBars />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) :
          openform ? <FormCounselor studentid={studentid} ReloadData={ReloadData} /> :
          Object.values(results.data).length > 0 ? (

            <div className="px-5 pb-2">
              {results.data.map(
                (v, index) =>
                  v.emp && (
                    <div key={index}>
                      <div className="d-flex flex-stack">
                        <div className="symbol symbol-30px me-4">
                          <div className="symbol-label fs-7 fw-semibold bg-danger text-inverse-danger">{(index + 1)}</div>
                        </div>
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                          <div className="flex-grow-1 me-2">
                            <div className="text-gray-800 fs-6 fw-bold">{v.emp.fullname}</div>
                            <span className="text-muted fw-semibold d-block fs-7">{v.emp.email}</span>
                          </div>
                        </div>
                      </div>
                      {(index < Object.values(results.data).length - 1) && (
                        <div className="separator separator-dashed my-2"></div>
                      )}
                    </div>
                  )
              )}
            </div>
          ) : (
            <div className="px-5 pb-2 text-center">
              <div className="fs-7 mb-3">
                Does not have any counselor assigned yet.
              </div>
              <ButtonTersier className="btn-sm" type="button" onClick={() => setOpenForm(!openform)} >
                Click here to add a counselor
              </ButtonTersier>
            </div>
        )}
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}

const FormCounselor = ({ studentid, ReloadData }) => {
  const [emp_id, setEmp_id] = useState(0);
  
  const [result, setResult] = useState({ loading: "", message: "", data: [] });
  const HandlerSubmit = async (e) => {
    e.preventDefault();
    if (emp_id) {
      setResult({ loading: true, message: "", data: [] });
      const param = {
        emp_id: emp_id,
        replace: true,
        students: studentid,
      };
      const result = await UPDATE_COUNSELOR(param);

      if (result.data && Object.values(result.data).length > 0) {
        setResult({ loading: false, message: "Counselor updated successfully", data: result.data });
        openModal({ message: <MessageSuccess message={"Counselor updated successfully"} /> })
        ReloadData();
      } else {
        setResult({ loading: false, message: result.message, data: [] });
        openModal({ message: <MessageError message={result.message} /> });
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <form method="post" autoComplete="off" className="px-5" onSubmit={(e) => HandlerSubmit(e)}>
      <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Find Courselor"} required={true} />

      <div className="d-grid mb-5">
        <ButtonPrimary
          className="btn-sm fw-bolder text-uppercase px-9 py-4"
          type="submit"
          disabled={result.loading}
        >
          {result.loading ? "Processing..." : "Save Changes"}
        </ButtonPrimary>
      </div>
    </form>
  );
};
