import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import moment from "moment";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { BadgeInfo } from "./TableAttd";
import {
  GET_ATD_LECTURER_BY_ATDID,
  UPDATE_STD_ATD_BY_ATD_LEC,
} from "../../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertNotif,
  AlertRetry,
} from "../../../../../_layouts/components/alerts/AlertsUI";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

export default function StudentList({ data, setData, widget_33 }) {
  const [stdPost, setStdPost] = useState([]);
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = { atdid: data.id };
    const result = await GET_ATD_LECTURER_BY_ATDID(param);

    if (result.data && Object.values(result.data).length > 0) {
      const data_students = result.data.atd_student.map((v)=>{
        if (v.studentstatusdata && v.studentstatusdata.fullname) {
            v.studentstatusdata.fullname = v.studentstatusdata.fullname
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
        }
        return v;
      })

      data_students.sort((a, b) => {
        const nameA = a.studentstatusdata?.fullname?.toUpperCase() || '';
        const nameB = b.studentstatusdata?.fullname?.toUpperCase() || '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });


      const hasStudentData = data_students.some(
        (student) => student.studentstatusdata === null
      );
      let dataStudent;
      if (hasStudentData) {
        dataStudent = data_students
          .filter(
            (student) =>
              student.studentstatusdata && student.studentstatusdata.fullname
          )
          .sort((a, b) =>
            a.studentstatusdata.fullname > b.studentstatusdata.fullname ? 1 : -1
          );
      } else {
        dataStudent = data_students;
      }

      const studentLst = dataStudent.map((v) => {
        var obj = {};
        obj.remark = v.remark ? v.remark : "";
        obj.status = v.status;
        obj.studentid = v.studentid;
        obj.flag_sts = v.flag_sts;
        obj.tap_time = v.tap_time || "";
        obj.coordinate = v.coordinate || "";
        obj.stundentname = v.studentstatusdata
          ? v.studentstatusdata.fullname
            ? v.studentstatusdata.fullname
            : "-"
          : "-";
        return obj;
      });

      setResults({ loading: false, message: "", data: studentLst });
      setStdPost(studentLst);
    } else {
      setResults(result);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerAttendanceTick = (index, value) => {
    setStdPost((prev) => {
      const newData = [...prev];
      newData[index].status = value;
      return newData;
    });
  };

  const HandlerAttendanceRemark = (index, value) => {
    setStdPost((prev) => {
      const newData = [...prev];
      newData[index].remark = value;
      return newData;
    });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(stdPost).length > 0) {
      setSubmit({ loading: true, message: "", data: [] });

      const param = {
        atd_lec_id: data.id,
        student: stdPost,
      };

      const result = await UPDATE_STD_ATD_BY_ATD_LEC(param);
      if (result.data && Object.values(result.data).length > 0) {
        openModal({
          message: <MessageSuccess message={"Successfully Saved"} />,
        });
      }
      setSubmit(result);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <>
      <ButtonTersier
        className="btn-sm mb-5"
        type="button"
        onClick={() => setData("")}
      >
        <div className="d-flex align-items-center">
          <KTIcon iconName="arrow-left" className="fs-2x me-1" />
          <span className="fw-bold fs-4">Back</span>
        </div>
      </ButtonTersier>
      <SGUCard>
        <SGUCard.Body className={"p-0"}>
          <div className="d-flex justify-content-between px-8 py-5">
            <div className="row">
              <div className="col-6">
                <div className="row align-items-center">
                  <label className="col-auto col-form-label fw-bold text-muted">
                    Start Date
                  </label>
                  <div className="col-auto">
                    <span className="fw-bold">
                      {moment(data.start_dt).format("MMM Do YYYY")},
                    </span>
                    <span className="ms-2 fw-bold">
                      {moment(data.start_dt).format("hh:mm A")}
                    </span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <label className="col-auto col-form-label fw-bold text-muted">
                    End Date
                  </label>
                  <div className="col-auto">
                    <span className="fw-bold">
                      {moment(data.end_dt).format("MMM Do YYYY")},
                    </span>
                    <span className="ms-2 fw-bold">
                      {moment(data.end_dt).format("hh:mm A")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row align-items-center">
                  <label className="col-auto col-form-label fw-bold text-muted">
                    Submited at
                  </label>
                  <div className="col-auto">
                    <span className="fw-bold">{data.submit_formatted}</span>
                  </div>
                </div>
                <div className="row align-items-center">
                  <label className="col-auto col-form-label fw-bold text-muted">
                    Total Student
                  </label>
                  <div className="col-auto">
                    <span className="fw-bold">
                      {Object.values(results.data).length}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row align-items-center">
                  <label className="col-auto col-form-label fw-bold text-muted">
                    Teaching Topic
                  </label>
                  <div className="col-auto">
                    <span className="fw-bold">{data.teaching_topic}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="text-center">
                <BadgeInfo data={data} />
                {data.approved_formatted && (
                  <span className="fw-bold">{data.approved_formatted}</span>
                )}
              </div>
            </div>
          </div>
          <form
            method="post"
            id="student-form-attd"
            autoComplete="off"
            onSubmit={(e) => HandleSubmit(e)}
          >
            <div className="table-responsive">
              <table className="table table-row-bordered align-middle gs-9">
                <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
                  <tr className="fw-bolder">
                    <th>No</th>
                    <th>Student ID</th>
                    <th>Fullname</th>
                    <th>Attendance</th>
                    <th>Absent</th>
                    <th>Remark</th>
                    <th>Tap Time</th>
                  </tr>
                </thead>
                <tbody>
                  {results.loading ? (
                    <tr>
                      <td colSpan={7}>
                        <LoadingSkeleton />
                      </td>
                    </tr>
                  ) : results.message ? (
                    <tr>
                      <td colSpan={7}>
                        <AlertRetry
                          messages={results.message}
                          Reload={ReloadData}
                        />
                      </td>
                    </tr>
                  ) : Object.values(stdPost).length > 0 ? (
                    stdPost.map((v, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{StudentIDFormat(v.studentid)}</td>
                        <td>{v.stundentname}</td>
                        <td>
                          {v.flag_sts === 1 ||
                          v.flag_sts === 4 ||
                          v.flag_sts === 5 ? (
                            <>
                              <label className="me-3">
                                <input
                                  type="radio"
                                  name={"atd-" + v.studentid}
                                  value={"P"}
                                  disabled={widget_33 ? false : true}
                                  defaultChecked={
                                    v.status == "P" ? true : false
                                  }
                                  onChange={(e) =>
                                    HandlerAttendanceTick(index, e.target.value)
                                  }
                                />{" "}
                                Present
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name={"atd-" + v.studentid}
                                  value={"A"}
                                  disabled={widget_33 ? false : true}
                                  defaultChecked={
                                    v.status == "A" ? true : false
                                  }
                                  onChange={(e) =>
                                    HandlerAttendanceTick(index, e.target.value)
                                  }
                                />{" "}
                                Absent
                              </label>
                            </>
                          ) : (
                            <span className="text-danger">{v.remark}</span>
                          )}
                        </td>
                        <td>0%</td>
                        <td>
                          <input
                            type="text"
                            name="remarks"
                            className="form-control form-control-sm"
                            defaultValue={v.remark}
                            onChange={(e) =>
                              HandlerAttendanceRemark(index, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          {v.tap_time && (
                            <>
                              <span className="fw-bold fs-7">{v.tap_time}</span>
                              <LocationCoordinate values={v} />
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}> No record found </td>
                    </tr>
                  )}
                </tbody>
                {Object.values(results.data).length > 0 && (
                  <tfoot>
                    {submit.message && (
                      <tr>
                        <td colSpan={6}>
                          <AlertNotif messages={submit.message} />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={6} className="text-end">
                        {widget_33 ? (
                        <div className="btn-group">
                          <ButtonTersier
                            className="btn-lg fw-bold text-uppercase"
                            type="button"
                            onClick={() => setData("")}
                          >
                            Cancel
                          </ButtonTersier>
                          <ButtonPrimary
                            className="btn-lg fw-bold text-uppercase"
                            type="submit"
                            disabled={submit.loading}
                          >
                            {submit.loading ? "Processing..." : "Save Changes"}
                          </ButtonPrimary>
                        </div>
                        ) :  <AlertNotif messages={"You don't have permission to update this resource. Please contact the administrator."} />}
                      </td>
                    </tr>
                  </tfoot>
                )}
                <tfoot></tfoot>
              </table>
            </div>
          </form>
        </SGUCard.Body>
      </SGUCard>
    </>
  );
}

const LocationCoordinate = ({ values }) => {
  let coordinate = values.coordinate;
  let coordinateType = "";
  if (coordinate) {
    let coordinateArr = coordinate.split(",");
    coordinateType = coordinateArr[0] + "," + coordinateArr[1];
  }

  return (
    <div>
      {coordinateType !== "0,0" ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement="top"
          overlay={renderTooltip({ title: "Click here to open map" })}
        >
          <a
            href={
              "https://www.google.com/maps/search/?api=1&query=" +
              coordinateType
            }
            target="_blank"
            className="btn btn-sm btn-light py-1 px-3"
          >
            <div className="d-flex align-items-center">
              <KTIcon iconName="map" className="me-1 text-success" />
              <span>Open</span>
            </div>
          </a>
        </OverlayTrigger>
      ) : (
        <div>
          <span className="fw-bolder me-1">Location:</span>
          <span className="text-danger">{coordinateType}</span>
        </div>
      )}
    </div>
  );
};

export {LocationCoordinate}
