import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import {
  CREATE_NOTIFICATION,
  UPDATE_ENROLLMENT,
} from "../../../apis/node-target-data/MarketingAPI";

import { ButtonPrimary } from "../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { toBase64 } from "../../components/EnrollmentComp";

const AcceptanceLetterModal = ({
  data,
  email,
  triggerNotification,
  enroll_id,
  data_for_send_letter,
  ReloadData,
}) => {
  const editorRef = useRef(null);
  const [newData, setNewData] = useState(data);
  const [emailTarget, setEmailTarget] = useState(email);
  const [subject, setSubject] = useState("Acceptance Letter");
  const [cc, setCc] = useState("");
  const [loadingSendLetter, setLoadingSendLetter] = useState(false);

  console.log("data_for_send_letter", data_for_send_letter);

  const handleEditorChange = (e) => {
    setNewData(e);
  };

  // regfee paid bisa send acceptance letter

  const handleSendLetter = async () => {
    setLoadingSendLetter(true);
    try {
      const param = {
        asonemail: 1,
        body: toBase64(newData),
        schd_type: "direct",
        channel: "notif.channel.type.email",
        subject: toBase64(subject),
        target_user: [
          {
            email: emailTarget,
            is_group: 0,
          },
        ],
      };

      if (cc !== "") {
        param.ext1 = cc;
      }

      // console.log("param - ", param);
      const send = await CREATE_NOTIFICATION(param);
      // console.log("send - ", send);
      setLoadingSendLetter(false);

      if (send.message === "") {
        triggerNotification({
          title: "Success",
          type: "success",
          message: `Success send letter`,
          duration: 3000,
        });

        const param = {
          id: enroll_id,
          loa_sent: 1,
        };

        // if (data_for_send_letter.enrollment_status === "FINISHED") {
        //   param.corporate_id = data_for_send_letter.corporate_id;
        //   param.term_id = data_for_send_letter.term_id;
        //   param.enroll_semester = data_for_send_letter.enroll_semester;
        // }

        const updateLoa = await UPDATE_ENROLLMENT(param);

        console.log("param", param);
        console.log("update loa", updateLoa);

        if (updateLoa.message === "") {
          triggerNotification({
            title: "Success",
            type: "success",
            message: `Success update LOA`,
            duration: 3000,
          });
          ReloadData();
        } else {
          triggerNotification({
            title: "Failed!",
            type: "danger",
            message: updateLoa.message,
            duration: 3000,
          });
        }
      } else {
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: send.message,
          duration: 3000,
        });
      }
    } catch (error) {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: error.message,
        duration: 3000,
      });
      setLoadingSendLetter(false);
      console.log(error);
    }
  };

  window.addEventListener("error", (event) => {
    if (event.message.includes("ResizeObserver loop limit exceeded")) {
      console.warn("ResizeObserver error ignored");
      event.preventDefault();
    }
  });

  useEffect(() => {
    console.log(newData);
  }, [newData]);

  return (
    <div>
      <div className="d-flex align-items-center flex-column flex-lg-row mb-5">
        <div className="form-group position-relative me-md-5">
          <label className={"fw-bold"}>Send to</label>
          <input
            className="form-control form-control-sm pe-10"
            placeholder="Email target"
            value={emailTarget}
            onChange={() => {}}
          />
        </div>
        <div className="form-group position-relative me-md-5">
          <label className={"fw-bold"}>Subject</label>
          <input
            className="form-control form-control-sm pe-10"
            placeholder="Subject for the letter"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {subject && (
            <span
              className="position-absolute"
              style={{
                top: "70%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => setSubject("")}
            >
              <i className="ki-outline ki-cross fs-1"></i>
            </span>
          )}
        </div>
        <div className="form-group position-relative">
          <label className={"fw-bold"}>CC</label>
          <input
            className="form-control form-control-sm pe-10"
            placeholder="CC for the letter"
            value={cc}
            onChange={(e) => setCc(e.target.value)}
          />
          {cc && (
            <span
              className="position-absolute"
              style={{
                top: "70%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => setCc("")}
            >
              <i className="ki-outline ki-cross fs-1"></i>
            </span>
          )}
        </div>
      </div>
      <Editor
        apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={newData ?? "No Content Available"}
        onEditorChange={handleEditorChange}
        init={{
          height: 450,
          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
        }}
      />
      <div className="d-flex flex-end mt-5">
        <Button
          variant="secondary"
          className="me-3"
          onClick={() => openModal({ open: false })}
        >
          Close
        </Button>
        <ButtonPrimary
          title={
            subject === "" || emailTarget === ""
              ? "Make sure email target and subject filled"
              : "Send letter"
          }
          onClick={() =>
            subject === "" || emailTarget === "" ? null : handleSendLetter()
          }
        >
          {loadingSendLetter ? "Sending..." : "Send"}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export { AcceptanceLetterModal };
