import React from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import moment from "moment";
import { SGUCardFlush } from "../../../../../../_layouts/components/cards/CardUI";

export default function LogActivitas({proposal, storage}) {
    const log_proposal = {name:proposal.audit_createdby, date:proposal.audit_created, label:"create proposal"};
    const log_proposal_app = {name:proposal.audit_approvedby, date:proposal.audit_approved, label:"approval proposal"};
    const log_storage = {name:storage.createdby, date:storage.created, label:"upload decree"};
    const logs = [log_proposal_app, log_storage, log_proposal];
  return (
    <SGUCardFlush>
      <SGUCardFlush.Header>
        <span className="card-label fw-bold text-gray-800">Log Activity</span>
      </SGUCardFlush.Header>
      <SGUCardFlush.Body>
        <div className="timeline timeline-border-dashed">
            {logs.map((v,index)=>(
                <div className="timeline-item pb-5" key={index}>
                    <div className="timeline-line"></div>
                    <div className="timeline-icon">
                        <KTIcon iconName="cd" className="fs-2 text-success" />
                    </div>
                    <div className="timeline-content m-0">
                        <span className="fs-8 fw-bolder text-success text-uppercase">
                            {v.label}
                        </span>
                        <span className="fs-7 text-gray-800 fw-bold d-block">
                            {v.name}
                        </span>
                        <span className="fw-semibold text-gray-500 fs-8">
                            {moment(v.date).format("MMM DD, YYYY hh:mm A")}
                        </span>
                    </div>
                </div>
            ))}
        </div>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}
