import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { debounce } from 'lodash';
import { LoadingBars, SpinnerLoad } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { GET_EMPLOYEE_LOOK_UP, GET_HR_FISCAL_YEAR, LOOK_UP_COST_CENTER } from "../../apis/node-target-data/HRAPI";
import {
  CLU_LOOK_UP,
  GET_ACAD_LOOKUP,
  GET_CORPORATE,
  GET_MSTONE_LOOKUP,
  GET_ROOM,
  GET_SECTION,
  GET_STUDENT,
  TYPE_LOOK_UP,
} from "../../apis/node-target-data/AcademicApi";
import { GET_LOOK_UP_CITY, GET_LOOK_UP_COUNTRY, GET_LOOK_UP_SCHOOL, GET_SCHOOL_PROGRAM_LOOKUP, GET_UNIV_LOOKUP, GET_UNIV_PRODI_LOOKUP } from "../../apis/node-target-data/IdentityApi";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";
import { OverlayTrigger } from "react-bootstrap";
import { GET_YEARINTAKE } from "../../apis/node-target-data/MarketingAPI";
import moment from "moment";
import { doc_type } from "../../../../_layouts/_core/ConstantData";

const now = moment();
const year = moment(now).format("YYYY");

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 })
};

const CourseOwner = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { search: "" };
    const result = await LOOK_UP_COST_CENTER(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data
        .filter((v) => ![25, 36, 45, 72].includes(v.id))
        .map((v) => ({ value: v.id, label: v.name }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Course Owner"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="course_owner"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder="Choose One"
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const AcadProgram = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { grouptype: "curr.group.type" };
    const result = await TYPE_LOOK_UP(param);

    setResult(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Academic Program"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <select
            name="course_owner"
            className="form-select form-select-sm"
            defaultValue={selected}
            onChange={(e) => HandlerChange(e.target.value)}
            disabled={disabled}
          >
            <option value="0">Choose One</option>
            {result.data.map((v, index) => (
              <option value={v.id} key={index}>
                {v.name ? v.name : v.id}
              </option>
            ))}
          </select>
        )
      )}
    </div>
  );
};

const AcadTerm = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "", typeShow = "", minimum_term = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { type: "asc.atp.type.term" };
    const result = await GET_ACAD_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      let r_data = result.data;
      if (typeShow) {
        r_data = result.data.filter(item => item.program_id === typeShow);
      }
      if (minimum_term) {
        r_data = r_data.filter(item => parseInt(item.id) > parseInt(minimum_term));
      }

      const option_result = r_data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Academic Calendar"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="course_owner"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const Department = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { clu_type: "asc.clu.type.Major" };
    const result = await CLU_LOOK_UP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Student Department"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="course_owner"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder="Choose One"
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const DepartmentSpec = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { clu_type: "asc.clu.type.Spec" };
    const result = await CLU_LOOK_UP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Student Specialization"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="course_owner"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder="Choose One"
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const Corporate = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { key: "" };
    const result = await GET_CORPORATE(param);

    setResult(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>{title ? title : "Corporate"}</label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <select
            name="course_owner"
            className="form-select form-select-sm"
            defaultValue={selected}
            onChange={(e) => HandlerChange(parseInt(e.target.value))}
            disabled={disabled}
          >
            <option value="0">Choose One</option>
            {result.data.map((v, index) => (
              <option value={v.id} key={index}>
                {v.name ? v.name : v.id}
              </option>
            ))}
          </select>
        )
      )}
    </div>
  );
};

const Coursetype = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { grouptype: "course.offering.type" };
    const result = await TYPE_LOOK_UP(param);

    setResult(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Course Type"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <select
            name="course_owner"
            className="form-select form-select-sm"
            defaultValue={selected}
            onChange={(e) => HandlerChange(parseInt(e.target.value))}
            disabled={disabled}
          >
            <option value="0">Choose One</option>
            {result.data.map((v, index) => (
              <option value={v.id} key={index}>
                {v.name ? v.name : v.id}
              </option>
            ))}
          </select>
        )
      )}
    </div>
  );
};

const StudentStatus = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const StudentStatusType = [
    { id: 1, name: "Active", type: "asc.student.status.active" },
    { id: 2, name: "Graduate", type: "asc.student.status.graduate" },
    { id: 3, name: "Resign", type: "asc.student.status.resign" },
    { id: 4, name: "Leave Semester", type: "asc.student.status.leave" },
    { id: 5, name: "Not Active", type: "asc.student.status.notactive" },
    {
      id: 6,
      name: "Finish Exchange",
      type: "asc.student.status.finishexchange",
    },
  ];

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>{title ? title : "Status"}</label>
      <select name="student_status" className="form-select form-select-sm" disabled={disabled} defaultValue={selected} onChange={(e) => HandlerChange(e.target.value)} >
        <option value="0">Choose One</option>
        {StudentStatusType.map((v, index) => (
          <option value={v.type} key={index}>
            {v.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const StudentActivity = ({ data, setData, required = false, disabled = false, title }) => {
  const [selected, setSelected] = useState(data || "");
  const StudentActivityType = [
    { id: 1, name: "PCF", type: "asc.student.activity.pcf" },
    { id: 2, name: "PCF Revision", type: "asc.student.activity.pcfr" },
    { id: 4, name: "Leave Semester", type: "asc.student.activity.leave" },
    { id: 5, name: "Change Major Program", type: "asc.student.activity.change.major" },
    { id: 6, name: "Change Specialization Program", type: "asc.student.activity.change.spec" },
    { id: 7, name: "Finish Exchange", type: "asc.student.activity.finishexchange" },
    { id: 8, name: "Graduate", type: "asc.student.activity.graduate" },
    { id: 9, name: "New Coming", type: "asc.student.activity.newcoming" },
    { id: 10, name: "Not Active", type: "asc.student.activity.notactive" },
    { id: 11, name: "Resign", type: "asc.student.activity.resign" },
    { id: 12, name: "Transfer Out", type: "asc.student.activity.transfer.out" },
    { id: 13, name: "Transfer Credit", type: "asc.student.activity.transfercredit" },
  ];
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>{title ? title : "Activity Type"}</label>
      <select name="student_status" className="form-select form-select-sm" disabled={disabled} defaultValue={selected} onChange={(e) => HandlerChange(e.target.value)}>
        <option value="0">Choose One</option>
        {StudentActivityType.map((v, index) => (
          <option value={v.type} key={index}>
            {v.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const CountryLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_LOOK_UP_COUNTRY(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue })
    }
    setInputValue(inputValue);
  }

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Country"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="country_id"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Choose One"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const CityLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_LOOK_UP_CITY(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue })
    }
    setInputValue(inputValue);
  }

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "City"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="city_id"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Choose One"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const SchoolLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  
  const ReloadData = async (param) => {
    const result = await GET_LOOK_UP_SCHOOL(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue })
    }
    setInputValue(inputValue);
  }

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "School Name"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="school_name"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Choose One"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const SchoolProgramLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_SCHOOL_PROGRAM_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue })
    }
    setInputValue(inputValue);
  }

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "School Program"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="school_name"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Choose One"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const UnivLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
  showtitle = true,
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_UNIV_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: decodeURIComponent(v.name),
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue })
    }
    setInputValue(inputValue);
  }

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title ? title : "University Name"}
        </label>
      )}
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="univ_name"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Choose One"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const UnivProdyLookUp = ({
  data,
  setData,
  required = false,
  disabled = false,
  univid,
  title
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { univid: univid };
    const result = await GET_UNIV_PRODI_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Program Study"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="course_owner"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder="Choose One"
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const StudentMultiple = ({ data, setData, required = false, disabled = false, title }) => {

  const [options, setOptions] = useState(data || "");
  const [inputValue, setInputValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResult({ loading: true, message: "", data: [] });
    const result = await GET_STUDENT(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.studentid,
        label: v.fullname,
      }));
      setOptions(option_result);
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (value) => {
    if (value.length >= 3) {
      let param = {};

      if (!isNaN(value) && value.trim() !== "") {
        param = { studentid: value.trim() };
      } else {
        param = { studentname: value.trim() };
      }
      ReloadData(param);
    }
    setInputValue(value);
  };

  const handleSelected = (selectedValue) => {
    if (selectedValue) {
      setData(selectedValue);
      setSelectedValue(selectedValue);
    } else {
      setData([]);
      setSelectedValue([]);
    }
  };

  return (
    <div className="mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title || "Enter Student ID or name"}
        {result.loading && <SpinnerLoad />}
      </label>
      <Select
        placeholder={"minim 3 character"}
        options={options}
        onInputChange={FindData}
        inputValue={inputValue}
        onChange={handleSelected}
        value={selectedValue}
        isMulti
      />

      {result.message && <div className="mt-3"><AlertNotif messages={result.message} /></div>}
    </div>
  )
}

const SectionSelect = ({ data, setData, required = false, disabled = false, title }) => {

  const [options, setOptions] = useState([data]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(data);

  const FindDataDebounced = useCallback(debounce((paramValue) => {
    ReloadData(paramValue);
  }, 500), []);

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);
    const result = await GET_SECTION(param);

    if (result.data && Object.values(result.data).length > 0) {
      const sort_data = result.data.sort((a, b) => b.atp_term.id - a.atp_term.id);;
      const option_result = sort_data.map((v) => ({
        value: v.section_id,
        label: v.section_id + ": " + (v.course && (v.course.code || "")) + " / " + (v.course && (v.course.coursetitle || v.section_id)) + " (" + (v.course && (v.course.credit + " SKS)")),
      }));
      setOptions(option_result);
      setResult(result);
    } else {
      setResult(result);
      setOptions([]);
    }
  };

  const FindData = (inputValue) => {
    const format_section = /^\d{4}-\d{1,2}-\d{3,4}$/;
    //const format_coursetitle = /^[^\d]+\s\d+$/;
    const format_course_code = /^[A-Z0-9]{4}-[A-Z0-9]{5,}$/;

    if (inputValue) {
      let paramValue;
      if (format_section.test(inputValue)) {
        if (inputValue.length >= 10) {
          paramValue = { section_id: inputValue };
          FindDataDebounced(paramValue);
        }
      } else if (format_course_code.test(inputValue)) {
        if (inputValue.length >= 5) {
          paramValue = { coursecode: inputValue };
          FindDataDebounced(paramValue);
        }
      } else {
        if (inputValue.length >= 5) {
          paramValue = { coursetitle: inputValue };
          FindDataDebounced(paramValue);
        }
      }
    } else {
      setOptions([]);
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedValue) => {
    setData(selectedValue);
    setSelectedValue(selectedValue);
  };

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={renderTooltip({ title: "Enter the section ID number or course title or course code to search data" })}
      >
        <div className="mb-5">
          <label className={"fw-bold " + (required && "required")}>
            {title || "Enter Section Course"}
            {result.loading && <SpinnerLoad />}
          </label>
          <Select
            placeholder="find section id or course title"
            options={options}
            onInputChange={FindData}
            inputValue={inputValue}
            onChange={handleSelected}
            value={selectedValue}
            isDisabled={disabled}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
          <small className="text-muted fw-italic">Exp: "{year}-1-1547" or "English 1" or "I200-1IELB"</small>
        </div>
      </OverlayTrigger>
      {result.message && <AlertNotif messages={result.message} />}
    </>
  )
}

const SectionSelectMulti = ({ data, setData, required = false, disabled = false, title }) => {

  const [options, setOptions] = useState(data || "");
  const [inputValue, setInputValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState(data);

  const FindDataDebounced = useCallback(debounce((paramValue) => {
    ReloadData(paramValue);
  }, 500), []);

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);
    const result = await GET_SECTION(param);

    if (result.data && Object.values(result.data).length > 0) {
      const sort_data = result.data.sort((a, b) => b.atp_term.id - a.atp_term.id);;
      const option_result = sort_data.map((v) => ({
        value: v.section_id,
        label: v.section_id + ": " + (v.course && (v.course.code || "")) + " / " + (v.course && (v.course.coursetitle || v.section_id)) + " (" + (v.course && (v.course.credit || "-") + " SKS)"),
      }));
      setOptions(option_result);
      setResult(result);
    } else {
      setResult(result);
      setOptions([]);
    }
  };

  const FindData = (inputValue) => {
    const format_section = /^\d{4}-\d{1,2}-\d{3,4}$/;
    const format_coursetitle = /^[^\d]+\s\d+$/;
    const format_course_code = /^[A-Z0-9]{4}-[A-Z0-9]{5,}$/;

    if (inputValue) {
      let paramValue;
      if (format_section.test(inputValue)) {
        if (inputValue.length >= 10) {
          paramValue = { section_id: inputValue };
          FindDataDebounced(paramValue);
        }
      } else if (format_course_code.test(inputValue)) {
        if (inputValue.length >= 6) {
          paramValue = { coursecode: inputValue };
          FindDataDebounced(paramValue);
        }
        // } else if(format_coursetitle.test(inputValue)){
        //   console.log("title");
        //   if (inputValue.length >= 5) {
        //     paramValue = { coursetitle: inputValue };
        //     FindDataDebounced(paramValue);
        //   }
      } else {
        if (inputValue.length >= 4) {
          paramValue = { coursetitle: inputValue };
          FindDataDebounced(paramValue);
        }
      }
    } else {
      setOptions([]);
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedValue) => {
    if (selectedValue) {
      setData(selectedValue);
      setSelectedValue(selectedValue);
    } else {
      setData([]);
      setSelectedValue([]);
    }
  };

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={renderTooltip({ title: "Enter the section ID number or course title or course code to search data" })}
      >
        <div className="mb-5">
          <label className={"fw-bold " + (required && "required")}>
            {title || "Enter Section Course"}
            {result.loading && <SpinnerLoad />}
          </label>
          <Select
            placeholder="find section id or course title"
            options={options}
            onInputChange={FindData}
            inputValue={inputValue}
            onChange={handleSelected}
            value={selectedValue}
            isDisabled={disabled}
            isMulti
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
          <small className="text-muted fw-italic d-block">Exp: "{year}-1-1547" or "English 1" or "I200-1IELB"</small>
          <small className="text-muted fw-italic">Type minimum 4 character</small>
        </div>
      </OverlayTrigger>
      {result.message && <AlertNotif messages={result.message} />}
    </>
  )
}

const RoomSingle = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });

    const result = await GET_ROOM();

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => {
        let obj = {};
        if (v.g_resourceName) {
          obj.value = v.id;
          obj.label = "Room " + v.g_resourceName + " (" + v.g_capacity + ") · " + (v.g_resourceType || "") + " · Floor " + (v.g_floorName)
        }
        return obj;
      });
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, [data]);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Academic Calendar"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="room_id"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const RoomMultiple = ({ data, setData, required = false, disabled = false, title, room_id }) => {
  const [selected, setSelected] = useState(room_id || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(() => {
    if (!data || (Array.isArray(data) && data.length === 0)) {
      return [];
    }
    return data;
  });
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const result = await GET_ROOM();

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: "Room " + v.g_resourceName + "(" + v.g_capacity + ")",
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
        setData([findSelected])
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  useEffect(() => {
    if (!data || (Array.isArray(data) && data.length === 0)) {
      setSelectedOption(([]));
    }
    setSelectedOption(data);
  }, [data])

  const handleChange = (selectedValue) => {
    if (selectedValue) {
      setData(selectedValue);
      setSelectedOption(selectedValue);
    } else {
      setData([]);
      setSelectedOption([]);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Room"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="room"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={handleChange}
            isDisabled={disabled}
            options={options}
            placeholder="Choose One"
            isMulti
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const MilestoneType = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { "grouptype": " atp.milestone" };
    const result = await TYPE_LOOK_UP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Milestone Type"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="milestone_type"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const MilestoneInsLookUp = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { "": "" };
    const result = await GET_MSTONE_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id + "#" + v.grouptype,
        label: v.name,
      }));
      option_result.sort((a, b) => a.label.localeCompare(b.label));

      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Milestone Type"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="milestone_type"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const MilestoneInfLookUp = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { "grouptype": "asc.atp.milestone.group.information" };
    const result = await GET_MSTONE_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Milestone Type"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="milestone_type"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const MailUserMultiple = ({ data, setData, required = false, disabled = false, title }) => {
  const [options, setOptions] = useState(data || "");
  const [inputValue, setInputValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState(data);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const FindDataDebounced = useCallback(debounce(async (paramValue) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);

    const result_std = await ReloadStudent(paramValue);

    const result_emp = await ReloadStaff(paramValue);

    const combinedResults = [...result_std, ...result_emp];
    setOptions(combinedResults);
    setResult({ loading: false, message: "", data: combinedResults });
  }, 500), []);


  const ReloadStudent = async (value) => {

    const result = await GET_STUDENT({ studentname: value, status: "asc.student.status.active" });

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.sgumail,
        label: v.sgumail,
      }));
      return option_result;
    } else {
      return [];
    }
  };

  const ReloadStaff = async (value) => {

    const result = await GET_EMPLOYEE_LOOK_UP({ search: value });

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.sgumail,
        label: v.sgumail,
      }));
      return option_result;
    } else {
      return [];
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      FindDataDebounced(inputValue);
    } else {
      setOptions([]);
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedValue) => {
    if (selectedValue) {
      setData(selectedValue);
      setSelectedValue(selectedValue);
    } else {
      setData([]);
      setSelectedValue([]);
    }
  };

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Enter participant list"}
        {result.loading && <SpinnerLoad />}
      </label>
      <Select
        placeholder="type name of student or staff"
        options={options}
        onInputChange={FindData}
        inputValue={inputValue}
        onChange={handleSelected}
        value={selectedValue}
        isDisabled={disabled}
        isMulti
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      <small className="text-muted">Type minimum 3 character</small>
    </div>
  );
};

const MailUserSingle = ({ data, setData, required = false, disabled = false, title }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(data);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const FindDataDebounced = useCallback(debounce(async (paramValue) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);

    const result_std = await ReloadStudent(paramValue);

    const result_emp = await ReloadStaff(paramValue);

    const combinedResults = [...result_std, ...result_emp];
    setOptions(combinedResults);
    setResult({ loading: false, message: "", data: combinedResults });
  }, 500), []);


  const ReloadStudent = async (value) => {

    const result = await GET_STUDENT({ studentname: value, status: "asc.student.status.active" });

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.sgumail,
        label: v.sgumail,
      }));
      return option_result;
    } else {
      return [];
    }
  };

  const ReloadStaff = async (value) => {

    const result = await GET_EMPLOYEE_LOOK_UP({ search: value });

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.sgumail,
        label: v.sgumail,
      }));
      return option_result;
    } else {
      return [];
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      FindDataDebounced(inputValue);
    } else {
      setOptions([]);
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedOption) => {
    if (selectedOption) {
      setData(selectedOption.value);
      setSelectedValue(selectedOption);
    } else {
      setData(null);
      setSelectedValue(null);
    }
  };

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Enter participant list"}
        {result.loading && <SpinnerLoad />}
      </label>
      <Select
        placeholder="type name of student or staff"
        options={options}
        onInputChange={FindData}
        inputValue={inputValue}
        onChange={handleSelected}
        value={selectedValue}
        isDisabled={disabled}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      <small className="text-muted">Type minimum 3 character</small>
    </div>
  );
};

const EmployeeSelect = ({ data, setData, required = false, disabled = false, title, showtitle = true }) => {

  const [options, setOptions] = useState([data]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(data);

  const FindDataDebounced = useCallback(debounce((value) => {
    ReloadData(value);
  }, 500), []);

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (value) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);
    const result = await GET_EMPLOYEE_LOOK_UP({ search: value });

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name_related,
      }));
      setOptions(option_result);
      setResult(result);
    } else {
      setResult(result);
      setOptions([]);
    }
  };

  const FindData = (inputValue) => {

    if (inputValue.length >= 3) {
      FindDataDebounced(inputValue);
    } else {
      setOptions([]);
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedValue) => {
    setData(selectedValue.value);
    setSelectedValue(selectedValue);
  };

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <>
      <div className="mb-5">
        {showtitle && (
          <label className={"fw-bold " + (required && "required")}>
            {title || "Employee"}
            {result.loading && <SpinnerLoad />}
          </label>
        )}
        <Select
          placeholder="type name min 3 character"
          options={options}
          onInputChange={FindData}
          inputValue={inputValue}
          onChange={handleSelected}
          value={selectedValue}
          isDisabled={disabled}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </div>
      {result.message && <AlertNotif messages={result.message} />}
    </>
  )
}

const FiscalYear = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const param = { "search": "" };
    const result = await GET_HR_FISCAL_YEAR(param);

    if (result.data && Object.values(result.data).length > 0) {
      const r_sort = result.data.sort((a, b) => (a.id < b.id) ? 1 : -1);
      const option_result = r_sort.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Budget Term"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="milestone_type"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};


const YearIntakeEnroll = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });

    const result = await GET_YEARINTAKE();

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => {
        let obj = {};
        obj.value = v.id;
        obj.label = v.year
        return obj;
      });
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, [data]);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Year Intake"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="year_intake"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const DocumentType = ({ data, setData, disabled = false, required }) => {
  const listdata = doc_type.map((v) => {
    return { value: v.id, label: v.name };
  });

  const [selected, setSelected] = useState(
    listdata.find((v) => v.value === data) || ""
  );

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"form-label fw-bold " + (required && "required")}>Document Type</label>
      <Select
        name="course_owner"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={listdata}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const AllUnivSelect = ({ data, setData, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });

    const result = await GET_UNIV_LOOKUP({ "": "" });

    if (result.data && Object.values(result.data).length > 0) {
      const otherOption = result.data.find(v => v.id === 9999);
      const remove_other = result.data.filter((v) => v.id !== 9999);
    
      const sort_data = remove_other.sort((a, b) => a.name.localeCompare(b.name));
      const option_result = sort_data.map((v) => {
        let obj = {};
        obj.label = v.name ? decodeURIComponent(v.name) : "";
        obj.value = v.id;
        return obj;
      });

      if (otherOption) {
        option_result.unshift({
          label: otherOption.name ? decodeURIComponent(otherOption.name) : "Other",
          value: otherOption.id,
        });
      }

      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected || null);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, [data]);

  useEffect(() => {
    if (data) {
      const findSelected = options.find((item) => item.value === data);
      setSelectedOption(findSelected || null);
      setSelected(data);
    } else {
      setSelectedOption(null);
      setSelected("");
    }
  }, [data, options]);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option ? option.value : "");
    setData(option ? option.value : "");
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Institution"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="univ_id"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={placeholder ? placeholder : "Choose One"}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        )
      )}
    </div>
  );
};

const AllUnivMajorTransSelect = ({ data, setData, univid, required = false, disabled = false, title, showtitle = true, placeholder = "" }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const obj_other = { label: "Other", value: 9999 };
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });

    const result = await GET_UNIV_PRODI_LOOKUP(param);

    if (result.data && Object.values(result.data).length > 0) {
      const filtered_data = result.data.filter(item => item.name.toLowerCase() !== "other");
      const sort_data = filtered_data.sort((a, b) => a.name.localeCompare(b.name));
      let option_result = sort_data.map((v) => {
        let obj = {};
        obj.label = v.name ? decodeURIComponent(v.name) : "";
        obj.value = v.id;
        return obj;
      });
      option_result.push(obj_other);

      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected || null);
      }
      setResult(result);
    } else {
      setOptions([obj_other]);
      setResult(result);
    }
  };

  useEffect(() => {
    if (univid) {
      const param = { univid: univid }
      ReloadData(param);
    }
  }, [data, univid]);

  useEffect(() => {
    if (data) {
      const findSelected = options.find((item) => item.value === data);
      setSelectedOption(findSelected || null);
      setSelected(data);
    } else {
      setSelectedOption(null);
      setSelected("");
    }
  }, [data, options, univid]);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option ? option.value : "");
    setData(option ? option.value : "");
  };

  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Institution"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        <Select
          name="univ_id"
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={HandleChange}
          isDisabled={disabled}
          options={options}
          placeholder={placeholder ? placeholder : "Choose One"}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      )}
    </div>
  );
};


export {
  CourseOwner,
  AcadProgram,
  AcadTerm,
  Department,
  DepartmentSpec,
  Corporate,
  Coursetype,
  StudentStatus,
  StudentActivity,
  CountryLookUp,
  CityLookUp,
  SchoolLookUp,
  SchoolProgramLookUp,
  UnivLookUp,
  UnivProdyLookUp,
  StudentMultiple,
  SectionSelect,
  RoomMultiple,
  RoomSingle,
  MilestoneType,
  MilestoneInsLookUp,
  MilestoneInfLookUp,
  SectionSelectMulti,
  MailUserMultiple,
  MailUserSingle,
  EmployeeSelect,
  FiscalYear,
  YearIntakeEnroll,
  DocumentType,
  AllUnivSelect,
  AllUnivMajorTransSelect
};
