import { useEffect, useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  ConvertToRupiah,
  MontIntake,
  ProgramDegree,
  YearIntake,
} from "../../Helper";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  CANCEL_MKT_FIN_TRANS,
  CREATE_MKT_FIN_TRANS,
  FLAG_MKT_FIN_TRANS,
} from "../../../apis/node-target-data/MarketingAPI";
import {
  FeeStatus,
  RegisterStatus,
  SetControlStateFeeStatus,
} from "../../components/Helper";
import moment from "moment";
import {
  BadgeAcceptance,
  BadgeItem,
  ConvertBulanKeRomawi,
} from "../../components/EnrollmentComp";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ModalCoupon } from "./kupon/ModalCoupon";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { ModalDetailBilling } from "./ModalDetailBilling";
import { ModalEntranceTest } from "./ModalEntranceTest";
import RangeDataCounter from "../../components/RangeDataCounter";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { AcceptanceLetterModal } from "./AcceptanceLetterModal";

export default function TableData({
  results,
  ReloadData,
  update_status_payment,
  coupon_all_access,
  HandlerPage,
  triggerNotification,
  templateLetter,
  acpt_letter,
}) {
  const navigation = useNavigate();

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [insertCoupon, setInserCoupon] = useState("");
  const [insertCoupon2, setInserCoupon2] = useState("");
  const [controlStateLoading, setControlStateLoading] = useState([]);

  const headers = [
    { name: "Name", field: "name", sortable: false },
    { name: "Reg Id", field: "candidate_id", sortable: false },
    { name: "Department", field: "department", sortable: false },
    { name: "Enroll Type", field: "enrollment_type", sortable: false },
    { name: "Coupon", field: "coupon", sortable: false },
    { name: "Reg Fee", field: "intake", sortable: false },
    { name: "Reg Fee Status", field: "req_fee_status", sortable: false },
    { name: "1st Installment", field: "first_installment", sortable: false },
    { name: "Reg Status", field: "reg_status", sortable: false },
    { name: "Acceptance", field: "acceptance", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.date_now = moment(new Date()).format("DD-MMMM-YYYY");
        obj.year_2_digit = moment(new Date()).format("YY");
        obj.bulan_romawi = ConvertBulanKeRomawi(
          parseInt(moment(new Date()).format("MM"))
        );
        obj.firstname = c.regstudent.firstname;
        obj.middlename = c.regstudent.middlename ?? "-";
        obj.lastname = c.regstudent.lastname;
        obj.name = c.regstudent.fullname;
        obj.email = c.regstudent.email;
        obj.candidate_id = c.candidate_id;
        obj.registrationdate = c.registrationdate;
        obj.reg_date = c.registrationdate
          ? moment(c.registrationdate).format("DD MMM YYYY hh:mm A")
          : "";
        obj.enrollment_type = c.enrollment_type?.name ?? "-";
        obj.major = c.target_department.name;
        obj.yearintake = c.yearintake;
        obj.yearintake_name = YearIntake(c.yearintake);
        obj.month_intake = c.month_intake;
        obj.month_intake_name = MontIntake(c.month_intake);
        obj.degree = c.target_department.program_id;
        obj.degree_name =
          c.target_department.program_id === 1 ? "Bachelor" : "Master";

        obj.coupon =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("regfee") && x.is_active === 1
              )?.coupon_no
            : null;

        obj.reg_fee =
          c.fin_tr.length > 0
            ? ConvertToRupiah(
                c.fin_tr.find(
                  (x) => x.trans_type.includes("regfee") && x.is_active === 1
                )?.nett_amount ?? 0
              )
            : "";

        obj.reg_fee_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("regfee") && x.is_active === 1
              )?.status ?? "CANCELED"
            : "CANCELED";

        obj.trans_id_reg_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("regfee") && x.is_active === 1
          )?.trans_id;

        obj.tuition_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
              )?.status ?? "CANCELED"
            : "CANCELED";

        obj.trans_id_tuition_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
          )?.trans_id;

        obj.reg_status = c.status;
        obj.fin_tr = c.fin_tr;
        obj.complete = c;
        obj.enroll_id = c.id;
        obj.acceptance = {
          loa_sent: c.loa_sent ?? "-",
          loa_time: c.loa_time ? moment(c.loa_time).format("DD MMM YYYY") : "-",
          loa_by: c.loa_by ?? "-",
        };
        obj.data_for_send_letter = {
          enrollment_status: c.status,
          corporate_id: c.corporate_id,
          term_id: c.term_id,
          enroll_semester: c.enroll_semester,
        };
        return obj;
      });

      computedData.sort((a, b) =>
        b.registrationdate > a.registrationdate ? 1 : -1
      );
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) => {
          const value = listData[key];
          return (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(search)
          );
        });
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // set loading state
    let stateArray = computedData.map((c, i) => {
      var obj = {};
      obj.id = i;
      obj.isLoadingFee = false;
      obj.isLoadingTuition = false;
      obj.isLoadingCoupon = false;
      return obj;
    });

    setControlStateLoading(stateArray);
    // console.log("computed data", computedData);
    return computedData;
  }, [results.data, search, sorting]);

  const CreateInvoiceFn = async (type, index, enroll_id) => {
    // no record -> draft
    const z = ResultData[index];

    SetControlStateFeeStatus(type, setControlStateLoading, index, true);
    const param = {
      candidate_id: z.complete.candidate_id,
      enroll_id: enroll_id,
      trans_type:
        type === "regfee"
          ? "mkt.fin.trans.type.regfee"
          : "mkt.fin.trans.type.tuitionfee",
    };

    if (insertCoupon !== "") {
      param["coupon_no"] = insertCoupon;
    }

    const set = await CREATE_MKT_FIN_TRANS(param);
    console.log("create->", set);

    triggerNotification({
      title: set.message ? "Failed!" : "Success",
      type: set.message ? "danger" : "success",
      message: set.message || "Success create invoice",
      duration: 3000,
    });

    SetControlStateFeeStatus(type, setControlStateLoading, index, false);
    ReloadData(JSON.parse(sessionStorage.getItem("previousFilter")));
  };

  const PayTuitionFeeFn = async (pos, trans_id, status, index, pay_mode) => {
    // unpaid -> paid
    if (pos === "tuitionfee") {
      if (status !== "PAID") {
        SetControlStateFeeStatus(pos, setControlStateLoading, index, true);
        const result = await FLAG_MKT_FIN_TRANS({
          trans_id: trans_id,
          flag: pay_mode,
        });

        console.log({
          trans_id: trans_id,
          flag: pay_mode,
        })
        console.log(result)

        triggerNotification({
          title: result.message ? "Failed!" : "Success",
          type: result.message ? "danger" : "success",
          message: result.message || "Success change to paid",
          duration: 3000,
        });
        SetControlStateFeeStatus(pos, setControlStateLoading, index, false);
        ReloadData(JSON.parse(sessionStorage.getItem("previousFilter")));
      }
    }
  };

  const Actions = ({
    candidate_id,
    index,
    main,
    reg_fee_trans_id,
    reg_fee_status,
  }) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="left"
        overlay={
          <Popover className="p-3">
            <Popover.Body className="p-0">
              <>
                <div
                  className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  onClick={() =>
                    navigation("detail/" + btoa(JSON.stringify(candidate_id)))
                  }
                >
                  <KTIcon iconName="profile-user" className="fs-3 me-2" />
                  <span>Detail Candidate</span>
                </div>
                <div
                  className="my-3 cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  onClick={() =>
                    openModal({
                      message: <ModalDetailBilling main={main} />,
                      header: <h4 className="modal-title">Detail Billing</h4>,
                    })
                  }
                >
                  <KTIcon iconName="cheque" className="fs-3 me-2" />
                  <span>Detail Billings</span>
                </div>
                <div
                  className="my-3 cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  onClick={() =>
                    openModal({
                      message: (
                        <ModalEntranceTest candidate_id={candidate_id} />
                      ),
                      header: (
                        <h4 className="modal-title">Detail Entrance Test</h4>
                      ),
                    })
                  }
                >
                  <KTIcon iconName="award" className="fs-3 me-2" />
                  <span>Entrance Test</span>
                </div>
              </>
            </Popover.Body>
          </Popover>
        }
      >
        <i className="ki-duotone ki-burger-menu-5 fs-3 cursor-pointer"></i>
      </OverlayTrigger>
    );
  };

  const handlePaste = async (cop) => {
    try {
      const text = await navigator.clipboard.readText();
      if (cop === 1) {
        setInserCoupon(text);
      } else if (cop === 2) {
        setInserCoupon2(text);
      }
    } catch (err) {
      alert("Failed to paste text.");
    }
  };

  const PopUpStatus = (status, key, index, trans_id, enroll_id) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="right"
        overlay={
          update_status_payment && status !== "PAID" ? (
            <Popover className="p-3">
              <Popover.Body className="p-0">
                {key === "regfee" && status === "CANCELED" && (
                  <>
                    <div className="position-relative">
                      <div className="d-flex justify-content-between">
                        <label className="fw-bold">Coupon</label>
                        <label
                          className="fw-bold text-muted text-hover-success cursor-pointer"
                          onClick={() => handlePaste(1)}
                        >
                          Paste
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Inset coupon"
                        defaultValue={insertCoupon}
                        onChange={(e) => setInserCoupon(e.target.value.trim())}
                      />
                      {insertCoupon && (
                        <span
                          className="position-absolute"
                          style={{
                            top: "70%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => setInserCoupon("")}
                        >
                          <i className="ki-outline ki-cross fs-1"></i>
                        </span>
                      )}
                    </div>
                    <div
                      className="mt-5 cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                      onClick={() =>
                        CreateInvoiceFn("regfee", index, enroll_id)
                      }
                    >
                      <KTIcon iconName="notepad" className="fs-3 me-2" />
                      <span>Create Fee Invoice</span>
                    </div>
                  </>
                )}
                {key === "tuitionfee" && (
                  <>
                    {status === "UNPAID" && (
                      <>
                        <div
                          className="cursor-pointer bg-hover-light-success text-hover-success ps-3 pe-5 py-3 rounded d-flex align-items-center"
                          onClick={() =>
                            PayTuitionFeeFn(key, trans_id, status, index, 1)
                          }
                        >
                          <KTIcon
                            iconName="abstract-20"
                            className="fs-3 me-2"
                          />
                          {/* <span>Full paid</span> */}
                          <span>Change to paid</span>
                        </div>
                        {/* <div
                          className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center mt-2"
                          onClick={() =>
                            PayTuitionFeeFn(key, trans_id, status, index, 2)
                          }
                        >
                          <KTIcon
                            iconName="abstract-18"
                            className="fs-3 me-2"
                          />
                          <span>Partial paid</span>
                        </div> */}
                      </>
                    )}
                    {status === "CANCELED" && (
                      <div
                        className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                        onClick={() =>
                          CreateInvoiceFn("tuitionfee", index, enroll_id)
                        }
                      >
                        <KTIcon iconName="send" className="fs-3 me-2" />
                        <span>Create Tuition Invoice</span>
                      </div>
                    )}
                  </>
                )}
              </Popover.Body>
            </Popover>
          ) : (
            <div></div>
          )
        }
      >
        {key === "regfee" ? (
          controlStateLoading[index]?.isLoadingFee ? (
            <SpinnerLoad />
          ) : (
            <div style={{ display: "inline-flex" }}>
              <FeeStatus data={status} />
            </div>
          )
        ) : controlStateLoading[index]?.isLoadingTuition ? (
          <SpinnerLoad />
        ) : (
          <div style={{ display: "inline-flex" }}>
            <FeeStatus data={status} />
          </div>
        )}
      </OverlayTrigger>
    );
  };

  const handleSendLetter = (index, templateLetter, email, enroll_id) => {
    let tmpt = templateLetter;

    const indexToUse = index;
    const data = ResultData[indexToUse]; // Data dari indeks tertentu

    // Gantikan placeholder dalam template
    const placeholders = tmpt.match(/\{\{(\w+)\}\}/g); // Cari placeholder
    if (placeholders) {
      placeholders.forEach((placeholder) => {
        const key = placeholder.replace(/\{\{|\}\}/g, ""); // Ambil nama key
        tmpt = tmpt.replace(placeholder, data[key] || ""); // Gantikan placeholder
      });
    }

    openModal({
      onClose: () => ReloadData(),
      header: "Acceptance Letter",
      message: (
        <AcceptanceLetterModal
          data={tmpt}
          email={email}
          enroll_id={enroll_id}
          ReloadData={ReloadData}
          triggerNotification={triggerNotification}
          data_for_send_letter={ResultData[index]?.data_for_send_letter}
        />
      ),
      size: "xl",
    });
  };

  const PopUpSentAcceptance = (index, email, enroll_id) => {
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        rootClose
        placement="top"
        overlay={
          update_status_payment ? (
            <Popover className="p-3">
              <Popover.Body className="p-0">
                <p className="fw-bold m-0">
                  <span className="text-danger">*</span>One time only
                </p>
              </Popover.Body>
            </Popover>
          ) : (
            <div></div>
          )
        }
      >
        {controlStateLoading[index]?.isLoadingTuition ? (
          <SpinnerLoad />
        ) : (
          <div
            className={`align-items-center mt-1 badge badge-light me-2 cursor-pointer`}
            style={{ flex: "0 0 auto" }}
            onClick={() =>
              handleSendLetter(index, templateLetter, email, enroll_id)
            }
          >
            <span className="fs-8 ms-1">Send Acpt</span>
          </div>
        )}
      </OverlayTrigger>
    );
  };

  const CancelChangeCouponFn = async (pos, trans_id, index, complete) => {
    const type = "regfee";
    // console.log((complete))
    SetControlStateFeeStatus(pos, setControlStateLoading, index, true);
    // cancel trans
    const cancel = await CANCEL_MKT_FIN_TRANS({ trans_id: trans_id });
    if (cancel.message === "") {
      // create mkt & send invoice
      const param = {
        candidate_id: complete.candidate_id,
        enroll_id: complete.enroll_id,
        trans_type:
          type === "regfee"
            ? "mkt.fin.trans.type.regfee"
            : "mkt.fin.trans.type.tuitionfee",
      };

      if (insertCoupon2 !== "" && pos === "change_coupon") {
        param["coupon_no"] = insertCoupon2;
      }
      const create = await CREATE_MKT_FIN_TRANS(param);
      if (create.message === "") {
        triggerNotification({
          title: "Success",
          type: "success",
          message:
            insertCoupon2 === ""
              ? "Success remove coupon!"
              : "Success change coupon!",
          duration: 3000,
        });
        SetControlStateFeeStatus(pos, setControlStateLoading, index, false);
        ReloadData(JSON.parse(sessionStorage.getItem("previousFilter")));
      } else {
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: create.message,
          duration: 3000,
        });
        SetControlStateFeeStatus(pos, setControlStateLoading, index, false);
        ReloadData(JSON.parse(sessionStorage.getItem("previousFilter")));
      }
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: cancel.message,
        duration: 3000,
      });
      SetControlStateFeeStatus(pos, setControlStateLoading, index, false);
    }
    setInserCoupon2("");
  };

  const CancelChangeCoupon = (coupon, trans_id, index, complete) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={
          update_status_payment && complete.reg_fee_status !== "PAID" ? (
            <Popover className="p-3">
              <Popover.Body className="p-0">
                <>
                  <div>
                    <div className="position-relative">
                      <div className="d-flex justify-content-between">
                        <label className="fw-bold">Coupon</label>
                        <label
                          className="fw-bold text-muted text-hover-success cursor-pointer"
                          onClick={() => handlePaste(2)}
                        >
                          Paste
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Inset coupon"
                        defaultValue={insertCoupon2}
                        onChange={(e) => setInserCoupon2(e.target.value.trim())}
                      />
                      {insertCoupon2 && (
                        <span
                          className="position-absolute"
                          style={{
                            top: "70%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => setInserCoupon2("")}
                        >
                          <i className="ki-outline ki-cross fs-1"></i>
                        </span>
                      )}
                    </div>

                    <div
                      className={`mt-2 ps-3 pe-5 py-3 rounded d-flex align-items-center ${
                        insertCoupon2 === ""
                          ? "cursor-default"
                          : "cursor-pointer bg-hover-light-primary text-hover-primary"
                      }`}
                      onClick={() =>
                        insertCoupon2 !== "" &&
                        CancelChangeCouponFn(
                          "change_coupon",
                          trans_id,
                          index,
                          complete
                        )
                      }
                    >
                      <KTIcon iconName="arrows-loop" className="fs-3 me-2" />
                      <span>Change Coupon</span>
                    </div>
                  </div>

                  <div className="separator my-5"></div>

                  <div
                    className="cursor-pointer bg-hover-light-danger text-hover-danger ps-3 pe-5 py-3 rounded d-flex align-items-center"
                    onClick={() =>
                      CancelChangeCouponFn(
                        "remove_coupon",
                        trans_id,
                        index,
                        complete
                      )
                    }
                  >
                    <KTIcon iconName="cross-circle" className="fs-3 me-2" />
                    <span>Remove Coupon</span>
                  </div>
                </>
              </Popover.Body>
            </Popover>
          ) : (
            <></>
          )
        }
      >
        {controlStateLoading[index]?.isLoadingCoupon ? (
          <SpinnerLoad />
        ) : coupon ? (
          <div className="cursor-pointer">{coupon}</div>
        ) : (
          <div className="ps-5">-</div>
        )}
      </OverlayTrigger>
    );
  };

  return (
    <div className="position-relative">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <RangeDataCounter
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td className="fs-7" style={{ width: 150 }}>
                    <span className="d-block fw-bolder">{v.name}</span>
                    {/* <BadgeItem
                      icon="abstract-20"
                      name={v.enrollment_type}
                      title="Enrollment Type"
                    /> */}
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bolder">
                      {/* {formatNumberWithRegex(v.candidate_id)} */}
                      {StudentIDFormat(v.candidate_id)}
                    </span>
                    <BadgeItem
                      icon="calendar"
                      name={v.reg_date}
                      title="Reg Date"
                    />
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bolder">{v.major}</span>
                    <BadgeItem
                      icon="time"
                      name={`${v.month_intake_name} ${v.yearintake_name}`}
                      title="Year Intake"
                      className="me-3"
                    />
                    {/* <BadgeItem
                      icon="abstract-20"
                      name={v.enrollment_type}
                      title="Enrollment Type"
                      className="me-3"
                    /> */}
                    <BadgeItem
                      icon="teacher"
                      name={ProgramDegree(v.degree)}
                      title="Program Degree"
                      className="me-3"
                    />
                  </td>
                  <td className="fs-7 fw-bold">{v.enrollment_type}</td>
                  <td className="fs-7 fw-bold">
                    {v.coupon === null &&
                    coupon_all_access &&
                    v.reg_fee_status !== "PAID" ? (
                      <ModalCoupon
                        id={index}
                        typeModal="p"
                        candidate_id={v.candidate_id}
                        trans_id_regfee={v.trans_id_reg_fee}
                        HandlerPage={HandlerPage}
                        enroll_id={v.enroll_id}
                      />
                    ) : (
                      CancelChangeCoupon(v.coupon, v.trans_id_reg_fee, index, v)
                    )}
                  </td>
                  <td className="fs-7 fw-bold">
                    {v.reg_fee_status === "CANCELED" ? (
                      <span className="ps-6">-</span>
                    ) : (
                      v.reg_fee
                    )}
                  </td>
                  <td>
                    {PopUpStatus(
                      v.reg_fee_status,
                      "regfee",
                      index,
                      v.trans_id_reg_fee,
                      v.enroll_id
                    )}
                  </td>
                  <td>
                    {PopUpStatus(
                      v.tuition_status,
                      "tuitionfee",
                      index,
                      v.trans_id_tuition_fee,
                      v.enroll_id
                    )}
                  </td>
                  <td>
                    <RegisterStatus data={v.reg_status} />
                  </td>
                  <td className="">
                    {v.acceptance.loa_sent !== 1 ? (
                      acpt_letter ? (
                        PopUpSentAcceptance(index, v.email, v.enroll_id)
                      ) : (
                        <span className="text-muted fs-7">Not send yet</span>
                      )
                    ) : (
                      <>
                        {/* <BadgeAcceptance
                          icon="teacher"
                          value={v.acceptance.loa_sent === 1 && "Sended"}
                          title="Status"
                          className="me-2"
                        /> */}
                        <BadgeAcceptance
                          icon="time"
                          value={v.acceptance.loa_time}
                          title="Sent Date"
                          className="me-2"
                        />
                        <BadgeAcceptance
                          icon="teacher"
                          value={v.acceptance.loa_by}
                          title="Sent By"
                          className="me-2"
                        />
                      </>
                    )}
                  </td>
                  <td className="text-center" style={{ width: 50 }}>
                    {
                      <Actions
                        candidate_id={v.candidate_id}
                        index={index}
                        main={v}
                        length={v.fin_tr.length}
                        reg_fee_trans_id={v.trans_id_reg_fee}
                        reg_fee_status={v.reg_fee_status}
                      />
                    }
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
