import React from 'react'
import AcadOverview from './AcadOverview'
import GraphicsGPA from './GraphicsGPA'

export function StudentAcademic({stdAcad, stdBio}) {
  return (
    <div id='acad-info'>
      <AcadOverview stdAcad={stdAcad} stdBio={stdBio} />
      <GraphicsGPA data={stdAcad} />
    </div> 
  )
}
