import { useState } from "react"
import { KTIcon } from "../../../_metronic/helpers"
import { ButtonLong, ButtonPrimary, ButtonTersier } from "../buttons/ButtonUI"
import { MessageSuccess, openModal } from "../modals/Modals"
import { InputPassword } from "../../../app/modules/authentications/components/From"
import { RequiredMsg } from "../../_core/ErrorMessage"
import { AlertNotif } from "../alerts/AlertsUI"
import { LoginEstes } from "../../../app/modules/authentications/API"
import { GenerateAppID, GET_ENVIRONMENT } from "../../_core/Core"
import CryptoJSAesJson from "../../_helpers/CryptoJSAesJson"
import { authStoreData } from "../../../app/reducer/AuthRedux"

const MenuCounsumerKey = ({ account }) => {
    return (
        <div className='menu-item px-5'>
            <div className='menu-link px-5 cursor-pointer align-items-center justify-content-center' onClick={() => openModal({ message: <SwitchKey account={account} /> })}>
                <span className="me-1 text-primary">Change consumer key</span>
                <KTIcon iconName='arrows-circle' className='fs-2 text-primary' />
            </div>
        </div>
    )
}

const SwitchKey = ({ account }) => {
    const TYPE_ENV = GET_ENVIRONMENT();
    const modeData = account.typeCK === 'production' ? 'development' : 'production';
    const [isSwitch, setIsSwitch] = useState(false);
    const [postData, setPostData] = useState({
        username: account.identity.email,
        password: "",
        typeCK: modeData
    });
    const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });
    var datetime = new Date();
    datetime.setHours(datetime.getHours() + 6);

    const HandlerSwitch = async () => {
        if (postData.password) {
            setSubmit({ loading: true, data: [], message: "" });
            const hApp = {
                "App-Env": TYPE_ENV.appenv,
                "App-Id": GenerateAppID(36),
            }
            const headers = hApp;
            headers['Content-Type'] = 'application/json';

            var encrypted_password = JSON.parse(
                CryptoJSAesJson.encrypt(
                    postData.password,
                    TYPE_ENV.privatekey
                )
            );

            const payload = {
                username: postData.username,
                password: encrypted_password,
                typeCK: postData.typeCK
            }

            const results = await LoginEstes({ headers, payload });
            if (Object.values(results.data).length > 0 && results.message) {
                const authStorage = {
                    headers: hApp,
                    identity: results.data.identity,
                    secretkey: results.data.secretkey,
                    expiredSession: datetime.getTime(),
                    typeCK: payload.typeCK
                }
                authStoreData(authStorage);
                openModal({ message: <MessageSuccess message={"Successfully switched, please wait for a second"} /> });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                setSubmit({ loading: false, data: [], message: results.message });
            }
        } else {
            setSubmit({ loading: false, data: [], message: RequiredMsg });
        }
    }
    if (!isSwitch) {
        return (
            <div className="pt-5">
                <p className="fs-3 text-center">You are currently using <span className="text-primary text-capitalize fw-bold">{account.typeCK}</span> data.
                    Do you want to switch to <span className="text-primary text-capitalize fw-bold">{modeData}</span> data? </p>
                <div className="mt-8 text-center">
                    <ButtonTersier type="button" className="btn-lg fw-bold me-3" onClick={() => openModal({ open: false })}>
                        Cancel
                    </ButtonTersier>
                    <ButtonPrimary type="button" className="btn-lg fw-bold" onClick={() => setIsSwitch(true)}>
                        Yes, please
                    </ButtonPrimary>
                </div>
            </div>
        )
    } else {
        return (
            <div className="pt-5">
                {submit.message && (
                    <div className="mb-5">
                        <AlertNotif type="danger" messages={submit.message} />
                    </div>
                )}
                <p className="fs-4 required">Enter your password correctly:</p>
                <InputPassword postData={postData} setPostData={setPostData} showTitle={true} />
                <ButtonLong type="button" className="btn-lg btn-danger fw-bold" disabled={submit.loading} onClick={() => HandlerSwitch()} >
                    {submit.loading ? "Processing..." : (
                        <span>Switch to <span className="text-capitalize fw-bold">{modeData}</span></span>
                    )}
                </ButtonLong>
            </div>
        )
    }
}

export { MenuCounsumerKey }