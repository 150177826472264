import { useEffect, useMemo, useRef } from "react";
import ApexCharts from "apexcharts";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../../_metronic/assets/ts/_utils";

const Bar = ({ className, data, listYear }) => {
  const chartRef = useRef(null);

  const media = [
    "Education Consultan",
    "School Information",
    "Website",
    "Instagram",
    "LinkedIn",
    "Google",
    "Others",
  ];

  const CookedData = useMemo(() => {
    if (Object.keys(data).length > 0) {
      let slpitMedia = {};
      Object.keys(data).forEach((key) => {
        slpitMedia[key] = {
          "Education Consultan": data[key].filter(
            (item) => item.sgumkt === "Education Consultan"
          ).length,
          "School Information": data[key].filter(
            (item) => item.sgumkt === "School Information"
          ).length,
          Website: data[key].filter((item) => item.sgumkt === "Website").length,
          Instagram: data[key].filter((item) => item.sgumkt === "Instagram")
            .length,
          LinkedIn: data[key].filter((item) => item.sgumkt === "LinkedIn")
            .length,
          Google: data[key].filter((item) => item.sgumkt === "Google").length,
          Others: data[key].filter((item) => item.sgumkt === "Others").length,
        };
      });

      // const bachelor = slpitMedia.bachelor;
      // const master = slpitMedia.master;

      // const newData = {
      //   bachelor: bachelor_program,
      //   master: master_program,
      // };
      // setLoading(false);
      return slpitMedia;
    }
  }, [data]);

  console.log("cooked data", CookedData);

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, CookedData)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            How Did You Know About SGU?
          </span>
          <span className="text-muted fw-semibold fs-7">
            //
          </span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: "350px" }}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { Bar };

function getChartOptions(height, cooked) {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const bachelor = getCSSVariableValue("--bs-primary");
  const master = getCSSVariableValue("--bs-info");

  const color1 = "#279FFA",
    color2 = "#26E7A5",
    color3 = "#FEBB3B",
    color4 = "#FE6177",
    color5 = "#8A74D7",
    color6 = "#6D828C",
    color7 = "#47B3A8";

  const cekCooked = cooked ? Object.keys(cooked).length : 0;

  const getYearArray = () => {
    if (cooked) {
      return Object.keys(cooked);
    }
    return [];
  };

  const getData = (med) => {
    let arrayTotalPerYear = [];
    Object.keys(cooked).forEach((thn) => {
      arrayTotalPerYear.push(cooked[thn][med]);
    });
    return arrayTotalPerYear;
  };

  return {
    series: [
      {
        name: "Education Consultan",
        data: cekCooked > 0 ? getData("Education Consultan") : [0],
      },
      {
        name: "School Information",
        data: cekCooked > 0 ? getData("School Information") : [0],
      },
      {
        name: "Website",
        data: cekCooked > 0 ? getData("Website") : [0],
      },
      {
        name: "Instagram",
        data: cekCooked > 0 ? getData("Instagram") : [0],
      },
      {
        name: "LinkedIn",
        data: cekCooked > 0 ? getData("LinkedIn") : [0],
      },
      {
        name: "Google",
        data: cekCooked > 0 ? getData("Google") : [0],
      },
      {
        name: "Others",
        data: cekCooked > 0 ? getData("Others") : [0],
      },
    ],

    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    legend: {
      show: true,
      position: "top", // Position the legend at the top
      horizontalAlign: "center", // Align it to the right
      floating: false,
      offsetY: 0,
      offsetX: 0,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: cekCooked > 0 ? getYearArray() : [0],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    colors: [color1, color2, color3, color4, color5, color6, color7],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
