import React, { useEffect, useState } from "react";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../../_layouts/_core/ErrorMessage";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import CourseOffering from "./componensts/CourseOffering";
import MatriculationCourse from "./componensts/MatriculationCourse";
import CandidateData from "./componensts/CandidateData";
import { ButtonPrimary } from "../../../../../_layouts/components/buttons/ButtonUI";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { CREATE_TRANSFER_PROPOSAL, UPDATE_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";
import moment from "moment";
import { UPDATE_CANDIDATE } from "../../../apis/node-target-data/MarketingAPI";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

export function FormProposalCredit() {
  const navigate = useNavigate();
  const location = useLocation();

  const context = useOutletContext();
  const widget_108 = context.widgets.find(
    (item) => item.widget_id === 108 && item.is_enabled === 1
  );

  const [courseData, setCourseData] = useState(
    location.state ? location.state.courseData : []
  );
  const [mycourses, setMyCourses] = useState(
    location.state ? location.state.mycourses : []
  );
  const [dcandidate, setDcandidate] = useState("");
  const [candidates, setCandidates] = useState(
    location.state ? location.state.data.candidates : []
  );

  const [doc_date, setDoc_date] = useState(
    moment(location.state?.data?.doc_date || undefined).toDate()
  );
  const [target_dept, setTargetDept] = useState("");
  const [univ, setUniv] = useState(() => {
    const initialValue = location.state?.data?.university_id;
    return initialValue ?? 0;
  });
  const [univProdi, setUnivProdi] = useState(() => {
    const initialValue = location.state?.data?.university_prodi;
    return initialValue ?? 0;
  });
  const [univLastSemes, setUnivLastSemes] = useState(0);

  const [isExist, setIsExist] = useState(false);
  const [isAvail, setIsAvailable] = useState(false);

  useEffect(() => {
    // if (univ === 9999) {
    //   openModal({ message: <FormUniv setData={setUniv} /> });
    // } else if (univProdi === 9999) {
    //   openModal({
    //     message: <FormUnivProdi univid={univ} setData={setUnivProdi} />,
    //   });
    // }

    if(Object.values(candidates).length > 0 && (univ === 0 || univ === 9999)) {
      setIsAvailable(false);
      openModal({ message: <MessageError title={"Previous institution is empty"} message={"Please update the data of "+(StudentIDFormat(candidates))+" first through admissions regarding the previous institute (university, major, and last semester)"} /> });
    }else{
      setIsAvailable(true);
    }
  }, [univ, univProdi]);

  useEffect(() => {
    if (courseData.length > 0) {
      const datapcf = courseData.map((v) => {
        const existingCourse = mycourses.find((course) => course.id === v.id);
        return {
          ...v,
          action_type: existingCourse?.action_type || "add",
          is_transfer: existingCourse?.is_transfer || 1,
          score: existingCourse?.score || "",
          section_id: existingCourse?.section_id || "",
          course_ext: existingCourse?.course_ext || "",
          credit_ext: existingCourse?.credit_ext || "",
          score_ext: existingCourse?.score_ext || "",
        };
      });

      const updatedMyCourses = mycourses.filter(course =>
        courseData.some(v => v.id === course.id)
      );

      const mergedCourses = [...updatedMyCourses, ...datapcf.filter(course =>
        !updatedMyCourses.some(existingCourse => existingCourse.id === course.id)
      )];

      if (JSON.stringify(mergedCourses) !== JSON.stringify(mycourses)) {
        setMyCourses(mergedCourses);
      }
    } else {
      setMyCourses([]);
    }
  }, [courseData]);

  const RemoveCourse = (id) => {
    setCourseData((prevData) => prevData.filter((course) => course.id !== id));
    setMyCourses((prevMyCourses) => prevMyCourses.filter((course) => course.id !== id));
  };

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (candidates && univ && univProdi && univLastSemes && Object.values(mycourses).length > 0 && doc_date) {
      setSubmit({ loading: true, data: [], message: "" });

      if (dcandidate && (dcandidate.regstudent.university_id !== univ || dcandidate.regstudent.university_prodi_id != univProdi || dcandidate.regstudent.university_last_semester != univLastSemes)) {
        const param_candidate = { id: dcandidate.regstudent.id, university_id: univ, university_prodi_id: univProdi, university_last_semester: univLastSemes };
        const result_update = await UPDATE_CANDIDATE(param_candidate);
      }

      const invalidScores = mycourses.reduce((acc, course) => {
        if (
          !course.score ||
          course.score === "" ||
          course.score === null ||
          course.score === undefined ||

          !course.score_ext ||
          course.score_ext === "" ||
          course.score_ext === null ||
          course.score_ext === undefined ||

          !course.course_ext ||
          course.course_ext === "" ||
          course.course_ext === null ||
          course.course_ext === undefined ||

          !course.credit_ext ||
          course.credit_ext === "" ||
          course.credit_ext === null ||
          course.credit_ext === undefined

        ) {
          //console.log(course.coursetitle, course.score_ext);
          acc.push({ id: course.coursetitle, message: "required mandatory" });
        } else {
          const score = parseInt(course.score);
          //const score_ext = course.score_ext;
          if (isNaN(score) || score < 0) {
            acc.push({ id: course.coursetitle, message: "invalid score" });
          } else if (score > 100) {
            acc.push({ id: course.coursetitle, message: "score more than 100" });
          }
        }
        return acc;
      }, []);

      if (invalidScores.length > 0) {
        //console.log(invalidScores);
        const errorMessages = invalidScores
          .map((error) => `Course ${error.id} has ${error.message};`)
          .join("\n");
        openModal({ message: <MessageError message={errorMessages} /> });
        setSubmit({ loading: false, data: [], message: "" });
        return;
      }

      const courses = mycourses.map((course) => ({
        ...course,
        credit_ext: (course.credit_ext && course.credit_ext !== null ? parseInt(course.credit_ext) : 0),
        score_ext: course.score_ext && course.score_ext !== null ? course.score_ext : "",
        course_id: parseInt(course.id)
      }));

      const totalCredit = courses.reduce(
        (sum, course) => sum + (parseInt(course.credit) || 0),
        0
      );
      const totalCreditExt = courses.reduce(
        (sum, course) => sum + (parseInt(course.credit_ext) || 0),
        0
      );

      const dataParam = {
        candidates: candidates,
        doc_date: doc_date,
        target_dept: target_dept,
        university_id: univ,
        university_prodi: univProdi,
        courses: courses,
        total_credit: { totalCredit, totalCreditExt },
      };

      let param_post = {
        candidate_id: dataParam.candidates,
        doc_date: moment(doc_date).format('YYYY-MM-DD'),
      };

      let result = { loading: true, data: [], message: "" };
      const proposal_id = location.state?.proposal_id;
      if (proposal_id) {
        console.log(dataParam.courses);
        param_post.proposal_id = proposal_id;
        param_post.courses = dataParam.courses.map(v => {
          let obj = {};
          if (v.course_dt_id) {
            obj.id = v.course_dt_id;
          }
          obj.course_id = v.course_id;
          obj.course_ext = v.course_ext,
            obj.credit_ext = v.credit_ext.toString(),
            obj.score = parseFloat(v.score),
            obj.score_ext = v.score_ext
          return obj;
        });
        result = await UPDATE_TRANSFER_PROPOSAL(param_post);
      } else {
        param_post.courses = dataParam.courses.map(v => {
          let obj = {};
          obj.course_id = v.course_id;
          obj.course_ext = v.course_ext,
            obj.credit_ext = v.credit_ext.toString(),
            obj.score = parseFloat(v.score),
            obj.score_ext = v.score_ext
          return obj;
        });
        result = await CREATE_TRANSFER_PROPOSAL(param_post);
      }

      if (result.data && Object.values(result.data).length > 0) {
        navigate('/course-managements/transfer-credit-proposal/preview', {
          state: {
            data: dataParam,
            mycourses: mycourses,
            courseData: courseData,
            result_draft: result.data
          }
        });
      } else {
        openModal({ message: <MessageError message={result.message} /> });
      }
      setSubmit(result);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return widget_108 ? (
    <div className="form-proposal">
      <form method="post" autoComplete="off" onSubmit={(e) => SubmitHandler(e)}>
        <div className="page-title d-flex flex-column justify-content-center flex-wrap mb-8 ">
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            Transfer Credit Proposal Form
          </h1>

          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <LinkBtn
                to={"/course-managements/transfer-credit-proposal"}
                className="d-flex align-items-center justify-content-center text-muted text-hover-primary"
              >
                <KTIcon iconName="arrow-left" className="fs-3 me-1" />
                Back
              </LinkBtn>
            </li>
          </ul>
        </div>

        <div className="row">
          <div className="col-12 mb-5">
            <CandidateData
              isAvail={isAvail}
              cadidates={candidates}
              setDcandidate={setDcandidate}
              setCandidates={setCandidates}
              univ={univ}
              setUniv={setUniv}
              univProdi={univProdi}
              setUnivProdi={setUnivProdi}
              setTargetDept={setTargetDept}
              doc_date={doc_date}
              setDoc_date={setDoc_date}
              setIsExist={setIsExist}
              univLastSemes={univLastSemes} 
              setUnivLastSemes={setUnivLastSemes}
            />
          </div>
          <div className="col-lg-4 mb-5">
            <CourseOffering
              courseData={courseData}
              setCourses={setCourseData}
            />
          </div>
          <div className="col-lg-8 mb-5">
            <MatriculationCourse
              mycourses={mycourses}
              setMyCourses={setMyCourses}
              RemoveCourse={RemoveCourse}
              setCourses={setCourseData}
            />
          </div>
        </div>

        {isExist ? (
          <div className="mb-5">
            <AlertNotif title={"Attention"} messages={"This candidate has already been created. Please select another candidate."} />
          </div>
        ) : (
          <div className="rounded bg-white border px-8 py-5">
            <div className="d-flex align-items-center justify-content-between">
              <LinkBtn
                to={"/course-managements/transfer-credit-proposal"}
                className="btn btn-sm btn-light fw-bold"
              >
                Cancel
              </LinkBtn>
              {isAvail && (
              <ButtonPrimary
                type="submit"
                className="btn-sm px-10"
                disabled={submit.loading}
              >
                {submit.loading ? "Processing..." : "Save"}
              </ButtonPrimary>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  ) : (
    <AccessInvalid />
  );
}
