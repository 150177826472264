export const DoctypeIdentifier = (type) => {
  if (type.includes("ktp")) {
    return "KTP";
  } else if (type.includes("pasfoto")) {
    return "PASFOTO";
  } else if (type.includes("passport")) {
    return "PASSPORT";
  } else if (type.includes("reportcards")) {
    return "RAPOR";
  } else if (type.includes("birthcertificate")) {
    return "BIRTH CERT";
  } else if (type.includes("ijazah")) {
    return "IJAZAH";
  } else if (type.includes("familycertificate")) {
    return "FAMILY CERT";
  } else if (type.includes("certificate")) {
    return "CERTIFICATE";
  } else if (type.includes("recommendationletter")) {
    return "RCMD LETTER";
  }
};

export const VerifyStatus = (status) => {
  let classType = "";
  let newStatus = "";
  if (status === 0) {
    newStatus = "Verification Requested";
    classType = "badge-light-warning";
  } else if (status === 1) {
    newStatus = "Verified";
    classType = "badge-light-success";
  }
  return <div className={`badge ${classType}`}>{newStatus}</div>;
};

export const ScanningExistingDoc = (doc_list) => {
  const docsType = ["KTP", "PASSPORT"];

  let documents = [];

  docsType.forEach((docType) => {
    doc_list.forEach((doc) => {
      const ident = DoctypeIdentifier(doc.doc_type);

      if (docType === ident) {
        documents.push({
          file_id: doc.file_id,
          name: doc.mimetype,
          type: ident,
          verify: doc.verify,
        });
      }
    });

    // Jika tidak ada dokumen yang cocok untuk docType, tambahkan entri default
    if (!documents.some((doc) => doc.type === docType)) {
      documents.push({
        file_id: "",
        name: "",
        type: docType,
        verify: 2, // 0 = unverified, 1 = verified, 2 = not provided
      });
    }
  });

  return documents;
};

export const VerifiedMandatoryDocs = (
  current_doc_type,
  verifiedDoc,
  doc_status
) => {
  // console.log("on verified mandatory docs", verifiedDoc);

  if (current_doc_type.includes("ktp")) {
    if (doc_status === 1 && verifiedDoc.ktp) {
      return true;
    }
  } 
  // else if (current_doc_type.includes("pasfoto")) {
  //   if (doc_status === 1 && verifiedDoc.pasfoto) {
  //     return true;
  //   }
  // } 
  else if (current_doc_type.includes("passport")) {
    if (doc_status === 1 && verifiedDoc.passport) {
      return true;
    }
  }

  return false;
};
