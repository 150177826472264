import React, { useEffect, useMemo, useState } from "react";
import {
  CardFlush,
  SGUCard,
} from "../../../../_layouts/components/cards/CardUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { RequestData } from "../../apis/ApiBackend";
import {
  ButtonItemRefresh,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  LoadingBars,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { useOutletContext } from "react-router-dom";
import { ButtonIcon } from "../../../../_layouts/components/buttons/ButtonUI";
import { GET_WIDGET_ALL_BY_GROUP } from "../../apis/module/BackendRequest";

export function ListWidget({ activeGroup }) {
  const [menus, setMenus] = useState({ loading: false, data: [], message: "" });
  const ReloadData = async () => {
    setMenus({ loading: true, data: [], message: "" });
    
    const result = await GET_WIDGET_ALL_BY_GROUP({ group_id: activeGroup.group_id });
    if(result.data && Object.values(result.data).length > 0){
      setMenus({ loading: false, data: result.data, message: "" });
    }else{
      setMenus(result)
    }
  };

  useEffect(() => {
    ReloadData();
  }, [activeGroup]);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const filterMenu = (menu) => {
    return menu
      .filter((item) => item.slug_uri !== "index")
      .map((item) => {
        if (item.children) {
          item.children = filterMenu(item.children);
        }
        return item;
      });
  };

  const ResultData = useMemo(() => {
    let computedData = filterMenu(menus.data);

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);
    return computedData;
  }, [menus.data, search]);

  return (
    <>

      <div className="my-8 d-none">
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center me-5">
            <i className="ki-outline ki-folder-added fs-2x text-primary"></i>
            <span className="fw-bold fs-5 ms-2">Have widget</span>
          </div>
          <div className="d-flex align-items-center">
            <i className="ki-outline ki-folder fs-2x text-danger"></i>
            <span className="fw-bold fs-5 ms-2">Doesn't have widget</span>
          </div>
        </div>
      </div>

      {menus.loading ? (
        <LoadingBars />
      ) : menus.message ? (
        <AlertNotif messages={menus.message} color="danger" />
      ) : ResultData.length > 0 ? (
        <div className="list-data row">
          {ResultData.map((v, index) => (
            <div className="col-12 mb-5" key={index}>
              <CardFlush>
                <CardMenu data={v} group_id={activeGroup.group_id} />
              </CardFlush>
            </div>
          ))}
        </div>
      ) : (
        <NoRecordFound />
      )}
    </>
  );
}

const CardMenu = ({ data, group_id }) => {
  const total_widget = Object.values(data.widgets).length;
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between pt-10">
        <div className="d-flex align-items-center ">
          {total_widget > 0 ? (
            <button
              type="button"
              className="btn btn-icon btn-clean p-0 collapsed"
              data-bs-toggle="collapse"
              data-bs-target={"#widget_" + data.menu_id}
              aria-expanded="false"
              aria-controls={"#widget_" + data.menu_id}
            >
              <div className="symbol symbol-circle me-5 bg-hover-light">
                <div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
                  <i className="ki-outline ki-folder-added fs-2x text-primary"></i>
                </div>
              </div>
            </button>
          ) : (
            <div className="symbol symbol-circle me-5">
              <div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
                <i className="ki-outline ki-folder fs-2x text-danger"></i>
              </div>
            </div>
          )}
          <div className="d-flex flex-column">
            <h2 className="mb-1">{data.menu_name}</h2>
            <div className="text-muted fw-bold">
              <span className="text-primary">
                {data.menu_type === 2
                  ? "Staging"
                  : data.menu_type === 1
                    ? "Private"
                    : "Production"}
              </span>
              <span className="mx-3">|</span>
              {data.menu_is_active === 1 ? (
                <span className="badge badge-info">Active</span>
              ) : (
                <span className="badge badge-danger">Not Active</span>
              )}
            </div>
          </div>
        </div>
        <ButtonIcon className="btn-active-light-primary collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target={"#widget_" + data.menu_id}
          aria-expanded="false"
          aria-controls={"#widget_" + data.menu_id}>
          <i className={"ki-outline ki-down fs-2x text-primary"}></i>
        </ButtonIcon>
      </div>
      <div className="tab-content bg-light rounded">
        <div
          className="collapse collapse-horizontal"
          id={"widget_" + data.menu_id}
        >
          {total_widget > 0 && (
            <div className="hover-scroll-y scroll-ps m-2">
              <div className="p-3 p-lg-4 tree tree-line">
                {data.widgets.map((v, index) => (
                  <TreeLineItem data={v} key={index} group_id={group_id} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const TreeLineItem = ({ data, group_id }) => {
  if (Object.values(data.children).length > 0) {
    return <TreeChildren data={data} group_id={group_id} />;
  } else {
    return <TreeSingle data={data} group_id={group_id} />;
  }
};

const TreeChildren = ({ data, group_id }) => {
  return (
    <div className="tree-node">
      <div className="tree-content p-3">
        <a
          className="tree-toggle collapsed"
          data-bs-toggle="collapse"
          href={`#kt_node_${data.widget_id}`}
          aria-expanded="true"
        >
          <KTIcon
            iconName={data.is_active === 1 ? "add-folder" : "delete-folder"}
            className="outline tree-icon-default"
          />
        </a>
        <div className="tree-section">
          <TickCheckbox data={data} group_id={group_id} />
        </div>
      </div>

      {data.children && data.children.length > 0 && (
        <div className="tab-content">
          <div
            className="tree-sub collapse show"
            id={`kt_node_${data.widget_id}`}
          >
            {data.children.map((child, index) => (
              <TreeLineItem key={index} data={child} group_id={group_id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TreeSingle = ({ data, group_id }) => {
  return (
    <div className="tree-node">
      <div className="tree-content p-3">
        <span className="tree-toggle">
          <KTIcon
            iconName={data.is_active === 1 ? "folder" : "delete-folder"}
            className={
              "tree-icon-default " + (data.is_active !== 1 && "text-danger")
            }
          />
        </span>
        <div className="tree-section">
          <TickCheckbox data={data} group_id={group_id} />
        </div>
      </div>
    </div>
  );
};

const TickCheckbox = ({ data, group_id }) => {
  const context = useOutletContext();
  const widget_6 = context.widgets.find(
    (item) => item.widget_id === 6 && item.is_enabled === 1
  );

  const [isenabled, setIsenabled] = useState(
    data.is_enabled === 1 ? true : false
  );
  const [isload, setIsLoad] = useState(false);
  const handlerTick = async (e) => {
    const is_checked = e.target.checked;
    const values = e.target.value;
    const valuesSplit = values.split("#");
    const widget_id = valuesSplit[0]; const menu_id = valuesSplit[1];
    const param = {
      group_id: parseInt(group_id),
      widget_id: parseInt(widget_id),
      menu_id: parseInt(menu_id),
    };
    
    let config;
    setIsLoad(true);
    if (is_checked) {
      config = {
        method: "post",
        pathuri: "api/group/submit-widget",
        payload: param,
      };
    } else {
      config = {
        method: "post",
        pathuri: "api/group/remove-widget",
        payload: param,
      };
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
      setIsLoad(false);
      setIsenabled(!isenabled);
    } else {
      alert("Failed updated. Error" + result.message);
      setIsLoad(false);
      setIsenabled(false);
    }
  };
  return (
    <div className="d-flex align-items-center">
      <span
        className={
          "fs-5 fw-semibold " +
          (data.is_active === 1 ? "text-gray-900" : "text-danger")
        }
      >
        {data.name}
      </span>
      <span className="badge badge-light-danger mx-2">W-{data.widget_id}</span>
      {(data.relmenu_id !== 0 && data.relmenu_id !== null) && <span className="badge badge-light-success me-2">M-{data.relmenu_id}</span> }
      {isload ? (
        <LoadingBars />
      ) : (
        widget_6 && (
          <div className="form-check form-switch">
            <input
              type="checkbox"
              name="is_group"
              className="form-check-input"
              defaultChecked={isenabled}
              defaultValue={data.widget_id + "#" +data.relmenu_id}
              onChange={(e) => handlerTick(e)}
            />
          </div>
        )
      )}
    </div>
  );
};
